type Props = {
  width: string;
  height: string;
  theme?: string;
};

export const IconCloseThick = (props: Props) => {
  const { width, height, theme = "success" } = props;

  const getColor = () => {
    switch (theme) {
      case "info":
        return "#38539a"; // blue
      case "error":
        return "#d91734"; // red
      default:
        return "#107f47"; // green
    }
  };

  return (
    <span className="icon-close-thick" style={{ width, height, backgroundSize: `${width} ${height}` }}>
      <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.10156 5.87891L6.23047 8.75L9.10156 11.6484C9.45703 11.9766 9.45703 12.5508 9.10156 12.8789C8.77344 13.2344 8.19922 13.2344 7.87109 12.8789L5 10.0078L2.10156 12.8789C1.77344 13.2344 1.19922 13.2344 0.871094 12.8789C0.515625 12.5508 0.515625 11.9766 0.871094 11.6484L3.74219 8.75L0.871094 5.87891C0.515625 5.55078 0.515625 4.97656 0.871094 4.64844C1.19922 4.29297 1.77344 4.29297 2.10156 4.64844L5 7.51953L7.87109 4.64844C8.19922 4.29297 8.77344 4.29297 9.10156 4.64844C9.45703 4.97656 9.45703 5.55078 9.10156 5.87891Z"
          fill={getColor()}
        />
      </svg>
    </span>
  );
};
