const content: { [key: string]: any } = {
  myBusinessIncome: "My Business Income",
  monthlyBonuses: {
    title: "PY {PERFORMANCE_YEAR} Estimated Monthly Bonus History",
    subtitle:
      "Note: Adjustments in Total Bonus Year to date may occur as a result of exchange rates, rounding, returns, reworks, taxes, etc.",
    taxDisclaimer:
      "All the amount displayed are subject to applicable taxes and paid in {CURRENCY_NAME} ({CURRENCY_CODE})",
    noData: "You have no bonuses for the selected performance year.",
  },
  errorBanner: {
    refreshPage: "Refresh Page",
    headline: "Something went wrong",
    content: "Please check your connection and refresh the page.",
  },
  metricLegendAwardTranslation: {
    all: "All",
    performance: "Performance",
    ruby: "Ruby",
    leadership: "Leadership",
    depth: "Depth",
    intl: "Intl Leadership",
    foster: "Foster",
    bbi: "Bronze Builder+",
    bfi: "Bronze Foundation+",
    pbi: "Path to Bronze+",
    csi: "CSI+",
    mpgg: "Monthly PGG+",
    pp: "Performance Plus+",
    ilbs: "ILB Statement",
    monthly: "Monthly Totals",
    pe: "Performance Elite+",
    pppe: "Performance Plus & Elite+",
    ssi: "Strong Start Incentive+",
  },
  metricsTable: {
    metrics: "Metrics",
    total: "Total",
    notAvailable: "N/A",
  },
  monthlyTotalTable: {
    estimatedTotal: "Estimated Total",
    notAvailable: "N/A",
  },
  ilbAccountInfo: {
    accountInformation: "Account Information",
    paymentToBeMade: "Payment to be made by ILB ACH/Wire Transfer",
    vatId: "VAT ID:",
    ifStopPayment: "**If a stop payment is applied, it may take 2 to 3 business days to re-issue your payment.",
  },
  ilbMarketGeneratorTable: {
    vatID: "VAT ID",
    paymentDetails: "Generator Payment Details",
    generator: "Generator(s)",
    date: "Date",
    bv: "BV",
    generatorBonus: "Generator Bonus",
    recipientBonus: "Recipient Bonus",
    netPayment: "Net Payment",
    totalAmountFrom: "Total Amount from",
    exchangeRate: "Exchange Rate",
    in: "in",
    adjustedAmountFrom: "Adjusted Amount From",
  },
  ilbPaymentDetails: {
    title: "ILB Payment Detail by Market",
    noData: "There are no generator details available.",
  },
  ilbPaymentSummary: {
    header: "ILB Payment Summary",
    totalAmountText: "Total Amount from ALL In",
  },
  ilbStatement: {
    title: "ILB Statement",
    noData: "There is no ILB Statement available for the selected month.",
    totalsAsOf: "Totals as of {timestamp}",
    statementDate: "Statement date: {date} Created on {timestamp}",
    downloadILB: "Download ILB Statement",
    readyToDownload: "Your file is ready to download",
    downloadModalCancel: "Cancel",
    downloadModalDownload: "Download",
  },
};

export default content;
