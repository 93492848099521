import { Navigate, Route } from "react-router-dom";
import { lazy } from "react";
import MBI from "../../components/MBI/MBI";
const MBIMonthlyBonuses = lazy(() => import("../../components/MBI/MBIMonthlyBonuses/MBIMonthlyBonuses"));
const ILBStatement = lazy(() => import("../../components/MBI/ILBStatement/ILBStatement"));

export enum MBI_ROUTES {
  BASE_PATH = "/mbi/",
  MONTHLY = `monthly`,
  ILB = `ilb`,
}

export const MBI_ROUTE_PATHS = [MBI_ROUTES.MONTHLY, MBI_ROUTES.ILB].map((route) => `${MBI_ROUTES.BASE_PATH}${route}`);

export const MBIRoutes = () => {
  return (
    <Route path={MBI_ROUTES.BASE_PATH} element={<MBI />}>
      <Route path={MBI_ROUTES.MONTHLY} element={<MBIMonthlyBonuses />} />
      <Route path={MBI_ROUTES.ILB} element={<ILBStatement />} />
      <Route path={MBI_ROUTES.BASE_PATH} element={<Navigate to={MBI_ROUTES.MONTHLY} />} />
    </Route>
  );
};
