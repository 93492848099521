import { Trans, useTranslation } from "react-i18next";
import defaultContent from "../../../../content/monthlyPerformanceDashboard";
import "./MonthlyPerformanceInfoModal.scss";
import LeafPanel, { ModalProps } from "../../../../common/leaf/LeafPanel/LeafPanel";
import { useAppSelector } from "../../../../store";
import { PERSONA } from "../../../../common/enums/persona";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

type Props = ModalProps & {
  isLoading: boolean;
  maxPerfBonusPercent: number;
  maxPerfGpvTarget: string;
  pvTargetWithOneQleg: string;
};

const MonthlyPerformanceInfoModal = ({
  show,
  isLoading,
  onCloseClick,
  title,
  maxPerfBonusPercent,
  maxPerfGpvTarget,
  pvTargetWithOneQleg,
}: Props) => {
  const { t } = useTranslation(["monthlyPerformanceDashboard"]);
  const persona = useAppSelector(({ boot }) => boot.shellConfig.persona);

  return (
    <div className="MpdInfoDrawer">
      <LeafPanel id={"monthly-performance-info-modal"} {...{ show, onCloseClick, title }} applyScroll={true}>
        <LeafRectangleLoader height="140px" width="500px" isLoading={isLoading} numRows={7}>
          <section className="MpdInfoDrawer__wrapper">
            <section>
              <h2 className="MpdInfoDrawer__title">
                <Trans>{t("infoDrawerGpvTitle", defaultContent["infoDrawerGpvTitle"])}</Trans>
              </h2>
              <p className="MpdInfoDrawer__desc">
                <Trans>
                  {t("infoDrawerGpvDesc", defaultContent["infoDrawerGpvDesc"], {
                    mpPerfBonus: maxPerfBonusPercent,
                  })}
                  <span>{/* mpPerfBonus */}</span>
                </Trans>
              </p>
            </section>
            <section hidden={persona === PERSONA.NEW}>
              <h2 className="MpdInfoDrawer__title">
                <Trans>{t("infoDrawerCorePlanTitle", defaultContent["infoDrawerCorePlanTitle"])}</Trans>
              </h2>
              <p className="MpdInfoDrawer__desc">
                <Trans>
                  {t("infoDrawerCorePlanDesc", defaultContent["infoDrawerCorePlanDesc"], {
                    maxPerfGpvTarget,
                    pvTargetWithOneQleg,
                    maxPerfBonusPercent,
                  })}
                  <span>{/* wayOne */}</span>
                  <span>{/* wayTwo */}</span>
                  <span>{/* oneMarket */}</span>
                  <span>{/* twoMarkets */}</span>
                </Trans>
              </p>
            </section>
            <section>
              <h2 className="MpdInfoDrawer__title">
                <Trans>{t("infoDrawerCurrentEarningsTitle", defaultContent["infoDrawerCurrentEarningsTitle"])}</Trans>
              </h2>
              <p className="MpdInfoDrawer__desc">
                <Trans>{t("infoDrawerCurrentEarningsDesc", defaultContent["infoDrawerCurrentEarningsDesc"])}</Trans>
              </p>
            </section>
            <section>
              <h2 className="MpdInfoDrawer__title">
                <Trans>
                  {t("infoDrawerDifferentialBonusTitle", defaultContent["infoDrawerDifferentialBonusTitle"])}
                </Trans>
              </h2>
              <p className="MpdInfoDrawer__desc">
                <Trans>{t("infoDrawerDifferentialBonusDesc", defaultContent["infoDrawerDifferentialBonusDesc"])}</Trans>
              </p>
            </section>
            <section hidden={persona !== PERSONA.LEADER && persona !== PERSONA.EMERALD && persona !== PERSONA.DIAMOND}>
              <h2 className="MpdInfoDrawer__title">
                <Trans>{t("infoDrawerRubyPvTitle", defaultContent["infoDrawerRubyPvTitle"])}</Trans>
              </h2>
              <p className="MpdInfoDrawer__desc">
                <Trans>
                  {t("infoDrawerRubyPvDesc", defaultContent["infoDrawerRubyPvDesc"], {
                    mpPerfBonus: maxPerfBonusPercent,
                  })}
                  <span>{/* mpPerfBonus */}</span>
                </Trans>
              </p>
            </section>
            <section hidden={persona !== PERSONA.LEADER && persona !== PERSONA.EMERALD && persona !== PERSONA.DIAMOND}>
              <h2 className="MpdInfoDrawer__title">
                <Trans>{t("infoDrawerLeadershipPvTitle", defaultContent["infoDrawerLeadershipPvTitle"])}</Trans>
              </h2>
              <p className="MpdInfoDrawer__desc">
                <Trans>
                  {t("infoDrawerLeadershipPvDesc", defaultContent["infoDrawerLeadershipPvDesc"], {
                    maxPerfBonusPercent,
                  })}
                  <span>{/* leaderBonus */}</span>
                </Trans>
              </p>
            </section>
          </section>
        </LeafRectangleLoader>
      </LeafPanel>
    </div>
  );
};

export default MonthlyPerformanceInfoModal;
