import { AWARD_CODES } from "../../enums/award-codes";
import { ROUTE_LABELS, ROUTE_PATHS, DROPDOWN_LABELS, DROPDOWN_KEYS } from "../../enums/routing-enums";
import { NavData, NavDropdown } from "../../interfaces/routing";
import { filterDropdown } from "./createMenuConfig";

export const createMyGroupDropdown = (navConfigs: NavData[]): NavDropdown => {
  const myGroupDropdown = {
    label: DROPDOWN_LABELS.MY_GROUP,
    key: DROPDOWN_KEYS.MY_GROUP,
    items: [
      {
        label: ROUTE_LABELS.CUSTOMER_OVERVIEW,
        path: ROUTE_PATHS.CUSTOMER_OVERVIEW,
        class: ROUTE_PATHS.CUSTOMER_OVERVIEW,
        code: AWARD_CODES.CUSTOMER_OVERVIEW,
        reloadDocument: true,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.LOS,
        path: ROUTE_PATHS.LOS,
        class: ROUTE_PATHS.LOS,
        code: AWARD_CODES.LOS,
        navPosition: 1,
      },
      {
        label: ROUTE_LABELS.EDLOS,
        path: ROUTE_PATHS.EDLOS,
        class: ROUTE_PATHS.EDLOS,
        code: AWARD_CODES.EDLOS,
        navPosition: 2,
      },
      {
        label: ROUTE_LABELS.ACTION_REPORTS,
        path: ROUTE_PATHS.ACTION_REPORTS,
        class: ROUTE_PATHS.ACTION_REPORTS,
        code: AWARD_CODES.ACTION_REPORTS,
        reloadDocument: true,
        navPosition: 3,
      },
      {
        label: ROUTE_LABELS.WATCHLIST,
        path: ROUTE_PATHS.WATCHLIST,
        class: ROUTE_PATHS.WATCHLIST,
        code: AWARD_CODES.WATCHLIST,
        navPosition: 4,
      },
      {
        label: ROUTE_LABELS.BRONZE_GROUP_TRACKING,
        path: ROUTE_PATHS.BRONZE_GROUP_TRACKING,
        class: ROUTE_PATHS.BRONZE_GROUP_TRACKING,
        code: AWARD_CODES.BRONZE_GROUP_TRACKING,
        reloadDocument: true,
        navPosition: 5,
      },
    ],
  };

  return filterDropdown(navConfigs, myGroupDropdown);
};
