import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import LeafDateTime from "../../../LeafDateTime/LeafDateTime";
import "./LeafBirthdayInfo.scss";

type Props = {
  date: string;
  privacyFlag?: boolean;
  show?: boolean;
};

// todo: handle leap year use case
const LeafBirthdayInfo = (props: Props) => {
  const { date, privacyFlag, show } = props;
  const { t } = useTranslation(["profile"]);

  const renderInfo = date ? (
    <LeafDateTime date={date} options={{ month: "long", day: "numeric" }} ignoreTimeZone={true} />
  ) : (
    `${t("aboProfile.datesTab.noBirthdayDate", defaultContent["aboProfile"]["datesTab"]["noBirthdayDate"])}`
  );

  const privacyView = privacyFlag === true ? "-" : renderInfo;

  if (show === true) {
    return (
      <div className="leaf-birthday">
        <div className="leaf-birthday__container">
          <div className="leaf-birthday__content-title">{`${t(
            "aboProfile.datesTab.birthday",
            defaultContent["aboProfile"]["datesTab"]["birthday"],
          )}`}</div>
          <div className="leaf-birthday__content-data">{privacyView}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafBirthdayInfo;
