export const QUALIFIED = "QUALIFIED";
export const DENIED = "DENIED";
export const TRACKING = "TRACKING";
export const ACHIEVED = "ACHIEVED";
export const CURRENT = "current";
export const NEXT = "next";
export const BONUS = "BONUS";

export const RUBY_PV_REQ = {
  requirement: "RUBY",
  requirementType: "Volume",
};

export const SP_MONTHS_TARGET = 12;
export const GPV_OR_VEPV_SP_MONTHS_TARGET = 10;
