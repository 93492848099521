const removeLeadingZero = (numberString: string) => {
  const stringLength = numberString.length;
  if (stringLength > 0 && numberString[0] === "0") {
    return numberString.slice(1, stringLength);
  }

  return numberString;
};

/**
 * Returns accurate date object based on local time from period string to use for formatting with Intl.DateTimeFormat.
 *
 * @param period "202406"
 * @returns Date "Sat Jun 15 2024 00:00:00 GMT-0400 (Eastern Daylight Time) "
 */
export const getPeriodToDate = (period: string | number) => {
  const { year, month } = getMonthAndYear(period);
  return new Date(Number(year), Number(month) - 1, 15);
};

export const getMonthAndYear = (period: string | number): { year: string; month: string } => {
  const periodString = `${period}`;
  if (periodString.length !== 6) {
    return { year: "", month: "" };
  }
  const year = periodString.slice(0, 4);
  const month = periodString.slice(4, 6);
  const formattedMonth = removeLeadingZero(month);

  if (Number(formattedMonth) > 12) return { year: "", month: "" };

  return { year, month };
};

/**
 * Returns 4 digit PY (Performance Year)
 * @param period - The period string in either 'YYYY' or 'YYYYMM' format.
 * @returns ex: "2024"
 */
export const getPerformanceYear = (period: string | number): string => {
  const periodString = `${period}`;
  const year = periodString.slice(0, 4);
  const month = periodString.length === 6 ? periodString.slice(4, 6) : "01"; // Default to January if only year is provided

  if (Number(month) >= 9) {
    const performanceYear = Number(year) + 1;
    return performanceYear.toString();
  }
  return year;
};

/**
 * Returns 2 digit PY (Performance Year)
 * @param period - The period string in either 'YYYY' or 'YYYYMM' format.
 * @returns ex: "24"
 */
export const getPerformanceYearShort = (period: string | number): string => {
  const periodString = `${period}`;
  if (periodString.length === 4) {
    return getPerformanceYear(period).slice(2, 4);
  } else if (periodString.length === 6) {
    return getPerformanceYear(period).slice(2, 4);
  } else {
    throw new Error("Invalid period format. Expected 'YYYY' or 'YYYYMM'.");
  }
};

/**
 * Returns the performance year data for a given period, adjusted backward by a specified count.
 *
 * @param period "202405"
 * @param offsetYears The number of years to go back from the performance year. "1"
 * @returns selectedPerformanceYear: "2023", selectedPYStartPeriod: "202309"; selectedPYEndPeriod: "202408"
 */
export const getSelectedPerformanceYearData = (
  period: string,
  offsetYears = 0,
): { selectedPerformanceYear: string; selectedPYStartPeriod: string; selectedPYEndPeriod: string } => {
  const { year, month } = getMonthAndYear(period);
  let performanceYear = Number(year);

  if (Number(month) >= 9) {
    performanceYear += 1;
  }

  performanceYear -= offsetYears;

  return {
    selectedPerformanceYear: performanceYear.toString(),
    selectedPYStartPeriod: `${performanceYear - 1}09`,
    selectedPYEndPeriod: `${performanceYear}08`,
  };
};

/**
 * @returns "202406"
 */
export const getPeriodFromDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  return `${year}${month}`;
};

/**
 * Returns the current date as period
 * @returns "202406"
 */
export const getCurrentPeriod = () => {
  return getPeriodFromDate(new Date());
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "Jun 2024"
 */
export const getShortPeriodFormat = (period: string, locale: string) => {
  const dateObject = getPeriodToDate(period);
  const shortDateFormatter = Intl.DateTimeFormat(locale, {
    month: "short",
    year: "numeric",
    calendar: "gregory",
  });
  const shortDate = shortDateFormatter.format(dateObject);
  return shortDate;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "Jun 24"
 */
export const getShortMonthYear = (period: string | number, locale: string) => {
  const dateObject = getPeriodToDate(period);
  const shortDateFormatter = Intl.DateTimeFormat(locale, {
    month: "short",
    year: "2-digit",
  });
  const shortDate = shortDateFormatter.format(dateObject);
  return shortDate;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "June 2024"
 */
export const getLongPeriodFormat = (period: string | number, locale: string) => {
  const dateObject = getPeriodToDate(period);
  const longDateFormatter = Intl.DateTimeFormat(locale, {
    month: "long",
    year: "numeric",
    calendar: "gregory",
  });
  const shortDate = longDateFormatter.format(dateObject);
  return shortDate;
};

/**
 * @param period "202406"
 * @param locale "ja-JP"
 * @returns "2024年"
 */
export const getYear = (period: string | number, locale: string) => {
  const dateObject = getPeriodToDate(period);
  const yearFormatter = Intl.DateTimeFormat(locale, {
    year: "numeric",
    calendar: "gregory",
  });
  const formatYear = yearFormatter.format(dateObject);
  return formatYear;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "June"
 */
export const getFullMonth = (period: string | number, locale: string) => {
  const dateObject = getPeriodToDate(period);
  const monthFormatter = Intl.DateTimeFormat(locale, {
    month: "long",
  });
  const formatMonth = monthFormatter.format(dateObject);
  return formatMonth;
};

/**
 * @param period "202406"
 * @param locale "en-US"
 * @returns "Jun"
 */
export const getShortMonth = (period: string | number, locale: string) => {
  const dateObject = getPeriodToDate(period);
  const monthFormatter = Intl.DateTimeFormat(locale, {
    month: "short",
  });
  const formatMonth = monthFormatter.format(dateObject);

  return formatMonth;
};

/**
 * Returns the Month of this AmwayPeriod as a zero-padded string. Eg: for August it returns '08'.
 * @param period
 * @returns "08"
 */
export const getMonthString = (period: string | number) => {
  const periodString = `${period}`;
  if (periodString.length === 6) {
    return periodString.slice(4);
  }
  return periodString;
};

const convertYearMonthToPeriod = (year: number, month: number) => {
  return month < 10 ? `${year}0${month}` : `${year}${month}`;
};

export const nextNPeriods = (period: string, n: number, inclusive: boolean) => {
  const periods = [];
  const { year, month } = getMonthAndYear(period);
  let currentYear = Number(year);
  let currentMonth = Number(month);
  let i = 0;
  if (inclusive) {
    periods.push(period.toString());
    i += 1;
  }

  for (; i < n; i += 1) {
    if (currentMonth === 12) {
      currentYear += 1;
      currentMonth = 1;
      periods.push(`${currentYear}01`);
    } else {
      currentMonth += 1;
      periods.push(convertYearMonthToPeriod(currentYear, currentMonth));
    }
  }

  return periods;
};

export const getPreviousNPeriods = (period: string, n: number, inclusive: boolean) => {
  const periods = [];
  const { year, month } = getMonthAndYear(period);
  let currentYear = Number(year);
  let currentMonth = Number(month);
  let i = 0;
  if (inclusive) {
    periods.push(period.toString());
    i += 1;
  }

  for (; i < n; i += 1) {
    if (currentMonth > 1) {
      currentMonth -= 1;
      periods.push(convertYearMonthToPeriod(currentYear, currentMonth));
    } else {
      currentYear -= 1;
      currentMonth = 12;
      periods.push(`${currentYear}${currentMonth}`);
    }
  }

  return periods;
};

/**
 * Returns an array of `n` number of the number representation of  AmwayPeriod(s) after this AmwayPeriod
 * If `inclusive` is `true`, the return array includes the string representation of this AmwayPeriod.
 *
 */
export const getNextNPeriodNumbers = (period: string, n: number, inclusive = false) => {
  const periods = nextNPeriods(period, n, inclusive);
  return periods.map((period) => Number(period));
};
