/**
 * A model of an ABO identifiers
 *
 * @export
 * @class Abo
 */
export default class AboAffNum {
  affiliate: string;
  aboNumber: number;
  affAbo: string;
  /**
   * Creates an instance of Abo identifiers
   * @param {object} args
   * @param {string} args.affiliate
   * @param {number} args.aboNumber
   */
  constructor({ affiliate, aboNumber }: { affiliate: string; aboNumber: number }) {
    this.affiliate = affiliate;
    this.aboNumber = aboNumber;
    this.affAbo = `${this.affiliate}-${this.aboNumber}`;
  }

  static fromString(affAbo: string): AboAffNum {
    const affAboSplit: string[] = affAbo.split("-");
    return new AboAffNum({ affiliate: affAboSplit[0], aboNumber: parseInt(affAboSplit[1]) });
  }
}
