import "./ErrorPage.scss";
import { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import defaultContent from "../../content/error";
import { IconAmway } from "../../common/icons/IconAmway/IconAmway";
import { IconQuestion } from "../../common/icons/IconQuestion/IconQuestion";
import { IconGlobe } from "../../common/icons/IconGlobe/IconGlobe";
import { IconBrowsers } from "../../common/icons/IconBrowsers/IconBrowsers";
import { IconErrorClock } from "../../common/icons/IconErrorClock/IconErrorClock";
import { IconErrorGeneral } from "../../common/icons/IconErrorGeneral/IconErrorGeneral";
import { IconErrorLock } from "../../common/icons/IconErrorLock/IconErrorLock";
import { IconErrorUnavailable } from "../../common/icons/IconErrorUnavailable/IconErrorUnavailable";
import { browserConfig } from "../../common/constants/browser-constants";
import { store } from "../../store";
import { ROUTE_PATHS } from "../../common/enums/routing-enums";
import LeafButton, { Variant } from "../../common/leaf/LeafButton/LeafButton";
import { IconArrowLink } from "../../common/icons/IconArrowLink/IconArrowLink";
import { track } from "../../common/util/tealium";

type Props = {
  errorType?: ERROR_TYPE;
  iconType?: IconType;
  showBackToAmwayButton?: boolean;
  isLoading?: boolean;
};

export enum IconType {
  Question,
  Frown,
  AmwayLogo,
  Globe,
  Unavailable,
  Clock,
  Lock,
  General,
}

export enum ERROR_TYPE {
  AUTHENTICATION_FAILED = "AuthenticationFailed",
  CML_LOAD_FAILED = "CmlLoadFailed",
  COMPONENT_ERROR = "ComponentError",
  EDLOS_LOAD_FAILED = "EdLosLoadFailed",
  GENERIC_ERROR = "GenericError",
  INCENTIVE_UNAVAILABLE_FOR_PERIOD = "IncentiveUnavailableForPeriod",
  INCORRECT_ACCOUNT = "IncorrectAccount",
  PAGE_UNAVAILABLE = "PageUnavailable",
  SYSTEM_MAINTENANCE = "SystemMaintenance",
  UNSUPPORTED_BROWSER = "UnsupportedBrowser",
  USER_SESSION_EXPIRED = "UserSessionExpired",
}

export const ErrorPage = ({
  errorType = ERROR_TYPE.GENERIC_ERROR,
  iconType = IconType.Frown,
  showBackToAmwayButton = false,
  isLoading = false,
}: Props) => {
  const { t } = useTranslation(["error"]);
  const [errorTitle, setErrorTitle] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorButtonTitle, setErrorButtonTitle] = useState("");
  const [noHeader, setNoHeader] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(iconType);
  const navigate = useNavigate();
  const { pathname, hash } = useLocation();

  const BrowserIcons = () => {
    const deviceType = store.getState().loginLocalStorage.deviceType || "desktop";
    return (
      <div className="error__browserIcons">
        {browserConfig.map(({ browser, downloadUrl, name }) => {
          const linkText = `${t("downloadBrowser", defaultContent["downloadBrowser"], { browserName: name })}`;
          return (
            <a href={downloadUrl[deviceType]} key={browser} aria-label={linkText}>
              <IconBrowsers browser={browser} />
            </a>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    const page = `${pathname.replace("/", "")}${hash.length > 1 ? hash : ""}`;
    switch (errorType) {
      case ERROR_TYPE.AUTHENTICATION_FAILED:
        setErrorTitle(`${t("authenticationFailedTitle", defaultContent["authenticationFailedTitle"])}`);
        setErrorMessage(`${t("authenticationFailedText", defaultContent["authenticationFailedText"])}`);
        setSelectedIcon(IconType.Lock);
        setNoHeader(true);
        track("view", "", "auth_fail_error_page", "auth_fail_error_page", `errorPage_${page}`);
        break;
      case ERROR_TYPE.CML_LOAD_FAILED:
        setErrorTitle(`${t("cmlLoadFailedTitle", defaultContent["cmlLoadFailedTitle"])}`);
        setErrorMessage(`${t("cmlLoadFailedText", defaultContent["cmlLoadFailedText"])}`);
        break;
      case ERROR_TYPE.COMPONENT_ERROR:
        setErrorTitle(`${t("componentErrorTitle", defaultContent["componentErrorTitle"])}`);
        setErrorMessage(`${t("componentErrorText", defaultContent["componentErrorText"])}`);
        setSelectedIcon(IconType.Clock);
        track("view", "", "component_error_page", "component_error_page", `errorPage_${page}`);
        break;
      case ERROR_TYPE.EDLOS_LOAD_FAILED:
        setErrorTitle(`${t("edLosLoadFailTitle", defaultContent["edLosLoadFailedTitle"])}`);
        setErrorMessage(`${t("edLosLoadFailText", defaultContent["edLosLoadFailedText"])}`);
        break;
      case ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD:
        setErrorTitle(
          `${t("incentiveUnavailableForPeriodTitle", defaultContent["incentiveUnavailableForPeriodTitle"])}`,
        );
        setErrorMessage(
          `${t("incentiveUnavailableForPeriodText", defaultContent["incentiveUnavailableForPeriodText"])}`,
        );
        setErrorButtonTitle(`${t("dashboard", defaultContent["dashboard"])}`);
        setSelectedIcon(IconType.Clock);
        track("view", "", "invalid_period_error_page", "invalid_period_error_page", `errorPage_${page}`);
        break;
      case ERROR_TYPE.INCORRECT_ACCOUNT:
        setErrorTitle(`${t("incorrectAccountTitle", defaultContent["incorrectAccountTitle"])}`);
        setErrorMessage(`${t("incorrectAccountText", defaultContent["incorrectAccountText"])}`);
        setSelectedIcon(IconType.General);
        setNoHeader(true);
        track("view", "", "incorrect_account_error_page", "incorrect_account_error_page", `errorPage_${page}`);
        break;
      case ERROR_TYPE.PAGE_UNAVAILABLE:
        setErrorTitle(`${t("pageUnavailableTitle", defaultContent["pageUnavailableTitle"])}`);
        setErrorMessage(`${t("pageUnavailableText", defaultContent["pageUnavailableText"])}`);
        setErrorButtonTitle(`${t("btnBackToHome", defaultContent["btnBackToHome"])}`);
        setSelectedIcon(IconType.Unavailable);
        track("view", "", "page_unavailable_error_page", "page_unavailable_error_page", `errorPage_${page}`);
        break;
      case ERROR_TYPE.SYSTEM_MAINTENANCE:
        setErrorTitle(`${t("systemMaintenanceTitle", defaultContent["systemMaintenanceTitle"])}`);
        setErrorMessage(`${t("systemMaintenanceText", defaultContent["systemMaintenanceText"])}`);
        setNoHeader(true);
        break;
      case ERROR_TYPE.UNSUPPORTED_BROWSER:
        setErrorTitle(`${t("unsupportedBrowserTitle", defaultContent["unsupportedBrowserTitle"])}`);
        setErrorMessage(`${t("unsupportedBrowserText", defaultContent["unsupportedBrowserText"])}`);
        track("view", "", "browser_unavailable_error_page", "browser_unavailable_error_page", `errorPage_${page}`);
        setNoHeader(true);
        break;
      case ERROR_TYPE.USER_SESSION_EXPIRED:
        setErrorTitle(`${t("userSessionExpiredTitle", defaultContent["userSessionExpiredTitle"])}`);
        setErrorMessage(`${t("userSessionExpiredText", defaultContent["userSessionExpiredText"])}`);
        setSelectedIcon(IconType.Clock);
        setNoHeader(true);
        track("view", "", "session_timeout_error_page", "session_timeout_error_page", `errorPage_${page}`);
        break;
      case ERROR_TYPE.GENERIC_ERROR:
      default:
        setErrorTitle(`${t("genericErrorTitle", defaultContent["genericErrorTitle"])}`);
        setErrorMessage(`${t("genericErrorText", defaultContent["genericErrorText"])}`);
        setSelectedIcon(IconType.General);
        track("view", "", "generic_error_page", "generic_error_page", `errorPage_${page}`);
    }
  }, [t, errorType, pathname, hash]);

  const renderButton = () => {
    if (showBackToAmwayButton || errorButtonTitle) {
      if (!errorButtonTitle) {
        setErrorButtonTitle(`${t("btnBackToAmwayCom", defaultContent["btnBackToAmwayCom"])} `);
      }

      let route = "/";

      switch (errorType) {
        case ERROR_TYPE.INCENTIVE_UNAVAILABLE_FOR_PERIOD:
          route = ROUTE_PATHS.MONTHLY_PERFORMANCE;
          break;
        default:
          route = "/";
      }
      return (
        <div id="error__buttons" className="error__buttons">
          <LeafButton
            variant={Variant.SOLID}
            clickAction={() => navigate(route)}
            id="error-cta-button"
            Icon={<IconArrowLink iconColor="white" />}
          >
            {errorButtonTitle}
          </LeafButton>
        </div>
      );
    }
    return "";
  };

  const renderErrorIcon = () => {
    let icon;
    switch (selectedIcon) {
      case IconType.AmwayLogo:
        icon = <IconAmway useBlackIcon />;
        break;
      case IconType.Question:
        icon = <IconQuestion />;
        break;
      case IconType.Globe:
        icon = <IconGlobe />;
        break;
      case IconType.Clock:
        icon = <IconErrorClock />;
        break;
      case IconType.General:
        icon = <IconErrorGeneral />;
        break;
      case IconType.Lock:
        icon = <IconErrorLock />;
        break;
      case IconType.Unavailable:
        icon = <IconErrorUnavailable />;
        break;
      case IconType.Frown:
      default:
        icon = <IconErrorGeneral />;
    }

    return (
      <div id="error__icon" className="error__icon">
        {icon}
      </div>
    );
  };

  return (
    <main id="error" className={isLoading ? "loading error" : `error ${noHeader ? "error__no-header" : ""}`}>
      {renderErrorIcon()}
      <div id="error__title" className="error__title">
        {errorTitle}
      </div>
      <div id="error__message" className="error__message">
        <Trans>{errorMessage}</Trans>
        {errorType === ERROR_TYPE.UNSUPPORTED_BROWSER && <BrowserIcons />}
      </div>

      {renderButton()}
    </main>
  );
};
