import hatFaaHelper, { HatFaaHelperPayload } from "./hatFaaHelper";

export interface GarBusinessLegPayload extends HatFaaHelperPayload {
  abo: number;
  isoCountry: string;
  foundersPlatinumCount: number;
  foundersPlatinumQC: number;
  ebrCount: number;
  ebrQC: number;
  dbrCount: number;
  dbrQC: number;
  fdbrCount: number;
  fdbrQC: number;
  totalQC: number;
  qcCounting: number;
  qcNotCounting: number;
}

export default class GarBusinessLeg extends hatFaaHelper {
  _p: GarBusinessLegPayload;
  constructor(payload: GarBusinessLegPayload) {
    super(payload);
    this._p = payload;
  }

  /**
   * Leg abo number
   * @return {number}
   */
  get abo(): number {
    return this._p.abo;
  }

  /**
   * ISO country code
   * @return {string}
   */
  get isoCountryCode(): string {
    return this._p.isoCountry;
  }

  /**
   * FP count.
   * @return {number}
   */
  get fpCount(): number {
    return this._p.foundersPlatinumCount;
  }

  /**
   * FP credits.
   * @return {number}
   */
  get fpCredits(): number {
    return this._p.foundersPlatinumQC;
  }

  /**
   * EBR count.
   * @return {number}
   */
  get ebrCount(): number {
    return this._p.ebrCount;
  }

  /**
   * EBR credits.
   * @return {number}
   */
  get ebrCredits(): number {
    return this._p.ebrQC;
  }

  /**
   * DBR count.
   * @return {number}
   */
  get dbrCount(): number {
    return this._p.dbrCount;
  }

  /**
   * DBR credits.
   * @return {number}
   */
  get dbrCredits(): number {
    return this._p.dbrQC;
  }

  /**
   * FDBR count.
   * @return {number}
   */
  get fdbrCount(): number {
    return this._p.fdbrCount;
  }

  /**
   * FDBR credits.
   * @return {number}
   */
  get fdbrCredits(): number {
    return this._p.fdbrQC;
  }

  /**
   * Sum of credits.
   * @return {number}
   */
  get totalCredits(): number {
    return this._p.totalQC;
  }

  /**
   * Total credits that count. There is a cap to the amount
   * of credits that you can count from a single leg.
   * @return {number}
   */
  get creditsCounting(): number {
    return this._p.qcCounting;
  }

  /**
   * Total credits that don't count, because this
   * leg belongs to a business that is not EBR.
   * @return {number}
   */
  get creditsNotCounting(): number {
    return this._p.qcNotCounting;
  }
}
