import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { NavDropdownItem } from "../../../common/interfaces/routing";
import TEALIUM from "../../../common/enums/tealium-enums";
import { TealiumClickActionMapper } from "../../../common/util/tealiumClickActionMapper";
import defaultContent from "../../../content/navigation";
import "./NavigationDropdown.scss";
import { reloadForP2Components } from "../../../common/util/checkP2Routes";
import { LinkUtil } from "../../../common/util/LinkUtil";
import { useAppSelector } from "../../../store";
import { track } from "../../../common/util/tealium";

type DropDownProps = {
  subMenus: NavDropdownItem[];
  isDropdownActive: boolean;
  closeDropdownOnSelection: Function;
  activeItem: string;
};

const Dropdown = ({ subMenus, isDropdownActive, closeDropdownOnSelection, activeItem }: DropDownProps) => {
  const { t } = useTranslation(["navigation"], { keyPrefix: "linkTitle" });
  const location = useLocation();

  const getSubMenuItemClass = (submenu: NavDropdownItem) => {
    let classname = `dropdown__menu-item ${submenu.class} `;
    if (submenu.path === activeItem) {
      classname += "dropdown__active";
    }
    return classname;
  };

  const handleTealiumTrackClickAction = (path: string) => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.DROP_DOWN,
      "",
      TealiumClickActionMapper[path],
    );
  };

  const handleClickEvent = (nextPath: string) => {
    handleTealiumTrackClickAction(nextPath);
    closeDropdownOnSelection();
    reloadForP2Components(location, nextPath);
  };

  const displayDropdownClass = () => {
    return isDropdownActive && subMenus.length > 0 ? "dropdown__show" : "dropdown__hide";
  };

  return (
    <ul className={`dropdown ${displayDropdownClass()}`}>
      {subMenus
        // HideMenu: Hide the menu but user can still access the page via direct link/bookmark
        .filter(({ hideMenu }) => !hideMenu)
        .map((subMenu: NavDropdownItem, index: number) => {
          return (
            <li key={index} id={subMenu.path} className={getSubMenuItemClass(subMenu)}>
              <LinkUtil to={subMenu} id={index} key={index}>
                <div onClick={() => handleClickEvent(subMenu.path)}>
                  {`${t(subMenu.label, defaultContent.linkTitle[subMenu.label])}`}
                </div>
              </LinkUtil>
            </li>
          );
        })}
    </ul>
  );
};

export default Dropdown;
