import { track } from "../../../../util/tealium";
import TEALIUM from "../../../../enums/tealium-enums";

const actionMapper: { [tabId: string]: string } = {
  overview: "view_overview",
  dates: "view_key_dates",
  sponsorship: "view_sponsorship",
  insights: "view_insights",
};

export const profileTabClickTrack = (tabId: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.ABO_PROFILE,
    "",
    actionMapper[tabId],
  );
};
