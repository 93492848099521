import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, Suspense, useState } from "react";
import LeafTabNavLink from "../../common/leaf/LeafTabNavLink/LeafTabNavLink";
import defaultContent from "../../content/customers";
import { CUSTOMER_ROUTES } from "../../routing/SubRoutes/Customers";
import "./Customers.scss";
import { tabMenuClickTrack } from "../../common/tealium/Customers/TabMenuTrack";
import { useAppDispatch, useAppSelector } from "../../store";
import { updateSelectorType, SELECTOR_TYPE } from "../../reducers/period";
import { fetchContent, storedLocale } from "../impersonation/util";
import { storedIsoCountry } from "../../common/util/config";
import CustomerListExportNotify from "../CustomerExport/CustomerExportNotifyContainer/CustomerListExportNotify/CustomerListExportNotify";
import OrderHistoryExportNotify from "../CustomerExport/CustomerExportNotifyContainer/OrderHistoryExportNotify/OrderHistoryExportNotify";
import LeafRectangleLoader from "../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";

const Customers = () => {
  const { t } = useTranslation(["customers"]);
  const dispatch = useAppDispatch();
  const isCountryCode = storedIsoCountry();
  const locale = storedLocale();
  const pageId = "cml";
  const [disclaimer, setDisclaimer] = useState("");
  const enableExport = useAppSelector((state) => state.boot?.configuration?.globalCML?.cmlExport?.enable);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));

    // Get cml content
    fetchContent(pageId, isCountryCode, locale, {}).then((content) => {
      setDisclaimer(content.footerDisclaimerMD);
    });
  }, [dispatch, locale, isCountryCode]);

  const navLinkOnClick = (to: string, text: string) => {
    tabMenuClickTrack(to);
  };

  const RouterLoader = () => (
    <div className="router-loader">
      <div className="router-loader-header">
        <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="3rem" />
      </div>
      <div className="router-loader-top">
        <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="3rem" />
      </div>
      <div className="router-loader-bottom">
        <LeafRectangleLoader isLoading={true} numRows={1} width="95%" height="8rem" />
      </div>
    </div>
  );

  return (
    <>
      <div className="cml">
        {enableExport === true && <CustomerListExportNotify />}
        {enableExport === true && <OrderHistoryExportNotify />}
        <div className="cml__header">
          <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} />
        </div>
        <div className="cml__scroll">
          <div className="cml__nav">
            <LeafTabNavLink
              tabs={[
                {
                  id: "overview",
                  to: CUSTOMER_ROUTES.OVERVIEW,
                  text: `${t("overview", defaultContent["overview"])}`,
                },
                {
                  id: "list",
                  to: CUSTOMER_ROUTES.LIST,
                  text: `${t("customerList", defaultContent["customerList"])}`,
                },
                {
                  id: "order",
                  to: CUSTOMER_ROUTES.ORDER,
                  text: `${t("orderHistory", defaultContent["orderHistory"])}`,
                },
              ]}
              navLinkOnClick={navLinkOnClick}
            />
          </div>
        </div>
        <div className="cml__container">
          <Suspense fallback={<RouterLoader />}>
            <Outlet />
          </Suspense>
        </div>
      </div>
      <BonusInfoFooter />
    </>
  );
};

export default Customers;
