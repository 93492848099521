/* eslint-disable @typescript-eslint/no-unused-vars */
import { DOWNLINEINFO } from "../enums/gql/los/downlineInfo";
import { AWARDINFO } from "../enums/gql/los/awardInfo";

export interface AccountPerformanceEnrichmentInfo {
  [key: string]: unknown;
  period: string;
}
export interface Volume {
  Name: string;
  PV: number;
}
export interface BonusPeriodStat {
  BonusPeriod: string;
  Volumes: Array<Volume>;
  enrichmentInfo?: AccountPerformanceEnrichmentInfo;
}

export interface AccountPerformanceDetail {
  BonusPeriodStats: Array<BonusPeriodStat>;
}

export interface AccountPerformancePayload {
  Detail: Array<AccountPerformanceDetail>;
}

/**
 * A model of an AccountPerformance
 *
 * @export
 * @class AccountPerformance
 */
export default class AccountPerformance {
  details: Array<AccountPerformanceDetail>;
  enrichmentInfo: Array<AccountPerformanceEnrichmentInfo>;
  /**
   * Creates an instance of AccountPerformance.
   * @param {object} details
   * @memberof AccountPerformance
   */
  constructor(details: AccountPerformancePayload, enrichmentInfo: Array<AccountPerformanceEnrichmentInfo>) {
    this.details = details.Detail;
    this.enrichmentInfo = enrichmentInfo;
  }

  /**
   * Gets the bonus period data based on the performance for the specified bonus period
   * @param {number} bonusPeriod
   * @returns {object}
   * @memberof AccountPerformance
   */
  getBonusPeriodData(bonusPeriod: string): BonusPeriodStat {
    const response = this.details[0].BonusPeriodStats.find((bps) => bps.BonusPeriod === bonusPeriod);
    const enrichmentInfo = this.getBonusPeriodEnrichmentInfo(bonusPeriod);
    if (response) {
      response.enrichmentInfo = enrichmentInfo;
    }
    return response as BonusPeriodStat;
  }

  /**
   * Gets the stats for a given bonus period
   * @param {string} bonusPeriod
   * @returns {object}
   * @memberof AccountPerformance
   */
  getBonusPeriodVolumes(bonusPeriod: string): Array<Volume> {
    const details = this.details[0];
    const bonus = details.BonusPeriodStats;
    return bonus.find((stats) => stats.BonusPeriod === bonusPeriod)?.Volumes as Volume[];
  }

  /**
   * Gets the non-volume stats for a given bonus period
   * @param {string} bonusPeriod
   * @returns {object}
   * @memberof AccountPerformance
   */
  getBonusPeriodEnrichmentInfo(bonusPeriod: string): AccountPerformanceEnrichmentInfo {
    return this.enrichmentInfo.find((stats) => stats.period === bonusPeriod) as AccountPerformanceEnrichmentInfo;
  }

  /**
   * Gets the stats for volume given bonus period based on the volume name
   * @param {string} bonusPeriod
   * @param {string} volumeName
   * @returns {object}
   * @memberof AccountPerformance
   */
  getVolumeTotalPVByNameAndPeriod(bonusPeriod: string, volumeName: string): number {
    const bonusPeriodStats = this.details[0].BonusPeriodStats;
    const bonusPeriodInfo = bonusPeriodStats.find((stats) => stats.BonusPeriod === bonusPeriod) as BonusPeriodStat;

    if (bonusPeriodInfo && Object.prototype.hasOwnProperty.call(bonusPeriodInfo, "Volumes")) {
      const volumesByType = bonusPeriodInfo.Volumes.filter((vol) => vol.Name === volumeName);
      return volumesByType.reduce((accumulator, { PV }) => accumulator + PV, 0);
    }
    return 0;
  }

  /**
   * Returns the volume total or downline info kpi for the specified bonus period
   * @param {string} bonusPeriod
   * @param {string} kpiKey
   */
  getKpiValueForPeriod(bonusPeriod: string, kpiKey: string): number {
    const downlineInfoValue = this.getBonusPeriodEnrichmentInfoByKey(bonusPeriod, kpiKey);
    return downlineInfoValue || this.getVolumeTotalPVByNameAndPeriod(bonusPeriod, kpiKey);
  }

  /**
   * Returns the downline information from the given bonus period with the associated DOWNLINEINFO key
   * @param {string} bonusPeriod
   * @param {string} downlineInfoKey
   * @returns {number}
   * @memberof AccountPerformance
   */
  getBonusPeriodEnrichmentInfoByKey(bonusPeriod: string, downlineInfoKey: string): number {
    const periodEnrichmentInfo = this.getBonusPeriodEnrichmentInfo(bonusPeriod);
    return periodEnrichmentInfo ? (periodEnrichmentInfo[downlineInfoKey] as number) : 0;
  }

  /**
   * Gets new abo count during the specified period
   * @param {string} bonusPeriod
   * @returns {number}
   * @memberof AccountPerformance
   */
  getTotalNewAboCountByPeriod(bonusPeriod: string): number {
    return this.getBonusPeriodEnrichmentInfoByKey(bonusPeriod, DOWNLINEINFO.TOTAL_NEW_ABO_COUNT);
  }

  /**
   * Gets total abo count during the specified period
   * @param {string} bonusPeriod
   * @returns {number}
   * @memberof AccountPerformance
   */
  getTotalAboCountByPeriod(bonusPeriod: string): number {
    return this.getBonusPeriodEnrichmentInfoByKey(bonusPeriod, DOWNLINEINFO.TOTAL_ABO_COUNT);
  }

  /**
   * Gets personal order count during the specified period
   * @param {string} bonusPeriodthis.__formatPositivePVValue
   * @returns {number}
   * @memberof AccountPerformance
   */
  getPersonalOrdersCountByPeriod(bonusPeriod: string): number {
    return this.getBonusPeriodEnrichmentInfoByKey(bonusPeriod, DOWNLINEINFO.PERSONAL_ORDERS_COUNT);
  }

  /**
   * Gets group order count during the specified period
   * @param {string} bonusPeriod
   * @returns {number}
   * @memberof AccountPerformance
   */
  getGroupOrdersCountByPeriod(bonusPeriod: string): number {
    return this.getBonusPeriodEnrichmentInfoByKey(bonusPeriod, DOWNLINEINFO.GROUP_ORDERS_COUNT);
  }

  /**
   * Gets personal vcs percentage during the specified period
   * @param {string} bonusPeriod
   * @returns {number}
   * @memberof AccountPerformance
   */
  getPersonalVcsPercentByPeriod(bonusPeriod: string): number {
    return this.getBonusPeriodEnrichmentInfoByKey(bonusPeriod, AWARDINFO.PERSONAL_VCS_PERCENT);
  }
}
