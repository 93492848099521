import { useState } from "react";
import IconLoadingCircle from "../IconLoadingCircle/IconLoadingCircle";

type Props = {
  show?: boolean;
  loading?: boolean;
};

export const IconCsv = (props: Props) => {
  const { show = true, loading = false } = props;
  const [hovered, setHovered] = useState(false);

  if (show === false) {
    return <></>;
  }

  if (loading === true) {
    return <IconLoadingCircle />;
  }

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <circle cx="18" cy="18" r="18" fill={hovered ? "#000" : "#2C2C2C"} />
      <path
        d="M10.5 26.625H11.75V28.5H10.5C9.09375 28.5 8 27.4062 8 26V11C8 9.63281 9.09375 8.5 10.5 8.5H16.9453C17.6094 8.5 18.2344 8.77344 18.7031 9.24219L22.2578 12.7969C22.7266 13.2656 23 13.8906 23 14.5547V19.75H21.125V14.75H18C17.2969 14.75 16.75 14.2031 16.75 13.5V10.375H10.5C10.1484 10.375 9.875 10.6875 9.875 11V26C9.875 26.3516 10.1484 26.625 10.5 26.625ZM15.8125 22.25H16.4375C17.2969 22.25 18 22.9531 18 23.8125V24.125C18 24.4766 17.6875 24.75 17.375 24.75C17.0234 24.75 16.75 24.4766 16.75 24.125V23.8125C16.75 23.6562 16.5938 23.5 16.4375 23.5H15.8125C15.6172 23.5 15.5 23.6562 15.5 23.8125V26.9375C15.5 27.1328 15.6172 27.25 15.8125 27.25H16.4375C16.5938 27.25 16.75 27.1328 16.75 26.9375V26.625C16.75 26.3125 17.0234 26 17.375 26C17.6875 26 18 26.3125 18 26.625V26.9375C18 27.8359 17.2969 28.5 16.4375 28.5H15.8125C14.9141 28.5 14.25 27.8359 14.25 26.9375V23.8125C14.25 22.9531 14.9141 22.25 15.8125 22.25ZM21.0078 22.25H22.375C22.6875 22.25 23 22.5625 23 22.875C23 23.2266 22.6875 23.5 22.375 23.5H21.0078C20.6953 23.5 20.5 23.7344 20.5 24.0469C20.5 24.2422 20.6172 24.3984 20.7734 24.5156L22.2578 25.1406C22.8828 25.4141 23.3125 26.0781 23.3125 26.7422C23.3125 27.7188 22.4922 28.5 21.5156 28.5H19.875C19.5234 28.5 19.25 28.2266 19.25 27.875C19.25 27.5625 19.5234 27.25 19.875 27.25H21.5156C21.8281 27.25 22.0625 27.0547 22.0625 26.7422C22.0625 26.5469 21.9453 26.3516 21.75 26.2734L20.2656 25.6484C19.6406 25.375 19.25 24.7109 19.25 24.0469C19.25 23.0703 20.0312 22.25 21.0078 22.25ZM24.875 22.25C25.1875 22.25 25.5 22.5625 25.5 22.875V24.125C25.5 25.0234 25.6953 25.9219 26.125 26.7031C26.5156 25.9219 26.75 25.0234 26.75 24.125V22.875C26.75 22.5625 27.0234 22.25 27.375 22.25C27.6875 22.25 28 22.5625 28 22.875V24.125C28 25.4922 27.5703 26.8203 26.8281 27.9531L26.6328 28.2266C26.5156 28.4219 26.3203 28.5 26.125 28.5C25.8906 28.5 25.6953 28.4219 25.5781 28.2266L25.3828 27.9531C24.6406 26.8203 24.25 25.4922 24.25 24.125V22.875C24.25 22.5625 24.5234 22.25 24.875 22.25Z"
        fill="white"
      />
    </svg>
  );
};
