import TEALIUM from "../../enums/tealium-enums";
import {
  CURRENT_VIEW,
  FORMAT_CSV,
  FORMAT_PDF,
  FORMAT_XLSX,
  FULL_MAP,
  FULL_MAP_SELECTED_KPIS,
  MODULE_BIRTHDAYS_AR,
  MODULE_CBR_AR,
  MODULE_CUSTOMER,
  MODULE_CVR_AR,
  MODULE_EDLOS,
  MODULE_GOALS_AR,
  MODULE_LOS,
  MODULE_NON_BONUSABLE_BV_AR,
  MODULE_ORDER,
  MODULE_PROVISIONAL_AR,
  MODULE_RENEWALS,
  MODULE_WATCHLIST,
  MODULE_WHOSNEW,
  MODULE_WHOS_CLOSE,
  STATUS_DONE,
  STATUS_ERROR,
} from "../../constants/export-constants";
import { track } from "../../util/tealium";

const ACTIONS = {
  VIEW_EXPORT: "view_exports",
  DELETE: "delete_export",
  DELETE_IN_PROGRESS: "delete_in_progress_export",
  DELETE_ERROR: "delete_failed_export",
  EXPORT_ANOTHER: "export_another",
  EXPORT: "export",
} as const;

const EXPORT_TYPE_MENU: Record<string, string> = {
  [CURRENT_VIEW]: "export_current_view",
  [FULL_MAP]: "export_full_map_all_KPIs",
  [FULL_MAP_SELECTED_KPIS]: "export_full_map_selected_KPIs",
};

const EXPORT_TYPE_DOWNLOAD: Record<string, string> = {
  [CURRENT_VIEW]: "current_view_download",
  [FULL_MAP]: "full_map_all_KPIs_download",
  [FULL_MAP_SELECTED_KPIS]: "full_map_selected_KPIs_download",
};

const FORMAT_ID: Record<string, string> = {
  [FORMAT_CSV]: "download_csv",
  [FORMAT_XLSX]: "download_xls",
  [FORMAT_PDF]: "download_pdf",
};

const EXPORT_STATUS: Record<string, string> = {
  [STATUS_DONE]: ACTIONS.DELETE,
  [STATUS_ERROR]: ACTIONS.DELETE_ERROR,
  default: ACTIONS.DELETE_IN_PROGRESS,
};

// [MODULE_EDLOS]: same as los,
const MODULE_ID: Record<string, string> = {
  [MODULE_LOS]: TEALIUM.CATEGORY.LOS,
  [MODULE_CUSTOMER]: TEALIUM.CATEGORY.GLOBAL_CML_EXPORT,
  [MODULE_ORDER]: TEALIUM.CATEGORY.GLOBAL_CML_EXPORT,
  [MODULE_BIRTHDAYS_AR]: TEALIUM.CATEGORY.BIRTHDAYS_AR,
  [MODULE_GOALS_AR]: TEALIUM.CATEGORY.GOALS_AR,
  [MODULE_PROVISIONAL_AR]: TEALIUM.CATEGORY.PROVISIONAL_AR,
  [MODULE_CBR_AR]: TEALIUM.CATEGORY.CBR_AR,
  [MODULE_CVR_AR]: TEALIUM.CATEGORY.CVR_AR,
  [MODULE_WATCHLIST]: TEALIUM.CATEGORY.WATCHLIST,
  [MODULE_WHOSNEW]: TEALIUM.CATEGORY.WHOSNEW,
  [MODULE_WHOS_CLOSE]: TEALIUM.CATEGORY.WHOS_CLOSE,
  [MODULE_NON_BONUSABLE_BV_AR]: TEALIUM.CATEGORY.NON_BONUSABLE_BV_AR,
  [MODULE_RENEWALS]: TEALIUM.CATEGORY.RENEWALS,
};

/**
 * Utility function to track user actions.
 * @param {string} section - The section where the action takes place.
 * @param {string} action - The action being tracked.
 * @param {string} [category=TEALIUM.CATEGORY.LOS] - The category of the action.
 */
const trackUtil = (section: string, action: string, category: string = TEALIUM.CATEGORY.LOS) => {
  track(TEALIUM.U_TAG_METHOD.LINK, TEALIUM.EVENT_NAME.CLICK_ACTION, section, category, "", action);
};

/**
 * Retrieves the module ID type.
 * @param {string} moduleId - The module ID.
 * @returns {string} The associated module category.
 */
const getModuleIdType = (moduleId: string) => MODULE_ID[moduleId];

/**
 * Tracks export menu type selection.
 * @param {string} exportType - The type of export selected.
 */
export const exportMenuTypeTrack = (exportType: string) =>
  trackUtil(TEALIUM.SECTION.EXPORT_MODAL, EXPORT_TYPE_MENU[exportType]);

/**
 * Tracks viewing the export menu type.
 * @param {string} moduleId - The module ID.
 */
export const exportViewMenuTypeTrack = (moduleId: string) =>
  trackUtil(TEALIUM.SECTION.EXPORT_MODAL, ACTIONS.VIEW_EXPORT, getModuleIdType(moduleId));

/**
 * Tracks exporting another menu type.
 * @param {string} moduleId - The module ID.
 * @param {string} isAnotherExport - The isAnotherExport button.
 */
export const exportAnotherMenuArTrack = (moduleId: string, isAnotherExport = false) => {
  const action = isAnotherExport ? ACTIONS.EXPORT_ANOTHER : ACTIONS.EXPORT;
  trackUtil(TEALIUM.SECTION.EXPORT_ANOTHER_MODAL, action, getModuleIdType(moduleId));
};

/**
 * Tracks exporting another menu type.
 * @param {string} moduleId - The module ID.
 */
export const exportAnotherMenuTypeTrack = (moduleId: string) =>
  trackUtil(TEALIUM.SECTION.EXPORT_ANOTHER_MODAL, ACTIONS.EXPORT_ANOTHER, getModuleIdType(moduleId));

/**
 * Retrieves the export download type.
 * @param {string} exportType - The type of export.
 * @param {boolean} filteredByKPIs - Whether the export is filtered by KPIs.
 * @returns {string} The export download type.
 */
const getExportDownloadType = (exportType: string, filteredByKPIs: boolean) => {
  return EXPORT_TYPE_DOWNLOAD[exportType === FULL_MAP && filteredByKPIs ? FULL_MAP_SELECTED_KPIS : exportType];
};

/**
 * Tracks export download action.
 * @param {string} exportType - The type of export.
 * @param {string} formatId - The format of the export.
 * @param {boolean} filteredByKPIs - Whether the export is filtered by KPIs.
 * @param {string} moduleId - The module ID.
 */
export const exportDownloadTypeTrack = (
  exportType: string,
  formatId: string,
  filteredByKPIs: boolean,
  moduleId: string,
) => {
  trackUtil(getExportDownloadType(exportType, filteredByKPIs), FORMAT_ID[formatId], getModuleIdType(moduleId));
};

/**
 * Tracks AR export download action.
 * @param {string} formatId - The format of the export.
 * @param {string} moduleId - The module ID.
 */
export const exportDownloadArTrack = (formatId: string, moduleId: string) => {
  trackUtil(TEALIUM.SECTION.EXPORT_ANOTHER_MODAL, FORMAT_ID[formatId], getModuleIdType(moduleId));
};

/**
 * Tracks export deletion action.
 * @param {string} exportType - The type of export.
 * @param {string} exportStatus - The status of the export.
 * @param {boolean} filteredByKPIs - Whether the export is filtered by KPIs.
 * @param {string} moduleId - The module ID.
 */
export const exportDeleteTypeTrack = (
  exportType: string,
  exportStatus: string,
  filteredByKPIs: boolean,
  moduleId: string,
) => {
  const statusState = EXPORT_STATUS[exportStatus] ?? EXPORT_STATUS.default;
  trackUtil(getExportDownloadType(exportType, filteredByKPIs), statusState, getModuleIdType(moduleId));
};

/**
 * Tracks AR export deletion action.
 * @param {string} exportStatus - The status of the export.
 * @param {string} moduleId - The module ID.
 */
export const exportDeleteArTrack = (exportStatus: string, moduleId: string) => {
  const statusState = EXPORT_STATUS[exportStatus] ?? EXPORT_STATUS.default;
  trackUtil(TEALIUM.SECTION.EXPORT_ANOTHER_MODAL, statusState, getModuleIdType(moduleId));
};
