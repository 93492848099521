/**
 * Common award codes used in the marketConfig service checks and within
 */
export const AWARDCODE: Readonly<{ [key: string]: number }> = Object.freeze({
  CUSTOMER_SALES_INCENTIVE_KZ: 925,
  CUSTOMER_SALES_INCENTIVE: 926,
  BRONZE_FOUNDATION: 930,
  BRONZE_BUILDER: 931,
  PERFORMANCE_PLUS: 940,
  PERSONAL_GROWTH_MONTHLY: 954,
  PERSONAL_GROUP_GROWTH: 955,
  PERSONAL_GROUP_R: 959,
  PERSONAL_GROUP_GROWTH_KICKER: 956,
  FRONTLINE_GROWTH: 960,
  FRONTLINE_GROWTH_KICKER: 961,
});

export enum TTCI_AWARD_CODES {
  SILVER = 968,
  GOLD = 969,
  PLATINUM = 970,
  FOUNDERS_PLATINUM = 971,
  SAPPHIRE = 972,
  FOUNDERS_SAPPHIRE = 973,
  IN_MARKET_EMERALD = 974,
  IN_MARKET_FOUNDERS_EMERALD = 975,
  IN_MARKET_DIAMOND = 976,
  IN_MARKET_FOUNDERS_DIAMOND = 977,
}

export const TTCI_MARKET_AWARD_CODES: Readonly<number[]> = [
  TTCI_AWARD_CODES.SILVER,
  TTCI_AWARD_CODES.GOLD,
  TTCI_AWARD_CODES.PLATINUM,
  TTCI_AWARD_CODES.FOUNDERS_PLATINUM,
  TTCI_AWARD_CODES.SAPPHIRE,
  TTCI_AWARD_CODES.FOUNDERS_SAPPHIRE,
  TTCI_AWARD_CODES.IN_MARKET_EMERALD,
  TTCI_AWARD_CODES.IN_MARKET_FOUNDERS_EMERALD,
  TTCI_AWARD_CODES.IN_MARKET_DIAMOND,
  TTCI_AWARD_CODES.IN_MARKET_FOUNDERS_DIAMOND,
];
