import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async";
import { mapAttributes } from "../../common/util/config";
import { storedLocale } from "../impersonation/util";
import { SELECTOR_TYPE, updateSelectorType } from "../../reducers/period";
import { useAppDispatch, useAppSelector } from "../../store";
import { showProfile } from "../../reducers/profile";
import { ErrorPage, ERROR_TYPE } from "../ErrorPage/ErrorPage";
import ABOProfile from "../ABOProfile/ABOProfile";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { MODULE_RENEWALS } from "../../common/constants/export-constants";
import { OpenExportV2ModalEvent } from "../ServerSideExport/ServerSideExportCommon/ServerSideExportUtil/ServerSideExportEvents";
import { ReportExportModal } from "../ServerSideExport/ReportExportModal/ReportExportModal";
import ServerSideExportCompletedNotify from "../ServerSideExport/ServerSideExportCommon/ServerSideExportNotify/ServerSideExportCompletedNotify/ServerSideExportCompletedNotify";
import ServerSideExportInProgressNotify from "../ServerSideExport/ServerSideExportCommon/ServerSideExportNotify/ServerSideExportInProgressNotify/ServerSideExportInProgressNotify";
import { LOS_GRID_EVENT } from "../Lit/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { useActionReportMenuOptions } from "./hooks/useActionReportMenuOptions";
import { getARComponentConfiguration } from "./utils/ActionReportHelpers";
import "./ActionReports.scss";

const loadComponent = async () => {
  return import("../Lit/acc-wc-los-ar/acc-wc-los-ar.js");
};

export default function RenewalsActionReport() {
  const locale = storedLocale().toLowerCase();
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { configuration } = useAppSelector((state) => state.boot);
  const { renewalsAr = {} } = getARComponentConfiguration(configuration);
  const { actionReportMenuOptions } = useActionReportMenuOptions();
  const dispatch = useAppDispatch();
  const [apiError, setApiError] = useState(false);
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();
  const [openExportModal, setExportModalState] = useState(false);
  const [hasNoExportData, setHasNoExportData] = useState(false);

  useEffect(() => {
    dispatch(updateSelectorType(SELECTOR_TYPE.MONTHLY));
  }, [dispatch]);

  const updatedRenewalsAr = useMemo(() => {
    return {
      ...renewalsAr,
      uiGrid: renewalsAr.uiGrid
        .split(",")
        .filter((item: string) => item !== "uiMenuShowPerfHistory")
        .join(","),
    };
  }, [renewalsAr]);

  useEffect(() => {
    if (!isPending) {
      const arComponent = document.getElementById("acc_wc_renewals_ar") as HTMLElement;
      mapAttributes(arComponent, updatedRenewalsAr, {});
    }
  }, [isPending, updatedRenewalsAr]);

  useEffect(() => {
    if (!apiError) {
      window.addEventListener("acc-api-error", () => setApiError(true));
    }

    if (apiError) {
      window.removeEventListener("acc-api-error", () => {});
    }
  }, [apiError]);

  const showABOProfile = useCallback(
    (event: CustomEventInit) => {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    };
  }, [showABOProfile]);

  const showExportModal = useCallback((event: CustomEventInit) => {
    setExportModalState(Boolean(event.detail?.open));
    setHasNoExportData(Boolean(event.detail?.hasNoExportData));
  }, []);

  useEffect(() => {
    window.addEventListener(OpenExportV2ModalEvent, showExportModal);
    return () => {
      window.removeEventListener(OpenExportV2ModalEvent, showExportModal);
    };
  }, [showExportModal]);

  const notifyDownloadLinkOnClick = () => {
    setExportModalState(true);
  };

  const closeExportModal = () => {
    setExportModalState(false);
  };

  if (apiError) {
    return <ErrorPage errorType={ERROR_TYPE.GENERIC_ERROR} />;
  }

  const renderRenewalsAr = () => {
    return (
      <acc-wc-los-ar
        id="acc_wc_renewals_ar"
        locale={locale}
        period={selectedPeriod}
        uiHeaderReportSelectorOptions={JSON.stringify(actionReportMenuOptions)}
        enableExportV2={true}
      ></acc-wc-los-ar>
    );
  };

  if (isPending) {
    return <div className="actionReportWrapper"></div>;
  }
  return (
    <main>
      <div id="ar-renewals-wrapper" className="actionReportWrapper">
        {renderRenewalsAr()}
      </div>
      <ABOProfile previousElement={modalOpenedByElement} useCurrentMonthData={true} />
      <BonusInfoFooter hideDate />
      <ServerSideExportInProgressNotify />
      <ServerSideExportCompletedNotify
        moduleId={MODULE_RENEWALS}
        notifyDownloadLinkOnClick={notifyDownloadLinkOnClick}
      />
      <ReportExportModal
        moduleId={MODULE_RENEWALS}
        hasNoExportData={hasNoExportData}
        open={openExportModal}
        onCloseCallbackFn={closeExportModal}
      />
    </main>
  );
}
