import { AWARD_CODES } from "../../enums/award-codes";
import { DROPDOWN_LABELS, DROPDOWN_KEYS, ROUTE_LABELS, ROUTE_PATHS } from "../../enums/routing-enums";
import { NavData, NavDropdown } from "../../interfaces/routing";
import { store } from "../../../store";
import { Config } from "../../interfaces/config";
import { BootUser } from "../../../reducers/boot";
import { filterDropdown } from "./createMenuConfig";

export const createResourcesDropdown = (
  navConfig: NavData[],
  learnMoreLink: string,
  { user, configuration }: { user: BootUser; configuration: Config },
): NavDropdown => {
  let items = [
    {
      label: ROUTE_LABELS.FEEDBACK,
      path: ROUTE_PATHS.FEEDBACK,
      class: ROUTE_PATHS.FEEDBACK,
      navPosition: 0,
    },
    {
      label: ROUTE_LABELS.LEARN_ABOUT_CPP,
      path: learnMoreLink || ROUTE_PATHS.LEARN_ABOUT_CPP,
      class: ROUTE_PATHS.LEARN_ABOUT_CPP,
      code: AWARD_CODES.LEARN_ABOUT_CPP,
      navPosition: 1,
    },
    {
      label: ROUTE_LABELS.LOG_OUT,
      path: ROUTE_PATHS.LOG_OUT,
      class: ROUTE_PATHS.LOG_OUT,
      navPosition: 2,
    },
    {
      label: ROUTE_LABELS.ACADEMY_V2,
      path: ROUTE_PATHS.ACADEMY,
      class: ROUTE_PATHS.ACADEMY,
      navPosition: 3,
    },
  ];
  const { navigation } = configuration;

  items = navigation.showFeedback ? items : items.filter((el) => el.label !== ROUTE_LABELS.FEEDBACK);
  items = store.getState().boot.shellConfig.showLogoutLink
    ? items
    : items.filter((el) => el.label !== ROUTE_LABELS.LOG_OUT);

  items = configuration?.externalIntegration?.academy?.useIframe
    ? items
    : items.filter((el) => el.label !== ROUTE_LABELS.ACADEMY_V2);

  const resourcesDropdown = {
    label: DROPDOWN_LABELS.RESOURCES,
    key: DROPDOWN_KEYS.RESOURCES,
    items,
  };

  return filterDropdown(navConfig, resourcesDropdown);
};
