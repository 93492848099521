import FAASuperLeg, { FAASuperLegPayload } from "./faaSuperLeg";

export interface TrackingSummary {
  hasMultiplier: boolean;
  totalBonus: number;
  projectedBonus: number;
  coreIncome: number;
  multiplier: number;
}

export interface SuperLegsSummary {
  bonusDetail: Array<unknown>;
  legDetail: Array<FAASuperLegPayload>;
}

export interface FAASummaryPayload {
  trackingSummary: TrackingSummary;
  superLegsSummary: SuperLegsSummary;
  isoCurrency: string;
  isoCountry: string;
}

export interface ProjectedBonusDetails {
  projectedBonus: number;
  coreIncome: number;
  multiplier: number;
}

/**
 * A model of a FAA Summary
 *
 * @export
 * @class FAASummary
 */

export default class FAASummary {
  __payload: FAASummaryPayload;
  __trackingSummary: TrackingSummary;
  __superLegsSummary: SuperLegsSummary;
  /**
   * Creates an instance of payload.
   * @param {object} payload
   * @memberof FAASummary
   */
  constructor(payload: FAASummaryPayload) {
    this.__payload = payload;
    this.__trackingSummary = payload.trackingSummary;
    this.__superLegsSummary = payload.superLegsSummary;
  }

  /**
   * Gets if abo is earning or not
   * @returns {Boolean}
   * @memberof FAASummary
   */
  isEarning(): boolean {
    const { hasMultiplier, totalBonus } = this.__trackingSummary;
    return hasMultiplier && totalBonus > 0;
  }

  /**
   * Gets iso currency code
   * @returns {String}
   * @memberof FAASummary
   */
  get currencyCode(): string {
    return this.__payload.isoCurrency;
  }

  /**
   * Gets iso country code
   * @returns {String}
   * @memberof FAASummary
   */
  get country(): string {
    return this.__payload.isoCountry;
  }

  /**
   * Gets tracking summary details
   * @returns {TrackingSummary}
   * @memberof FAASummary
   */
  get trackingSummaryDetails(): TrackingSummary {
    return this.__trackingSummary;
  }

  /**
   * Gets projected bonus details
   * @returns {Object}
   * @memberof FAASummary
   */
  get projectedBonusDetails(): ProjectedBonusDetails {
    const { projectedBonus, coreIncome, multiplier } = this.__trackingSummary;
    return { projectedBonus, coreIncome, multiplier };
  }

  /**
   * Gets super leg bonus details
   * @returns {String}
   * @memberof FAASummary
   */
  get superLegBonusDetails(): SuperLegsSummary {
    return this.__superLegsSummary;
  }

  /**
   * Gets bonus detail
   * @returns {Array}
   * @memberof FAASummary
   */
  get bonusDetail(): Array<unknown> {
    return this.__superLegsSummary.bonusDetail;
  }

  /**
   * Gets bonus detail
   * @returns {FAASuperLeg[]}
   * @memberof FAASummary
   */
  get legDetails(): Array<FAASuperLeg> {
    return this.__superLegsSummary.legDetail
      .map((leg) => new FAASuperLeg(leg))
      .sort((a, b) => {
        return a.points - b.points;
      });
  }
}
