import { useTranslation } from "react-i18next";
import { storedLocale } from "../../../../../components/impersonation/util";
import defaultContent from "../../../../../content/profile";
import "./LeafPreferredLanguageInfo.scss";

type Props = {
  isoLanguage: string;
  privacyFlag?: boolean;
  show?: boolean;
};

const LeafPreferredLanguageInfo = (props: Props) => {
  const { isoLanguage, privacyFlag, show } = props;
  const { t } = useTranslation(["profile"]);
  const locale = storedLocale();

  const formatLanguage = () => {
    try {
      if (isoLanguage && isoLanguage !== "--") {
        return new Intl.DisplayNames(locale, { type: "language" }).of(isoLanguage);
      }
    } catch (error) {
      /* empty */
    }

    return `${t("leaf.noPreferredLanguage", defaultContent["leaf"]["noPreferredLanguage"])}`;
  };

  const privacyView = privacyFlag === true ? "-" : formatLanguage();

  if (show === true) {
    return (
      <div className="leaf-language">
        <div className="leaf-language__container">
          <div className="leaf-language__content-title">
            {`${t("leaf.preferredLanguage", defaultContent["leaf"]["preferredLanguage"])}`}
          </div>
          <div className="leaf-language__content-data">{privacyView}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafPreferredLanguageInfo;
