import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Markets } from "../../components/Lit/acc-es-data/model";
import { fetchConfig } from "../../common/util/config";
import { setUser } from "../../reducers/boot";
import { store } from "../../store";
import { SetLoginFields } from "../../reducers/model/boot";
import { AUTH_ROUTE_PATHS } from "../../routing/AuthenticationRoutes";
import { normalizeLocale, normalizeSsoOrigin, redirectAuthFailed } from "./impersonation-util";

export const AmwayIdLogin = () => {
  const [errorText, setErrorText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const locale = normalizeLocale(urlParams.get("locale"));
      const abo = urlParams.get("abo");
      const aff = urlParams.get("aff") || null;
      const entryPage = urlParams.get("entryPage");
      const targetAbo = urlParams.get("targetAbo");
      let ssoOrigin = normalizeSsoOrigin(urlParams.get("ssoOrigin"));

      if (ssoOrigin) {
        SetLoginFields({ fields: { ssoOrigin: ssoOrigin } });
        const isoCountryCode = ssoOrigin;
        store.dispatch(setUser({ user: { isoCountryCode } }));
      }

      if (aff === null && ssoOrigin === null) {
        setErrorText(
          'Missing both "aff" and "ssoOrigin" params. At least one is required. Redirecting to auth failed page.',
        );
        redirectAuthFailed(navigate);
        return;
      }

      // Pick an ssoOrigin if one was not provided
      if (ssoOrigin === null) {
        ssoOrigin = new Markets().getByCtry(aff!).iso;
      }

      const config = await fetchConfig(`/configs/${ssoOrigin}.configuration.json`);

      if (locale !== null) {
        SetLoginFields({ fields: { locale: locale } });
      }

      if (entryPage !== null) {
        SetLoginFields({ fields: { entryPage: entryPage } });
      }

      if (targetAbo !== null) {
        SetLoginFields({ fields: { targetAbo: targetAbo } });
      }

      SetLoginFields({
        fields: {
          ssoOrigin: ssoOrigin,
          loginOrigin: "amwayid",
        },
      });

      const generateSecureRandomNumber = () => {
        const array = new Uint32Array(1);
        crypto.getRandomValues(array);
        return array[0];
      };

      const crypto = (window as any).crypto || (window as any).msCrypto;

      const timeStamp = ((Date.now() * generateSecureRandomNumber()) % Number.MAX_SAFE_INTEGER).toString();

      // Build authentication url

      const nonce = timeStamp;
      let state;
      const amw_lng = locale;
      const ANA_MARKETS = ["CA", "DO", "US"];
      const amw_clientapp = ANA_MARKETS.includes(ssoOrigin) ? "coreplusUS" : `coreplus${ssoOrigin}`;
      const uid = timeStamp;

      if (aff !== null && abo !== null) {
        state = {
          amw_lng,
          amw_clientapp,
          uid,
          sales_plan_aff: aff,
          abo: abo,
        };
      } else {
        state = {
          amw_lng,
          amw_clientapp,
          uid,
        };
      }

      window.sessionStorage.setItem("uid", state.uid);
      const encodedState = window.btoa(JSON.stringify(state));
      const amwayIdAuthDomainV1 = `${config.amwayIdDomain}/v1/proxy/oauth2/${config.amwayIdAuthServer}/v1/authorize`;
      const authDomain = config.useGluuAuth ? config.amwayIdIdpUrl : amwayIdAuthDomainV1;
      let url = `${authDomain}?response_mode=query&response_type=${config.tokenResponseType}&redirect_uri=${config.cppModAmwayIdRedirectUri}&client_id=${config.amwayIdClientId}`;

      if (!config.enableNextGenToken) {
        url = `${url}&scope=${config.oktaScope}&nonce=${nonce}&state=${encodedState}`;
      } else {
        // extra logic for next gen flow to pass aff and abo in scope for multiple accounts scenario.
        if (aff && abo) {
          const scope = `salesPlanAff=${aff}aboNum=${abo}`;
          url = `${url}&scope=${scope}&nonce=${nonce}&state=${encodedState}`;
        }
      }

      if (config.maintenanceConfig.appMaintenance) {
        navigate(AUTH_ROUTE_PATHS.MAINTENANCE);
      } else {
        window.location.replace(url);
      }
    })();
  }, [navigate]);

  return <h1 style={{ display: "flex", justifyContent: "center", color: "#002f5f" }}>{errorText}</h1>;
};
