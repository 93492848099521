import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
  QueryDefinition,
} from "@reduxjs/toolkit/query/react";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { dynamicBaseQuery } from "../";
import { ShellConfigApiResponse } from "./getShellConfigAPI.types";

type MyBizShellRequestParams = {
  abo: string;
  aff: string;
  period: string;
  faaEligibilityWindow?: number;
  optionalPeriodRange?: number;
};

export const mybizShellAPI = createApi({
  reducerPath: "shellConfig",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getMyBizShellConfig: builder.query<ShellConfigApiResponse, MyBizShellRequestParams>({
      query: (params) => {
        const { abo, aff, period, faaEligibilityWindow = 12, optionalPeriodRange } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Period: period,
          FaaEligibilityWindow: faaEligibilityWindow.toString(),
        });

        if (optionalPeriodRange) queryParams.append("OptionalPeriodRange", `${optionalPeriodRange}`);

        return `/mybiz-shell/api/config?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
      // keep data for 1 hour
      keepUnusedDataFor: 3600,
    }),
  }),
});

export type LazyGetMyBizShellConfigQueryType = LazyQueryTrigger<
  QueryDefinition<
    MyBizShellRequestParams,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, object, FetchBaseQueryMeta>,
    never,
    ShellConfigApiResponse,
    "shellConfig"
  >
>;

export const { useGetMyBizShellConfigQuery, useLazyGetMyBizShellConfigQuery } = mybizShellAPI;
