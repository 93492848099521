import { useState } from "react";
import IconLoadingCircle from "../IconLoadingCircle/IconLoadingCircle";

type Props = {
  show?: boolean;
  loading?: boolean;
};

export const IconXls = (props: Props) => {
  const { show = true, loading = false } = props;
  const [hovered, setHovered] = useState(false);

  if (show === false) {
    return <></>;
  }

  if (loading === true) {
    return <IconLoadingCircle />;
  }

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <circle cx="18" cy="18" r="18" fill={hovered ? "#000" : "#2C2C2C"} />
      <path
        d="M10.5 26.125H13.625V28H10.5C9.12109 28 8 26.8789 8 25.5V10.5C8 9.12109 9.12109 8 10.5 8H16.9648C17.6289 8 18.2656 8.26172 18.7344 8.73047L22.2695 12.2656C22.7383 12.7344 23 13.3711 23 14.0352V19.875H21.125V14.25H18C17.3086 14.25 16.75 13.6914 16.75 13V9.875H10.5C10.1562 9.875 9.875 10.1562 9.875 10.5V25.5C9.875 25.8438 10.1562 26.125 10.5 26.125ZM16.75 22.375C16.75 22.6602 16.8359 22.9375 16.9922 23.1719L17.375 23.7461L17.7578 23.1719C17.9141 22.9336 18 22.6562 18 22.375C18 22.0312 18.2812 21.75 18.625 21.75C18.9688 21.75 19.25 22.0312 19.25 22.375C19.25 22.9062 19.0938 23.4258 18.7969 23.8672L18.125 24.875L18.7969 25.8828C19.0938 26.3242 19.25 26.8438 19.25 27.375C19.25 27.7188 18.9688 28 18.625 28C18.2812 28 18 27.7188 18 27.375C18 27.0898 17.9141 26.8125 17.7578 26.5781L17.375 26L16.9922 26.5742C16.8359 26.8125 16.75 27.0898 16.75 27.3711C16.75 27.7148 16.4688 27.9961 16.125 27.9961C15.7812 27.9961 15.5 27.7148 15.5 27.3711C15.5 26.8398 15.6563 26.3203 15.9531 25.8789L16.625 24.875L15.9531 23.8672C15.6563 23.4258 15.5 22.9062 15.5 22.375C15.5 22.0312 15.7812 21.75 16.125 21.75C16.4688 21.75 16.75 22.0312 16.75 22.375ZM20.5 27.375V22.375C20.5 22.0312 20.7812 21.75 21.125 21.75C21.4688 21.75 21.75 22.0312 21.75 22.375V26.75H23C23.3438 26.75 23.625 27.0312 23.625 27.375C23.625 27.7188 23.3438 28 23 28H21.125C20.7812 28 20.5 27.7188 20.5 27.375ZM23.9375 23.5352C23.9375 22.5508 24.7344 21.75 25.7227 21.75H26.75C27.0938 21.75 27.375 22.0312 27.375 22.375C27.375 22.7188 27.0938 23 26.75 23H25.7227C25.4297 23 25.1875 23.2383 25.1875 23.5352C25.1875 23.7383 25.3008 23.9219 25.4844 24.0117L26.7031 24.6211C27.3086 24.9219 27.6875 25.5391 27.6875 26.2148C27.6875 27.1992 26.8906 28 25.9023 28H24.875C24.5312 28 24.25 27.7188 24.25 27.375C24.25 27.0312 24.5312 26.75 24.875 26.75H25.9023C26.1953 26.75 26.4375 26.5117 26.4375 26.2148C26.4375 26.0117 26.3242 25.8281 26.1406 25.7383L24.9219 25.1289C24.3203 24.8281 23.9375 24.2109 23.9375 23.5352Z"
        fill="white"
      />
    </svg>
  );
};
