import { AWARDCODE } from "../enums/awardCodes";

/**
 * @typedef Awards
 * @property {string} isoCountry
 * @property {number} awardNumber
 * @property {number} bonusPeriod
 * @property {number} effectivePeriod
 * @property {Boolean} programActive
 * @property {string[]} ineligibleBusinessNatures
 */

/**
 * A model of an MarketConfig
 *
 * @property {Awards[]} awards
 * @property {string} businessNature
 */
export default class MarketConfig {
  businessNature: string;
  awards: Array<{
    isoCountry: string;
    awardNumber: number;
    bonusPeriod: number;
    comingSoon: boolean;
    effectivePeriod: number;
    firstEffectivePeriod: number;
    programActive: boolean;
    ineligibleBusinessNatures: Array<string>;
  }>;
  /**
   * Creates an instance of MarketConfig.
   * @param {Awards[]} awards
   */
  constructor(payload: {
    businessNature: string;
    awards: Array<{
      isoCountry: string;
      awardNumber: number;
      bonusPeriod: number;
      comingSoon: boolean;
      effectivePeriod: number;
      firstEffectivePeriod: number;
      programActive: boolean;
      ineligibleBusinessNatures: Array<string>;
    }>;
  }) {
    this.awards = payload.awards;
    this.businessNature = payload.businessNature;
  }

  /**
   * Gets the status of if the award code is available in the market
   * @param {number} code
   * @returns {Boolean}
   */
  isAwardAvailable(code: number, checkComingSoon: boolean): boolean {
    const { isoCountry } = this.awards[0];
    if ((code === AWARDCODE.BRONZE_FOUNDATION || code === AWARDCODE.BRONZE_BUILDER) && isoCountry === "KZ") {
      let eligible = true;
      this.awards
        .filter((award) => award.awardNumber === code)
        .map((eligibility) => eligibility.ineligibleBusinessNatures)[0]
        .forEach((element) => {
          // hide CSI for ABOS #1
          // hide bronze for provisional ABOs #2
          if (code === AWARDCODE.CUSTOMER_SALES_INCENTIVE_KZ && element === this.businessNature) {
            eligible = false;
          }
          if (
            (code === AWARDCODE.BRONZE_FOUNDATION || code === AWARDCODE.BRONZE_BUILDER) &&
            element === this.businessNature
          ) {
            eligible = false;
          }
        });

      // checks for bad MAGIC data returning empty information
      const dataCheck = this.awards
        .filter((award) => award.awardNumber === code)
        .map((eligibility) => eligibility.ineligibleBusinessNatures)[0];
      if (dataCheck[0] === undefined) {
        return false;
      }
      return eligible;
    }
    //checks businessNature in ineligibleBusinessNatures, if it is not available then returns programActive
    const requiredAward = this.awards.find((award) => award.awardNumber === code);
    return (
      requiredAward?.ineligibleBusinessNatures.indexOf(this.businessNature) === -1 &&
      (requiredAward.programActive || (checkComingSoon ? requiredAward.comingSoon : false))
    );
  }

  /**
   * Gets the date of the effective period
   * @param {number} code
   * @returns {number}
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isEffectivePeriod(code: number): number | null {
    return this.awards.find((award) => award.awardNumber === code)?.firstEffectivePeriod || null;
  }

  /**
   * Gets the market config details for the specified award code
   * @param {number} code
   * @returns {object}
   */
  awardCode(code: number): Record<string, unknown> | null {
    return this.awards.find((award) => award.awardNumber === code) || null;
  }
}
