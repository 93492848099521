import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import LeafTileTitleLink from "../../../../../common/leaf/LeafTileTitleLink/LeafTileTitleLink";
import defaultContent from "../../../../../content/annualPerformanceDashboard";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import "./FaaTrackingTile.scss";
import { AWARD_PROGRESS } from "../../../../../common/enums/award-progress-codes";
import { storedLocale } from "../../../../impersonation/util";
import TEALIUM from "../../../../../common/enums/tealium-enums";
import { track } from "../../../../../common/util/tealium";
import { AwardProgressBadge } from "../../../../../common/components/AwardProgressBadge/AwardProgressBadge";
import { getLongPeriodFormat } from "../../../../../common/util/period";
import { AnnualTrackingFAATile } from "../../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";

type Props = {
  tileData: AnnualTrackingFAATile;
  isLoading: boolean;
};

export const FaaTrackingTile = ({ tileData, isLoading }: Props) => {
  const { DbrStatus, FoundersPlatinumLegs, FaaPoints, FaaPercent, TrackingBonus, LastMonthProcessed } = tileData;
  const { t } = useTranslation(["annualPerformanceDashboard"]);
  const locale = storedLocale();
  const dbrBadge = DbrStatus ? AWARD_PROGRESS.TRACKING : AWARD_PROGRESS.NOT_TRACKING;
  const desktopFaaTitle = t("foundersAchievementAwardTracking", defaultContent["foundersAchievementAwardTracking"]);
  const mobileFaaTitle = t("faaTracking", defaultContent["faaTracking"]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const faaTitle = windowWidth >= 620 ? desktopFaaTitle : mobileFaaTitle;

  const trackTileLinkClick = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "Founders Achievement Award Tracking",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "trackingTile",
    );
  };

  return (
    <LeafTile isLoading={isLoading}>
      <div className="FaaTrackingTile">
        <LeafTileTitleLink title={faaTitle} link={ROUTE_PATHS.FAA} onClick={trackTileLinkClick} />
        <p className="FaaTrackingTile__subtitle">
          {t("lastMonthProcessed", defaultContent["lastMonthProcessed"])}:{" "}
          <span>{LastMonthProcessed > 0 ? getLongPeriodFormat(LastMonthProcessed.toString(), locale) : "--"}</span>
        </p>
        <LeafTileDivider />
        <section className="FaaTrackingTile__data--section">
          <p className="FaaTrackingTile__row-label FaaTrackingTile__dbrStatus">
            {t("dbrStatus", defaultContent["dbrStatus"])}
          </p>
          <span className="FaaTrackingTile__row-value FaaTrackingTile__dbrStatus">
            <AwardProgressBadge status={dbrBadge} id={`FaaBadge__${dbrBadge}`} />
          </span>

          <p className="FaaTrackingTile__row-label">{t("fPlatLegs", defaultContent["fPlatLegs"])}</p>
          <span className="FaaTrackingTile__row-value">{DbrStatus ? FoundersPlatinumLegs : "--"}</span>

          <p className="FaaTrackingTile__row-label">{t("faaPoints", defaultContent["faaPoints"])}</p>
          <span className="FaaTrackingTile__row-value">{DbrStatus ? FaaPoints : "--"}</span>

          <p className="FaaTrackingTile__row-label">{t("faaMultiplier", defaultContent["faaMultiplier"])}</p>
          <span className="FaaTrackingTile__row-value">{DbrStatus ? FaaPercent : "--"}</span>

          <p className="FaaTrackingTile__row-label">{t("trackingBonus", defaultContent["trackingBonus"])}</p>
          <span className="FaaTrackingTile__row-value">{DbrStatus ? TrackingBonus : "--"}</span>
        </section>
      </div>
    </LeafTile>
  );
};
