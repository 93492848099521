import { getAllErrorRoutes } from "../../routing/ErrorRoutes";
import { ROUTE_LABELS, ROUTE_PATHS } from "../enums/routing-enums";

/**
 * Refer to **removePeriodSelectorFromRoute** array from config file for additional
 * routes without period selector.
 */
export const ROUTES_WITHOUT_PERIOD_SELECTOR: string[] = [
  ROUTE_PATHS.EARNINGS_CALCULATOR,
  ROUTE_PATHS.EDLOS,
  ROUTE_PATHS.FEEDBACK,
  ROUTE_PATHS.LEARN_ABOUT_CPP,
  ROUTE_PATHS.IBO_ONBOARDING,
  ROUTE_PATHS.CBR,
  ROUTE_PATHS.WHOS_CLOSE_ACTION_REPORT,
  ROUTE_PATHS.FAA,
  ROUTE_PATHS.ACTION_REPORTS,
  ROUTE_PATHS.RENEWALS,
  ...getAllErrorRoutes(),
];
export const INNER_ROUTE_SHOW_PERIOD_SELECTOR: string[] = [ROUTE_PATHS.EDLOS_DOWNLINE];

export const POLYMER_2_ROUTES: string[] = [ROUTE_PATHS.EARNINGS_CALCULATOR];

export const ROUTE_TO_EXTERNAL = [ROUTE_LABELS.LEARN_ABOUT_CPP, ROUTE_LABELS.ACADEMY];

// Always display dropdown items based on the ROUTE_LABEL
export const ALWAYS_DISPLAY: string[] = [ROUTE_LABELS.FEEDBACK, ROUTE_LABELS.LOG_OUT, ROUTE_LABELS.ACADEMY_V2];
