/* eslint-disable no-nested-ternary */
import FAAIncomeBusiness from "./faaIncomeBusiness";
import { FAAIncomeBusinessPayload } from "./faaIncomeBusiness";

export interface FAAIncomePayload {
  ABONo: number;
  Businesses?: Array<FAAIncomeBusinessPayload>;
}

/**
 * A model of a FAA Income
 *
 * @export
 * @class FAAIncome
 */
export default class FAAIncome {
  __p: FAAIncomePayload;
  __businesses: Array<FAAIncomeBusinessPayload>;
  /**
   * Creates an instance of payload.
   * @param {object} payload
   * @memberof FAAIncome
   */
  constructor(payload: FAAIncomePayload) {
    this.__p = payload;
    this.__businesses = (this.__p && this.__p.Businesses) || [];
  }

  /**
   * Returns models of FAAIncomeBusiness
   * sorted by requesting abo first and then by IncomeAverage descending
   * @returns {Array} an array of FAAIncomeBusiness model
   * @memberof FAAIncome
   */
  get businesses(): Array<FAAIncomeBusiness> {
    return (
      this.__businesses
        .sort((business1, business2) => {
          return business1.IncomeAverage > business2.IncomeAverage ? -1 : 1;
        })
        // eslint-disable-next-line no-nested-ternary
        .sort((business1, business2) => {
          return business1.ABONo === this.__p.ABONo ? -1 : business2.ABONo === this.__p.ABONo ? 1 : 0;
        })
        .map((business) => new FAAIncomeBusiness(business))
    );
  }
}
