import AboAffNum from "./aboAffNum";

export interface AboProfileFields {
  name?: string;
  localName?: string;
  country?: string;
  isoLanguageCode?: string;
  privacyFlag?: boolean;
}

export interface AboCodeFields {
  losType?: string;
  businessNature?: number;
  segment?: number;
  status?: number;
}

export interface AboSponsoringFields {
  upline?: AboAffNum;
  groupCount?: number;
  internationalUpline?: AboAffNum;
}

export interface AboDateFields {
  entryDate?: string;
}

export interface AboConstructorFields {
  affiliate: string;
  aboNumber: number;
  codes?: AboCodeFields;
  dates?: AboDateFields;
  profile?: AboProfileFields;
  sponsoring?: AboSponsoringFields | null;
}

/**
 * A model of an ABO
 *
 * @export
 * @class Abo
 */
export default class Abo extends AboAffNum {
  codes?: AboCodeFields;
  dates?: AboDateFields;
  profile?: AboProfileFields;
  sponsoring?: AboSponsoringFields | null;

  /**
   * Creates an instance of abo
   * @param {AboConstructorFields} aboConstructorFields
   */
  constructor(aboConstructorFields: AboConstructorFields) {
    super(aboConstructorFields);
    this.codes = aboConstructorFields.codes;
    this.dates = aboConstructorFields.dates;
    this.profile = aboConstructorFields.profile;
    this.sponsoring = aboConstructorFields.sponsoring;
  }
}
