import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { updatePeriod } from "../../reducers/period";
import { useAppDispatch } from "../../store";
import { getCurrentPeriod } from "../../common/util/period";
import ABOProfileSlider from "./ABOProfileSlider/ABOProfileSlider";
import ABOApplicantSlider from "./ABOApplicantSlider/ABOApplicantSlider";

export type Props = {
  previousElement?: HTMLElement;
  useCurrentMonthData?: boolean;
  showOnlyPerformanceHistory?: boolean;
};

const ABOProfile = (props: Props) => {
  const { previousElement, useCurrentMonthData = false, showOnlyPerformanceHistory = false } = props;

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (useCurrentMonthData === true) {
      dispatch(updatePeriod({ period: getCurrentPeriod() }));
    }
  }, [dispatch, useCurrentMonthData]);

  useTranslation(["profile", "common", "leafErrorMessage"]);

  return (
    <>
      <ABOProfileSlider showOnlyPerformanceHistory={showOnlyPerformanceHistory} previousElement={previousElement} />
      <ABOApplicantSlider />
    </>
  );
};

export default ABOProfile;
