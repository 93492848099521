const content: { [key: string]: any } = {
  pageTitle: "Customers & Orders",
  overview: "Overview",
  customerList: "Customer List",
  orderHistory: "Order History",
  customerTable: {
    customers: " Customers",
    name: " Name",
    tags: " Tag(s)",
    noOfOrders: "# of Orders",
    noOfVcsPv: "VCS PV/PV",
    lastOrdered: "Last Ordered",
    renewalDate: "Renewal Date",
    confidentialUser: "Confidential User",
    noData: "You have no customers or members to display in this view.",
  },
  customerTypeChart: {
    title: "Customer Type",
    counts: "Counts",
    legendPreferredCustomerText: "Preferred Customer",
    legendPreferredCustomerValue: "({COUNT}) {PERCENTAGE}%",
    legendGuestCustomerText: "Guest Customer",
    legendGuestCustomerValue: "({COUNT}) {PERCENTAGE}%",
    legendSharebarCustomerText: "Sharebar Customer",
    legendSharebarCustomerValue: "({COUNT}) {PERCENTAGE}%",
  },
  customerPVChart: {
    title: "Total Customer PV",
    cpv: "CPV",
    legendVcsPvValue: "({PV}) {PERCENTAGE}%",
    legendNonVcsPvText: "Non-VCS PV",
    legendNonVcsPvValue: "({PV}) {PERCENTAGE}%",
    legendOtherNonVcsPvText: "Other",
    legendOtherNonVcsPvValue: "({PV}) {PERCENTAGE}%",
  },
  personalPVChart: {
    title: "Total Personal PV",
    ppv: "PPV",
    legendPersonalOrderPvText: "Personal Order PV",
    legendPersonalOrderPvValue: "({PV}) {PERCENTAGE}%",
    legendCustomerPvText: "Customer PV",
    legendCustomerPvValue: "({PV}) {PERCENTAGE}%",
  },
  customerOverview: {
    subTitle: "{month} Stats",
    newCust: "New Customers",
    customerPV: "Customer PV",
    vcsPV: "Verified Customer PV",
    nonVcsPv: "Non-Verified Customer PV",
    totalOrders: "Total Customer Orders",
    totalCustomers: "Total Customers",
    multipleOrders: "Multiple Orders",
    idleCustomers: "Idle Customers",
    viewAll: "View All",
    newCustomerList: {
      title: "Recently Joined",
      labelNew: "New",
      lastOrderDate: "Last ordered",
    },
    recentOrderList: {
      title: "Recent Orders",
    },
  },
  customerListFilter: {
    all: "All",
    customerType: "Customer type",
    sort: "Sort",
    verified: "Verified",
    noVerified: "Non-Verified",
    newCustomer: "New",
    customer: "Customer",
    member: "Member",
    guest: "Guest Customer",
    sharebar: "Sharebar Customer",
    descLastOrderDate: "Recent order date",
    ascLastOrderDate: "Oldest order date",
    ascTotalCustomerPV: "Customer PV: Low - High",
    descTotalCustomerPV: "Customer PV: High - Low",
    ascNumberOrders: "Number of Orders: Low - High",
    descNumberOrders: "Number of Orders: High - Low",
    isCurrentMonthRenewalDate: "Renewal in Current Month",
  },
  orderFilter: {
    all: "All",
    orderType: "Order type",
    sort: "Sort",
    vcs: "VCS",
    nonVCS: "Non-VCS",
    return: "Return",
    order: "Order",
    uplift: "Uplift",
    adjustment: "Adjustment",
    reworks: "Rework",
    rms: "RMS Mass Upload Adjustment",
    cvr: "CVR",
    mars: "MARS",
    backdate: "Back Date",
    descOrderDate: "Recent order date",
    ascOrderDate: "Oldest order date",
    ascOrderPV: "Order PV: Low - High",
    descOrderPV: "Order PV: High - Low",
  },
  orderHistoryTable: {
    customerName: "Customer Name",
    orderDate: "Order Date",
    orderNumber: "Order Number",
    orderType: "Order Type",
    confidentialUser: "Confidential User",
    orders: "Orders",
    noOrders: {
      header: "You currently have no orders",
      subHeader: "New orders will be displayed here after your customer make a purchase or after you create a receipt",
      noOrdersFound: "No Orders Found",
    },
  },
  orderType: {
    nonVCSOrder: "Non-VCS Order",
    vcsOrder: "VCS Order",
    return: "Return",
    upliftOrder: "Uplift Order",
    adjustment: "Adjustment",
    reworks: "Rework",
    rmsAdjust: "RMS Mass Upload Adjustment",
    cvr: "CVR",
    mars: "MARS",
    backDate: "Back Date",
    other: "Other",
    order: "Order",
  },
  statistic: {
    positiveOrder: "<positive>{context}</positive> more orders than the previous month",
    negativeOrder: "<negative>{context}</negative> less orders than the previous month",
    neutralOrder: "<neutral>The same number</neutral> of orders as previous month",
    positiveCustomerPV: "<positive>{context}</positive> more Customer PV than the previous month",
    negativeCustomerPV: "<negative>{context}</negative> less Customer PV than the previous month",
    neutralCustomerPV: "<neutral>The same</neutral> Customer PV as previous month",
    positiveNewCustomer: "<positive>{context}</positive> more customers than the previous month",
    negativeNewCustomer: "<negative>{context}</negative> less customers than the previous month",
    neutralNewCustomer: "<neutral>The same number</neutral> of customers as previous month",
    newJoinerMessage: "Data is usually available after one performance month.",
  },
  updatedAsOf: "Updated as of",
  search: "Search",
  filter: "Filter",
  pv: "PV",
  bv: "BV",
  vcsPv: "VCS PV",
  order: "Order",
  total: "Total:",
};

export default content;
