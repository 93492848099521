import { useState, useMemo } from "react";
import "./Table.scss";
import LeafPanel from "../../leaf/LeafPanel/LeafPanel";
import { LeafIconButton } from "../../leaf/LeafIconButton/LeafIconButton";
import { FILTER_TYPE, IconFilter } from "../../icons/IconFilter/IconFilter";
import LeafBadgeCount from "../../leaf/LeafBadgeCount/LeafBadgeCount";

interface SearchItem {
  searchKey: string;
  displayValue: JSX.Element | string;
}

interface Column {
  label: string;
  accessor: string;
}

export interface CommonTableData {
  [key: string]: SearchItem;
}
type FilterTag = {
  filterKey: string;
  displayValue: JSX.Element | string;
};

type FilterMenuData = {
  accessor: string;
  filterTags: FilterTag[];
};

interface Props {
  data: CommonTableData[];
  header: Column[];
  filterMenuData: FilterMenuData;
  enableFilter?: boolean;
  enableSearch?: boolean;
}

const addRemoveFilter = (activeFilters: string[], filter: string) => {
  return activeFilters.includes(filter) ? activeFilters.filter((f) => f !== filter) : [...activeFilters, filter];
};

const FilterMenu = ({
  show,
  onCloseClick,
  title,
  activeFilters,
  filterTags,
  setActiveFilters,
}: {
  show: boolean;
  onCloseClick: Function;
  title: string;
  activeFilters: string[];
  setActiveFilters: Function;
  filterTags: FilterTag[];
}) => {
  return (
    <LeafPanel id="" applyScroll={false} {...{ show, onCloseClick, title }}>
      <div className="filterMenu">
        {filterTags.map((filterTag) => (
          <button
            onClick={() => setActiveFilters(addRemoveFilter(activeFilters, filterTag.filterKey))}
            className={`filterMenu__button ${
              activeFilters.includes(filterTag.filterKey) ? "filterMenu__button--active" : ""
            }`}
            key={filterTag.filterKey}
          >
            {filterTag.displayValue}
          </button>
        ))}
      </div>
      <div className="filterMenu__footer">
        <button onClick={() => setActiveFilters([])} className="filterMenu__footer__button">
          Clear Filters
        </button>
        <button onClick={() => onCloseClick()} className="filterMenu__footer__button">
          Close
        </button>
      </div>
    </LeafPanel>
  );
};

const Table = ({ data, header, filterMenuData, enableFilter = false, enableSearch = false }: Props) => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);
  const [activeFilters, setActiveFilters] = useState<string[]>([]);

  const sortedAndFilteredData = useMemo(() => {
    let filteredData = [...data];

    if (searchQuery) {
      filteredData = filteredData.filter((row) =>
        header.some((column) => {
          const item = row[column.accessor];
          return item?.searchKey?.toString().toLowerCase().includes(searchQuery.toLowerCase());
        }),
      );
    }

    if (activeFilters.length > 0) {
      filteredData = filteredData.filter((row) => {
        const filterAccessor = row[filterMenuData.accessor];

        return filterAccessor && activeFilters.includes(filterAccessor.searchKey);
      });
      filteredData.sort((a, b) => {
        const aValue = a[filterMenuData.accessor]?.searchKey;
        const bValue = b[filterMenuData.accessor]?.searchKey;
        return aValue.localeCompare(bValue);
      });
    }

    return filteredData;
  }, [data, header, searchQuery, activeFilters, filterMenuData]);

  return (
    <div className="Table-container">
      <div className="Table__search-and-filter">
        {enableFilter && (
          <div className="Table__filter">
            <LeafIconButton onClick={() => setShowFilterMenu(true)} Icon={<IconFilter type={FILTER_TYPE.ENABLED} />} />
            <span>
              <LeafBadgeCount count={activeFilters.length} hideZero={true} />
            </span>
          </div>
        )}

        {enableSearch && (
          <input
            type="text"
            placeholder="Search..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="Table__searchInput"
          />
        )}
      </div>

      <div className="Table__container">
        <table className="Table">
          <thead className="Table__header">
            <tr>
              {header.map((column) => (
                <th key={column.accessor}>{column.label}</th>
              ))}
            </tr>
          </thead>
        </table>
        <div className="Table__bodyContainer">
          <table className="Table">
            <tbody>
              {sortedAndFilteredData.length === 0 ? (
                <tr>
                  <td className="Table__noResults" colSpan={header.length}>
                    No results found
                  </td>
                </tr>
              ) : (
                sortedAndFilteredData.map((row, index) => (
                  <tr className="Table__row" key={index}>
                    {header.map((column) => (
                      <td key={column.accessor}>{row[column.accessor]?.displayValue}</td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
      <FilterMenu
        show={showFilterMenu}
        onCloseClick={() => setShowFilterMenu(false)}
        title="Filter by Tag"
        activeFilters={activeFilters}
        setActiveFilters={setActiveFilters}
        filterTags={filterMenuData.filterTags}
      />
    </div>
  );
};

export default Table;
