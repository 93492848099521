export enum QUALIFICATION_STATUS {
  QUALIFIED = "QUALIFIED",
  NOT_QUALIFIED = "NOT_QUALIFIED",
  TRACKING = "TRACKING",
  NOT_TRACKING = "NOT_TRACKING",
  HELD = "HELD",
  DENIED = "DENIED",
  OPEN = "OPEN",
  UN_AVAILABLE = "UN_AVAILABLE",
  MANUALLY_APPROVED = "MANUALLY_APPROVED",
  MANUALLY_QUALIFIED = "MANUAL_QUALIFIED", // custom status value not from Magic API
  AUTOMATICALLY_APPROVED = "AUTOMATICALLY_APPROVED",
  ELIGIBLE = "ELIGIBLE",
  NOT_ELIGIBLE = "NOT_ELIGIBLE", // custom status value not from Magic API
  GRADUATED = "GRADUATED",
  PENDING_ELIGIBLE = "PENDING_ELIGIBLE",
  COMING_SOON = "COMING_SOON",
  REWORK_DISQUALIFIED = "REWORK DISQUALIFIED",
  MISSED_WINDOW = "MISSED_WINDOW",
  NO_LONGER_POSSIBLE = "NO_LONGER_POSSIBLE",
}
