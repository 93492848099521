/**
 * Returns true if the `test` object has the same key values as the `filter`
 * object. Used to filter an array of requirements from the service payload.
 * Example: ```
 *     import {BF_LEGS} from 'constants';
 *     import {isReq} from 'common';
 *     let trg = requirements.filter(e => isReq(e, BF_LEGS))[0].target;
 * ```
 */
export function isReq(
  test: { requirementType: string; requirement: string },
  filter: { requirementType: string; requirement: string },
): boolean {
  return (
    (test.requirementType && test.requirementType.toUpperCase()) ===
      (filter.requirementType && filter.requirementType.toUpperCase()) &&
    (test.requirement && test.requirement.toUpperCase()) === (filter.requirement && filter.requirement.toUpperCase())
  );
}

/**
 * This function takes two string arguments, converts them to uppercase and compares
 * @param {string} first
 * @param {string} second
 * @return {boolean}
 */
export function capEq(first: string, second: string): boolean {
  if (typeof first === "string" && typeof second === "string") {
    return first.toUpperCase() === second.toUpperCase();
  }
  return first === second;
}

export function isEmptyObject(o: Record<string, unknown>): boolean {
  return o instanceof Object && o.constructor === Object && Object.keys(o).length === 0;
}

/**
 * This is a temporary workaround to handle the circular dependency on data and
 * mixins; this will all be removed once the data methods are removed from
 * mixins
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dateRefs = {
  today: new Date(),
  bonusFormat: new Intl.DateTimeFormat("en-us", {
    year: "numeric",
    month: "2-digit",
  }),
};

/**
 * Convert a performance year to a new Date()
 * @param {string} performanceYear
 * @returns {Date}
 */
export function performanceYearToDate(performanceYear: string): Date {
  const year = performanceYear.substr(0, 4);
  const month = performanceYear.substr(4);
  const day = new Date().getDate();

  return new Date(`${year}/${month}/${day}`);
}

/**
 * Fast pass and object to a param string
 * @param {object} obj
 * @returns {string}
 */
export function serializeObject(obj: Record<string, unknown>): string {
  return Object.keys(obj)
    .map((o) => `${encodeURIComponent(o)}=${encodeURIComponent(obj[o] as string)}`)
    .sort()
    .join("&");
}

/**
 * Returns `numPeriods` number of consecutive periods, starting with `period`
 * @param {string} period
 * @param {number} numPeriods
 * @returns {string[]}
 */
export function getNextPeriods(period: string, numPeriods: number): string[] {
  const periods = [period];
  let currentPeriod = period;
  for (let i = 0; i < numPeriods; i++) {
    const nextPeriod = getNextPeriod(currentPeriod);
    periods.push(nextPeriod);
    currentPeriod = nextPeriod;
  }
  return periods;
}

/**
 * Returns the next period
 * @param {string} period
 */
export function getNextPeriod(period: string): string {
  let year = parseInt(period.slice(0, 4));
  let month = parseInt(period.slice(-2));

  month = month + 1;
  if (month > 12) {
    month = 1;
    year = year + 1;
  }

  const paddedMonth = month < 10 ? `0${month}` : month.toString();
  return `${year}${paddedMonth}`;
}
