import GarBusinessLeg, { GarBusinessLegPayload } from "./garBusinessLeg";
import hatFaaHelper, { HatFaaHelperPayload } from "./hatFaaHelper";
export interface GarBusinessPayload extends HatFaaHelperPayload {
  abo: number;
  isoCountry: string;
  isd: boolean;
  primary: boolean;
  ebrPeriod: number;
  dbrPeriod: number;
  fpltPeriod: number;
  legs: Array<GarBusinessLegPayload>;
}
export default class GarBusiness extends hatFaaHelper {
  _legs: Array<GarBusinessLeg>;
  _p: GarBusinessPayload;

  constructor(payload: GarBusinessPayload) {
    super(payload);
    this._p = payload;
    this._legs = payload.legs.map((leg) => new GarBusinessLeg(leg));
  }

  /**
   * Business abo number
   * @return {number}
   */
  get abo(): number {
    return this._p.abo;
  }

  /**
   * ISO country code
   * @return {string}
   */
  get isoCountryCode(): string {
    return this._p.isoCountry;
  }

  /**
   * ISD
   */
  get isIsd(): boolean {
    // TODO question to brian, what is this? Can i put better name?
    return this._p.isd;
  }

  /**
   * True if this is the ABO's primary business.
   */
  get isPrimary(): boolean {
    return this._p.primary;
  }

  /**
   * EBR Period.
   * @return {number}
   */
  get ebrPeriod(): number {
    return this._p.ebrPeriod;
  }

  /**
   * DBR Period.
   * @return {number}
   */
  get dbrPeriod(): number {
    return this._p.dbrPeriod;
  }

  /**
   * FP Period.
   * @return {number}
   */
  get fpPeriod(): number {
    return this._p.fpltPeriod;
  }

  /**
   * Business legs
   * @return {array} - Array of GarBusinessLeg's
   */
  get legs(): Array<GarBusinessLeg> {
    return this._legs;
  }
}
