import FaaBusiness, { FaaBusinessPayload } from "./faaBusiness";
export interface FAABusinessDetailPayload {
  Businesses?: Array<FaaBusinessPayload>;
  Aff?: number;
  ABONo?: number;
  PerformanceYear?: number;
  ClosingPeriod?: number;
}

/**
 * A model of a FAA Business Detail
 *
 * @export
 * @class FAABusinessDetail
 */
export default class FAABusinessDetail {
  __payload: FAABusinessDetailPayload;
  _businesses: Array<FaaBusiness>;
  /**
   * Creates an instance of payload.
   * @param {object} payload
   * @memberof FAABusinessDetail
   */
  constructor(payload: FAABusinessDetailPayload) {
    this.__payload = payload || {};
    const businesses = this.__payload.Businesses || [];
    this._businesses = businesses.map((business) => new FaaBusiness(business));
  }

  /**
   * Affiliate number
   * @return {number}
   */
  get aff(): number | null {
    return this.__payload.Aff || null;
  }

  /**
   * ABO number
   * @return {number}
   */
  get abo(): number | null {
    return this.__payload.ABONo || null;
  }

  /**
   * Performance year
   * @return {number}
   */
  get performanceYear(): number | null {
    return this.__payload.PerformanceYear || null;
  }

  /**
   * Closing period
   * @return {number}
   */
  get closingPeriod(): number | null {
    return this.__payload.ClosingPeriod || null;
  }

  /**
   * Gets all the FAA schedules
   * @returns {object[]} an array of schedules
   * @memberof FAABusinessDetail
   */
  get businesses(): Array<FaaBusiness> {
    return this._businesses.filter((business) => business.legs.length > 0);
  }
}
