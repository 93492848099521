import "./LeafLinkButton.scss";
import React from "react";

type Props = {
  onClick: React.MouseEventHandler<HTMLButtonElement | HTMLAnchorElement>;
  disabled?: boolean;
  label: string;
  as: "link" | "button";
  children: any;
  fontSize?: "small" | "normal" | "medium" | "big" | "huge" | "large";
  fontWeight?: "thin" | "regular" | "medium" | "semibold" | "bold";
  theme?: "white" | "black" | "green" | "blue" | "purple";
  show?: boolean;
  underlined?: boolean;
};

const LeafLinkButton = ({
  onClick,
  disabled = false,
  label,
  children,
  as = "link",
  fontWeight,
  fontSize,
  theme = as === "link" ? "black" : "white",
  show = true,
  underlined = true,
}: Props) => {
  const commonProps = {
    onClick,
    disabled,
    "aria-label": label,
    "aria-disabled": disabled,
    role: as,
  };

  const NormalButton = () => {
    if (as === "button") {
      return (
        <button
          className={`leaf-link-button leaf-link-button__button leaf-link-button__button__theme-${theme}${
            disabled === true ? "--disabled" : "--enabled"
          } ${fontSize ? `leaf-link-button--${fontSize} ` : ""}${
            fontWeight ? `leaf-link-button leaf-link-button--${fontWeight}` : ""
          }`}
          {...commonProps}
        >
          {children}
        </button>
      );
    }
    return <></>;
  };

  const LinkButton = () => {
    if (as === "link") {
      return (
        <div
          className={`leaf-link-button leaf-link-button__link leaf-link-button__link__theme-${theme}${
            disabled === true ? "--disabled" : "--enabled"
          } leaf-link-button__link__underline${underlined ? "--enabled" : "disabled"}`}
        >
          <a
            className={`${fontSize ? `leaf-link-button leaf-link-button--${fontSize} ` : ""}${
              fontWeight ? `leaf-link-button leaf-link-button--${fontWeight}` : ""
            }`}
            {...commonProps}
          >
            {children}
          </a>
        </div>
      );
    }
    return <></>;
  };

  if (show === true) {
    return (
      <>
        <NormalButton />
        <LinkButton />
      </>
    );
  }

  return <></>;
};

export default LeafLinkButton;
