const content: { [key: string]: any } = {
  aboProfile: {
    title: "ABO Profile",
    sponsorshipTab: {
      sponsorshipTitle: "Sponsorship",
      sponsor: "Sponsor",
      platinumSponsor: "Platinum",
      fosterSponsor: "Foster",
      platinumAndSponsor: "Sponsor & Platinum",
      platinumFosterSponsor: "Foster & Platinum",
      internationalSponsor: "International Sponsor",
    },
    overviewTab: {
      pinInformation: "Pin Information",
      currentPin: "Current Pin",
      highestPin: "Highest Pin",
      trackingPin: "Tracking Pin",
      additionalRegistrationInformation: "Additional Registration Information",
      primaryAddress: "Primary Address",
      sponsorBlock: "Sponsor Block",
      orderBlock: "Order Block",
      autoRenewal: "Auto Renewal",
      contractSigned: "Contract Signed",
      true: "True",
      false: "False",
      yes: "Yes",
      no: "No",
    },
    datesTab: {
      birthday: "Birthday",
      noBirthdayDate: "No birthday date",
      recentOrderDate: "Recent Order Date",
      noRecentOrderDate: "No recent orders",
      recentReturnDate: "Recent Return Date",
      noRecentReturnDate: "No recent returns",
      recentSponsoringDate: "Recent Sponsoring Date",
      noRecentSponsoringDate: "No recent sponsoring date",
      renewalDate: "Renewal/Expiration Date",
      noRenewalDate: "No renewal date",
      dateJoined: "Date Joined",
      businessEntry: "Business Entry",
      noJoinedDate: "No date joined",
    },
    aboApplicants: {
      title: "Applicants",
      viewAllLink: "View all {NUMBER} applicants",
      viewAll: "View All",
      gender: "Gender",
      F: "Female (she/her)",
      M: "Male (he/him)",
      N: "Non-binary (they/them)",
      aboType: {
        1: "ABO",
        2: "Provisional ABO",
        3: "Member",
        4: "Client",
        5: "Market Advisor",
        6: "Amway Of",
        7: "Employee/Honorary",
        8: "BSc [India]",
        9: "GPKh (Non Group Leader) [Russia/KZ]",
        0: "Probational Auth Agent",
        10: "Service Site Point Operator",
        12: "Sales Representative",
        15: "Placeholder",
        17: "Guest Customer",
        171: "Sharebar Customer",
      },
    },
    aboProfilePerfHistory: {
      title: "Business KPIs",
      ppv: "Personal PV",
      gpv: "Group PV",
      rubyPV: "Ruby PV",
      leadershipPV: "Leadership PV",
      vcsPV: "VCS PV",
      vcsPercent: "VCS %",
      qcPV: "Qualified Customer PV",
      personalOrders: "Personal Orders",
      groupOrders: "Group Orders",
      newABOs: "New ABOs",
      totalABOs: "Group Size",
      newCustomers: "New Customers",
      customerOrders: "Customer Orders",
      totalCustomers: "Total Customers",
      performanceYear: "PY",
      noData: "There is no data available for the selected performance year.",
      kpiSelector: "KPI Selector",
      pySelector: "PY Selector",
      performanceBonus: "Performance Bonus",
    },
    tabViews: {
      insights: "Insights",
      overview: "Overview",
      dates: "Key Dates",
      sponsorship: "Sponsorship",
    },
  },
  customerProfile: {
    title: "Customer Profile",
    customerRegistered: "Registered",
    allApplicant: "All Applicants",
    overviewTab: {
      overview: "Overview",
      customer: "Customer",
      member: "Member",
      idleCustomer: "Idle",
      mostRecentOrder: "Most Recent Order",
      viewAll: "View All",
      description:
        "hasn’t made a purchase in {DAY_COUNT} days. Reach out to see how you can help get the ball rolling.",
    },
    profileTab: {
      profile: "Profile",
      additionalInformation: "Additional Information",
    },
    orderHistoryTab: {
      orderHistory: "Order History",
      search: "Search",
    },
    note: {
      pageTitle: "Note",
      editButton: "Edit",
      saveButton: "Save",
      addButton: "Add Note",
      readMore: "Read More",
      viewLess: "View Less",
    },
    tags: {
      new: "New",
      verified: "Verified",
      nonVerified: "Non-Verified",
      customer: "Customer",
      member: "Member",
      moreTags: "+{MORE_TAGS_COUNT} tags",
      primary: "Primary",
      newLastMonth:
        "Customers or Members who have joined in the previous month are included in the filter for the current period.",
      migratedTooltip:
        "Migrated status refers to users in your business who have recently changed their business nature to Member or Customer.",
      migrated: "Migrated",
      guestCustomer: "Guest Customer",
      shareBar: "Sharebar Customer",
    },
  },
  leaf: {
    confidentialUser: "Confidential User",
    emailAddress: "Email address",
    primaryAddress: "Primary address",
    copyAddress: "Copy Address",
    phoneNumber: "Mobile number",
    sms: "Send SMS message",
    phoneCall: "Make a Phone Call",
    copyPhone: "Copy Phone Number",
    copyPhoneTooltipText: "The selected item has been copied.",
    preferredLanguage: "Preferred Language",
    noPreferredLanguage: "No preferred language",
  },
};

export default content;
