import GarBusiness, { GarBusinessPayload } from "./garBusiness";
export interface GarBusinessDetailPayload {
  aff: number;
  abo: number;
  perfYear: number;
  closingPeriod: number;
  businesses: Array<GarBusinessPayload>;
}

export default class GarBusinessDetail {
  _p: GarBusinessDetailPayload;
  _businesses: Array<GarBusiness>;
  constructor(payload: GarBusinessDetailPayload) {
    this._p = payload;
    this._businesses = payload.businesses.map((business) => new GarBusiness(business));
  }

  /**
   * Affiliate number
   * @return {number}
   */
  get aff(): number {
    return this._p.aff;
  }

  /**
   * ABO number
   * @return {number}
   */
  get abo(): number {
    return this._p.abo;
  }

  /**
   * Performance year
   * @return {number}
   */
  get performanceYear(): number {
    return this._p.perfYear;
  }

  /**
   * Closing period
   * @return {number}
   */
  get closingPeriod(): number {
    return this._p.closingPeriod;
  }

  /**
   * GAR businesses with at least 1 leg.
   * @return {array} - Array of GarBusiness's
   */
  get businesses(): Array<GarBusiness> {
    return this._businesses.filter((business) => business.legs.length > 0);
  }
}
