import { useState } from "react";
import IconLoadingCircle from "../IconLoadingCircle/IconLoadingCircle";

type Props = {
  show?: boolean;
  loading?: boolean;
};

export const IconPdf = (props: Props) => {
  const { show = true, loading = false } = props;
  const [hovered, setHovered] = useState(false);

  if (show === false) {
    return <></>;
  }

  if (loading === true) {
    return <IconLoadingCircle />;
  }

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <circle cx="18" cy="18" r="18" fill={hovered ? "#000" : "#2C2C2C"} />
      <path
        d="M10.5 26.625H11.75V28.5H10.5C9.09375 28.5 8 27.4062 8 26V11C8 9.63281 9.09375 8.5 10.5 8.5H16.9453C17.6094 8.5 18.2344 8.77344 18.7031 9.24219L22.2578 12.7969C22.7266 13.2656 23 13.8906 23 14.5547V19.75H21.125V14.75H18C17.2969 14.75 16.75 14.2031 16.75 13.5V10.375H10.5C10.1484 10.375 9.875 10.6875 9.875 11V26C9.875 26.3516 10.1484 26.625 10.5 26.625ZM14.875 22.25H16.125C17.2969 22.25 18.3125 23.2656 18.3125 24.4375C18.3125 25.6484 17.2969 26.625 16.125 26.625H15.5V27.875C15.5 28.2266 15.1875 28.5 14.875 28.5C14.5234 28.5 14.25 28.2266 14.25 27.875V26V22.875C14.25 22.5625 14.5234 22.25 14.875 22.25ZM16.125 25.375C16.6328 25.375 17.0625 24.9844 17.0625 24.4375C17.0625 23.9297 16.6328 23.5 16.125 23.5H15.5V25.375H16.125ZM19.875 22.25H21.125C22.1406 22.25 23 23.1094 23 24.125V26.625C23 27.6797 22.1406 28.5 21.125 28.5H19.875C19.5234 28.5 19.25 28.2266 19.25 27.875V22.875C19.25 22.5625 19.5234 22.25 19.875 22.25ZM21.125 27.25C21.4375 27.25 21.75 26.9766 21.75 26.625V24.125C21.75 23.8125 21.4375 23.5 21.125 23.5H20.5V27.25H21.125ZM24.25 22.875C24.25 22.5625 24.5234 22.25 24.875 22.25H26.75C27.0625 22.25 27.375 22.5625 27.375 22.875C27.375 23.2266 27.0625 23.5 26.75 23.5H25.5V24.75H26.75C27.0625 24.75 27.375 25.0625 27.375 25.375C27.375 25.7266 27.0625 26 26.75 26H25.5V27.875C25.5 28.2266 25.1875 28.5 24.875 28.5C24.5234 28.5 24.25 28.2266 24.25 27.875V25.375V22.875Z"
        fill="white"
      />
    </svg>
  );
};
