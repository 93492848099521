/**
 *
 * @typedef downlineInfoTaxonomy
 * @property {string} totalABOCount
 * @property {string} totalNewABOCount
 * @property {string} totalCustomerCount
 * @property {string} personalOrdersCount
 * @property {string} groupOrdersCount
 * @property {string} qualifiedLegs
 * @readonly
 * @enum string
 */
export const DOWNLINEINFO = Object.freeze({
  TOTAL_ABO_COUNT: "totalABOCount",
  TOTAL_NEW_ABO_COUNT: "totalNewABOCount",
  TOTAL_CUSTOMER_COUNT: "totalCustomerCount",
  PERSONAL_ORDERS_COUNT: "personalOrdersCount",
  GROUP_ORDERS_COUNT: "groupOrdersCount",
  QUALIFIED_LEGS: "qualifiedLegs",
});
