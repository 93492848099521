import { useTranslation } from "react-i18next";
import { BonusBreakdownTile } from "../../../../common/components/BonusBreakdownTile/BonusBreakdownTile";
import defaultBonusBreakContent from "../../../../content/bonusBreakdown";
import defaultCommonContent from "../../../../content/common";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { useAppSelector } from "../../../../store";
import {
  BonusBreakdownLineData,
  BonusBreakdownSimpleLineData,
} from "../../../../common/interfaces/monthlyPerformanceDash";
import { getRoutePathForAwardCode } from "../../../../common/util/Navigation/navigationUtil";
import { BonusAmount } from "../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";
import { FetchBonusBreakdownData } from "./AnnualBonusBreakdownDataLayer";
import { annualBonusBreakdownMap, annualBonusBreakdownMapItem } from "./AnnualBonusBreakdownMap";

export const AnnualBonusBreakdown = () => {
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const { t } = useTranslation(["bonusBreakdown", "common"]);
  const { menuConfig } = useAppSelector((state) => state.boot.shellConfig);

  const renderBonusBreakdown = () => {
    const {
      bonusBreakdownData,
      isLoading,
      errorStatus,
      refetch: refetchBousBreakdownData,
    } = FetchBonusBreakdownData(selectedPeriod);

    if (isLoading) {
      return (
        <div className="bbt-loader-container">
          <LeafRectangleLoader isLoading={isLoading} numRows={1} width="100%" height="25rem" margin={"0 0 0.5rem 0"} />
          <div className="bbt-loader-container__bottom-line">
            <LeafRectangleLoader
              isLoading={isLoading}
              numRows={1}
              width="12rem"
              height="1.5rem"
              margin={"0 0 0.5rem 0"}
            />
            <LeafRectangleLoader
              isLoading={isLoading}
              numRows={1}
              width="7rem"
              height="1.5rem"
              margin={"0 0 0.5rem 0"}
            />
          </div>
        </div>
      );
    }

    if (errorStatus) {
      return (
        <ErrorTile
          clickFunction={refetchBousBreakdownData}
          errorStatus={errorStatus}
          isLoading={isLoading}
          tealiumTrackId="Bonus_Breakdown"
        />
      );
    }

    const responseData = bonusBreakdownData.BonusAmount;
    const order = annualBonusBreakdownMap.map(({ awardCode }) => awardCode);

    const expandContentKey = (defaultContent: Record<string, any>, contentKey: string) => {
      let content = defaultContent;
      for (const key of contentKey.split(".")) {
        content = content[key];
      }
      return content;
    };

    const getIncentiveTitle = (award: annualBonusBreakdownMapItem) => {
      const defaultContent = award.ns === "bonusBreakdown" ? defaultBonusBreakContent : defaultCommonContent;
      const title = award ? award.name : "";

      const defaultText = title.includes(".") ? expandContentKey(defaultContent, title) : defaultContent[title];

      return `${t(title, defaultText, { ns: award?.ns })}`;
    };

    const buildSubGroup = (subgroup: BonusAmount[]) => {
      return subgroup.map((award) => {
        const mapItem = annualBonusBreakdownMap.find((element) => element.awardCode === award.awardCode);
        return {
          incentiveTitle: mapItem ? getIncentiveTitle(mapItem) : "",
          amount: award.bonusAmount,
        } as BonusBreakdownSimpleLineData;
      });
    };

    const renderData = responseData
      .map((item) => {
        const mapItem = annualBonusBreakdownMap.find((element) => element.awardCode === item.awardCode);

        const result: BonusBreakdownLineData = {
          awardCode: item.awardCode,
          incentiveTitle: mapItem ? getIncentiveTitle(mapItem) : "",
          tealiumTag: mapItem ? defaultBonusBreakContent[mapItem.name].replace("+", "") : "",
          amount: item.bonusAmount,
          link: getRoutePathForAwardCode(menuConfig, item.awardCode),
        };

        if (item.groupAwards) {
          result.group = buildSubGroup(item.groupAwards);
        }

        return result;
      })

      .sort((a, b) => order.indexOf(a.awardCode) - order.indexOf(b.awardCode));

    return (
      <BonusBreakdownTile
        title={t("annualBonusBreakdown", defaultBonusBreakContent["annualBonusBreakdown"])}
        description={t(
          "annualBonusBreakdownDescription",
          defaultBonusBreakContent["annualBonusBreakdownDescription"],
        ).toString()}
        subTitle={t("annualBonus", defaultBonusBreakContent["annualBonus"])}
        bonusSubTotalTitle={t("annualBonusEstimatedTotal", defaultBonusBreakContent["annualBonusEstimatedTotal"])}
        bonusSubTotalValue={bonusBreakdownData.BonusAmountSubTotal}
        bonusBreakdown={renderData}
      />
    );
  };

  return <LeafTile>{renderBonusBreakdown()}</LeafTile>;
};
