import "./LeafTabViewHeader.scss";

type TabViewHeaderItem = {
  id?: string;
  title?: string;
  activeView: string;
  disabled?: boolean;
};

type Props = {
  tabItem: TabViewHeaderItem;
  tabHeaderOnClick: Function;
};

const LeafTabViewHeader = (props: Props) => {
  const { tabItem, tabHeaderOnClick } = props;
  const { id, title, activeView, disabled } = tabItem;

  const onClickTabHeader = () => {
    if (!disabled) {
      tabHeaderOnClick(id);
    }
  };

  const onKeyPressTabHeader = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (!disabled) {
        tabHeaderOnClick(id);
      }
    }
  };

  return (
    <li
      role="tab"
      id={id}
      aria-selected={id === activeView ? "true" : "false"}
      tabIndex={disabled ? -1 : 0}
      aria-controls={`tabpanel-${id}`}
      onClick={onClickTabHeader}
      onKeyDown={onKeyPressTabHeader}
      className={`leaf-tab-view-header-item leaf-tab-view-header-item__nav-item${
        id === activeView ? "--active leaf-tab-view-header-item__nav-item" : ""
      }${disabled ? "--disabled leaf-tab-view-header-item__nav-item" : ""}`}
    >
      {title}
    </li>
  );
};

export default LeafTabViewHeader;
