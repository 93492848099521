import hatFaaHelper, { HatFaaHelperPayload } from "./hatFaaHelper";

export interface FaaBusinessLegPayload extends HatFaaHelperPayload {
  ABO: number;
  Country: string;
  FoundersPlatinumCount: number;
  FoundersPlatinumPoints: number;
  EBRCount: number;
  EBRPoints: number;
  DBRCount: number;
  DBRPoints: number;
  Points: number;
  PaymentPoints: number;
  TotalPoints: number;
  SuperLeg: boolean;
}

export default class FaaBusinessLeg extends hatFaaHelper {
  _p: FaaBusinessLegPayload;
  constructor(payload: FaaBusinessLegPayload) {
    super(payload);
    this._p = payload;
  }

  /**
   * Leg abo number
   * @return {number}
   */
  get abo(): number {
    return this._p.ABO;
  }

  /**
   * ISO country code
   * @return {string}
   */
  get isoCountryCode(): string {
    return this._p.Country;
  }

  /**
   * FP count.
   * @return {number}
   */
  get fpCount(): number {
    return this._p.FoundersPlatinumCount;
  }

  /**
   * FP points.
   * @return {number}
   */
  get fpPoints(): number {
    return this._p.FoundersPlatinumPoints;
  }

  /**
   * EBR count.
   * @return {number}
   */
  get ebrCount(): number {
    return this._p.EBRCount;
  }

  /**
   * EBR points.
   * @return {number}
   */
  get ebrPoints(): number {
    return this._p.EBRPoints;
  }

  /**
   * DBR count.
   * @return {number}
   */
  get dbrCount(): number {
    return this._p.DBRCount;
  }

  /**
   * DBR points.
   * @return {number}
   */
  get dbrPoints(): number {
    return this._p.DBRPoints;
  }

  /**
   * Points earning.
   * @return {number}
   */
  get points(): number {
    return this._p.Points;
  }

  /**
   * Total payment points.
   * @return {number}
   */
  get paymentPoints(): number {
    return this._p.PaymentPoints;
  }

  /**
   * Total points earning.
   * @return {number}
   */
  get totalPoints(): number {
    return this._p.TotalPoints;
  }

  /**
   * Has the abo reached super leg?
   * @return {number}
   */
  get isSuperLeg(): boolean {
    return this._p.SuperLeg;
  }
}
