import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NavDropdownItem } from "../../../common/interfaces/routing";
import { LeafNavigationDropdown } from "../../../common/leaf/LeafNavigationDropdown/LeafNavigationDropdown";
import { SmartNextSteps } from "../../SmartNextSteps/SmartNextSteps";
import "./HamburgerMenu.scss";
import { DROPDOWN_KEYS, ROUTE_LABELS, ROUTE_PATHS } from "../../../common/enums/routing-enums";
import TEALIUM from "../../../common/enums/tealium-enums";
import defaultContent from "../../../content/navigation";
import {
  getLearnMoreLink,
  displayFeedbackLink,
  showLogoutLink,
  getDisplayAcademyLink,
  getAcademyLink,
} from "../../../common/util/common";
import LeafButton, { Variant } from "../../../common/leaf/LeafButton/LeafButton";
import { BusinessSelector } from "../../BusinessSelector/BusinessSelector";
import { useAppSelector } from "../../../store";
import { getCountryFlag } from "../../../common/util/getCountryFlag";
import { track } from "../../../common/util/tealium";

export const HamburgerMenu = () => {
  const { t } = useTranslation(["navigation"]);
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const { user, configuration, isBootLoading, shellConfig } = useAppSelector((state) => state.boot);
  const aboName = useAppSelector(({ aboName }) => aboName.name);
  const { abo: aboNum, isoCountryCode } = user;
  const { showBusinessSelector = false } = configuration?.navigation || {};
  const { menuConfig = [] } = shellConfig;

  const displayFeebackLink = displayFeedbackLink(menuConfig);
  const displayLogoutLink = showLogoutLink();
  const learnMoreLink = getLearnMoreLink(menuConfig);
  const displayAcademyLink = getDisplayAcademyLink(menuConfig);
  const academyLink = getAcademyLink(menuConfig);

  const [openedWindow, setOpenedWindow] = useState<Window | null>(null);

  const handleTealiumTrackClickActionProvideFeedback = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "",
      TEALIUM.CATEGORY.CORE_PLUS_PORTAL,
      "",
      "provide_feedback",
    );
  };

  const handleTealiumTrackClickActionLearnMore = () => {
    track(TEALIUM.U_TAG_METHOD.LINK, "", TEALIUM.CATEGORY.USER_MANUAL_LINK, "", "learn_more");
  };

  const handleTealiumTrackClickActionAcademy = () => {
    track(TEALIUM.U_TAG_METHOD.LINK, TEALIUM.EVENT_NAME.CLICK_ACTION, "", TEALIUM.CATEGORY.ACADEMY, "", "academy");
  };

  const getTranslatedLinks = (key: DROPDOWN_KEYS): NavDropdownItem[] => {
    let links: NavDropdownItem[] = [];
    menuConfig.forEach((item) => {
      if (item.key === key) {
        links = item.items
          // HideMenu: Hide the menu but user can still access the page via direct link/bookmark
          .filter(({ hideMenu }) => !hideMenu)
          .map((link) => ({
            ...link,
            label: `${t(`linkTitle.${link.label}`, defaultContent.linkTitle[link.label])}`,
            key: link.label,
          }));
      }
    });
    return links;
  };

  const getTranslatedTitle = (key: DROPDOWN_KEYS): string => {
    return `${t(`${key}`, defaultContent[`${key}`])}`;
  };

  const createLeafDropdown = (key: DROPDOWN_KEYS) => {
    const title = getTranslatedTitle(key);
    const links = getTranslatedLinks(key);
    if (links.length > 0) {
      return <LeafNavigationDropdown title={title} links={links} clickFunction={closeHamburgerModal} />;
    }
    return "";
  };

  const toggleBackgroundComponent = () => {
    setExpanded((currentValue) => !currentValue);
  };

  const closeHamburgerModal = () => {
    setExpanded(false);
    const mobileModalEl = document.getElementById("hamburger-menu__hamburger-checkbox") as HTMLInputElement;
    mobileModalEl.checked = false;
  };

  useEffect(() => {
    window.addEventListener("resize", closeHamburgerModal);
    return () => window.removeEventListener("resize", closeHamburgerModal);
  }, []);

  useEffect(() => {
    const componentSection = document.getElementById("App__component-section");
    if (componentSection) {
      componentSection.style.cssText = expanded ? "overflow: hidden;" : "";
    }
  }, [expanded]);

  const renderBusinessSelector = () => {
    return showBusinessSelector ? (
      <BusinessSelector viewState="mobile" callbackOnBusinessChanged={closeHamburgerModal} />
    ) : (
      <span id="busSelector">
        <h2>{aboName}</h2>
        <span className={"busSelector__subheading--no-pointer"}>
          {getCountryFlag(isoCountryCode)}
          <span>{isoCountryCode}</span>
          <span>#{aboNum}</span>
        </span>
      </span>
    );
  };

  const openOrRedirect = (url: string, event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    if (openedWindow && !openedWindow.closed) {
      openedWindow.focus();
    } else {
      const newWindow = window.open(url, "academyFrame");
      setOpenedWindow(newWindow);
    }
    handleTealiumTrackClickActionAcademy();
  };

  return (
    <div className="hamburger-menu" id="hamburger-menu">
      <div className="hamburger-menu__hamburger">
        <input
          aria-label={`${t("toggleMenu", defaultContent["toggleMenu"])}`}
          id="hamburger-menu__hamburger-checkbox"
          className="hamburger-menu__hamburger-checkbox"
          type="checkbox"
          autoComplete="off"
          onClick={toggleBackgroundComponent}
        ></input>
        <div className="hamburger-menu__hamburger-icon">
          <div className="hamburger-menu__hamburger-icon-top"></div>
          <div className="hamburger-menu__hamburger-icon-middle"></div>
          <div className="hamburger-menu__hamburger-icon-bottom"></div>
        </div>
        <nav className="hamburger-menu__nav-container">
          <div className="hamburger-menu__section hamburger-menu__header">{renderBusinessSelector()}</div>
          <div className="hamburger-menu__section">
            <div className="hamburger-menu__smart-next-steps">
              <h2>{`${t("frequentlyUsed", defaultContent["frequentlyUsed"])}`}</h2>
              {isBootLoading ? (
                <p className="loading-pulse" style={{ height: "11.25rem" }}></p>
              ) : (
                <SmartNextSteps menuConfig={menuConfig} clickFunction={closeHamburgerModal} />
              )}
            </div>
          </div>
          <div className="hamburger-menu__section">
            <div className="hamburger-menu__section-container">
              <div className="hamburger-menu__performance-incentives">
                <h2>{`${t("performanceAndIncentives", defaultContent["performanceAndIncentives"])}`}</h2>
                {isBootLoading ? (
                  <p className="loading-pulse" style={{ height: "12.75rem" }}></p>
                ) : (
                  <div className="hamburger-menu__dropdown">
                    {createLeafDropdown(DROPDOWN_KEYS.PERFORMANCE)}
                    {createLeafDropdown(DROPDOWN_KEYS.EARLY_INCENTIVES)}
                    {createLeafDropdown(DROPDOWN_KEYS.MY_GROUP)}
                    {createLeafDropdown(DROPDOWN_KEYS.LEADER_GROWTH)}
                  </div>
                )}
              </div>
            </div>
          </div>
          {displayAcademyLink && (
            <div className="hamburger-menu__section">
              <div className="hamburger-menu__section-container">
                <div className="hamburger-menu__academy" onClick={() => closeHamburgerModal()}>
                  <a
                    href={academyLink}
                    rel="noopener noreferrer"
                    target="academyFrame"
                    onClick={(e) => openOrRedirect(academyLink, e)}
                  >
                    <h2>{`${t("academy", defaultContent["academy"])}`}</h2>
                  </a>
                </div>
              </div>
            </div>
          )}
          {(displayFeebackLink || learnMoreLink) && (
            <div className="hamburger-menu__section">
              <div className="hamburger-menu__section-container">
                <div className="hamburger-menu__performance-incentives">
                  <h2>{`${t("resources", defaultContent["resources"])}`}</h2>
                  <span
                    className={`hamburger-menu__link ${displayFeebackLink ? "" : "hidden"}`}
                    onClick={() => closeHamburgerModal()}
                  >
                    <div onClick={handleTealiumTrackClickActionProvideFeedback}>
                      <a href={ROUTE_PATHS.FEEDBACK} rel="noopener noreferrer">
                        {`${t(`linkTitle.${ROUTE_LABELS.FEEDBACK}`, defaultContent.linkTitle[ROUTE_LABELS.FEEDBACK])}`}
                      </a>
                    </div>
                  </span>
                  <span
                    className="hamburger-menu__link"
                    onClick={() => {
                      closeHamburgerModal();
                      handleTealiumTrackClickActionLearnMore();
                    }}
                  >
                    <a
                      hidden={!learnMoreLink}
                      href={learnMoreLink}
                      id={ROUTE_LABELS.LEARN_ABOUT_CPP}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {`${t(
                        `linkTitle.${ROUTE_LABELS.LEARN_ABOUT_CPP}`,
                        defaultContent.linkTitle[ROUTE_LABELS.LEARN_ABOUT_CPP],
                      )}`}
                    </a>
                  </span>
                </div>
              </div>
            </div>
          )}
          {displayLogoutLink && (
            <div
              className="hamburger-menu__section__footer"
              onClick={() => handleTealiumTrackClickActionProvideFeedback()}
            >
              <span
                className={`hamburger-menu__link ${displayLogoutLink ? "" : "hidden"}`}
                onClick={() => closeHamburgerModal()}
              >
                <LeafButton
                  variant={Variant.SOLID}
                  clickAction={() => navigate(ROUTE_PATHS.LOG_OUT)}
                  id={"mobile-view-log-out"}
                >{`${t(
                  `linkTitle.${ROUTE_LABELS.LOG_OUT}`,
                  defaultContent.linkTitle[ROUTE_LABELS.LOG_OUT],
                )}`}</LeafButton>
              </span>
            </div>
          )}
        </nav>
      </div>
    </div>
  );
};
