import { BootUser } from "../../reducers/boot";
import { ERROR_ROUTE_SUBPATHS, getFullRoutePath } from "../../routing/ErrorRoutes";
import { ROUTE_PATHS } from "../enums/routing-enums";

export function verifyLogin(user: BootUser) {
  const { pathname } = window.location;
  const sessionExpiredDuration =
    user && user.cacheDateInMS && user.expires_in
      ? Date.now() - Number(user.cacheDateInMS) - Number(user.expires_in) * 1000 > 0 || false
      : true;
  const accessToken = user && user.access_token ? user.access_token.replace(/"/g, "") : null;
  const errorPath = getFullRoutePath(ERROR_ROUTE_SUBPATHS.USER_SESSION_EXPIRED);

  if ((!accessToken || sessionExpiredDuration) && pathname !== ROUTE_PATHS.LOG_OUT) {
    window.location.replace(window.location.origin + errorPath);
  }
}
