import { capEq } from "../utils/utils";
import { DENIED, ACHIEVED } from "../utils/constants";

export interface TtciIncentivePayload {
  bonusCurrency: string;
  timeStamp: number;
  status: string;
  awardLevels: [{ bonus: number }];
}

/**
 * A model of TtciIncentive
 *
 * @export
 * @class TtciIncentive
 */
export default class TtciIncentive {
  __payload: TtciIncentivePayload;
  /**
   * Creates an instance of payload.
   * @param {object} payload
   * @memberof TtciIncentive
   */
  constructor(payload: TtciIncentivePayload) {
    this.__payload = payload;
  }

  /**
   * Gets the total bonus by combaing bonus of all award levels
   * @returns {Number}
   * @memberof TtciIncentive
   */
  get totalBonus(): number {
    return this.__payload.awardLevels.reduce((a, b) => a + b.bonus, 0);
  }

  /**
   * Gets the status of the abo if earning or not for specific award level
   * @returns {Boolean}
   * @memberof TtciIncentive
   */
  isEarning(): boolean {
    return capEq(this.__payload.status, ACHIEVED);
  }

  /**
   * Gets the status of abo id tracking or not for specific award level
   * @returns {Boolean}
   * @memberof TtciIncentive
   */
  isTracking(): boolean {
    return this.__payload.awardLevels.length > 0;
  }

  /**
   * Gets the status of the abo if denied or not for specific award level
   * @returns {Boolean}
   * @memberof TtciIncentive
   */
  isDenied(): boolean {
    return capEq(this.__payload.status, DENIED);
  }

  /**
   * Gets the bonus currency code of the abo/market
   * @returns {String}
   * @memberof TtciIncentive
   */
  get currencyCode(): string {
    return this.__payload.bonusCurrency;
  }

  /**
   * Gets the array of award levels
   * @returns {Array}
   * @memberof TtciIncentive
   */
  get awardLevels(): Array<unknown> {
    return this.__payload.awardLevels;
  }

  /**
   * Gets the timestamp
   * @returns {Date}
   * @memberof TtciIncentive
   */
  get timestamp(): Date {
    return new Date(this.__payload.timeStamp);
  }
}
