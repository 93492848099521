import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "../../../../../store";
import { PERSONA } from "../../../../../common/enums/persona";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import defaultContent from "../../../../../content/annualPerformanceDashboard";
import { PIN_LEVEL_CODE } from "../../../../../common/enums/pin-level-codes";
import LeafPinLevelName from "../../../../../common/leaf/LeafPinLevelName/LeafPinLevelName";
import "./PinTrackingTile.scss";
import { FoundersPlatinumProgress } from "../../FoundersPlatinumProgress/FoundersPlatinumProgress";
import { AWARD_PROGRESS } from "../../../../../common/enums/award-progress-codes";
import TEALIUM from "../../../../../common/enums/tealium-enums";
import { track } from "../../../../../common/util/tealium";
import { AwardProgressBadge } from "../../../../../common/components/AwardProgressBadge/AwardProgressBadge";
import { addParams } from "../../../../../common/util/addParams";
import { getSelectedPerformanceYearData } from "../../../../../common/util/period";
import { FoundersPlatinumTrackingResponse } from "../../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";

type Props = {
  qualifiedLegsPinLevelCode: PIN_LEVEL_CODE;
  globalPinLevelCode: PIN_LEVEL_CODE;
  currentPinLevelCode: PIN_LEVEL_CODE;
  highestPinLevelCode: PIN_LEVEL_CODE;
  fpTrackingData: FoundersPlatinumTrackingResponse;
  isLoading: boolean;
  currentInMarketPin: PIN_LEVEL_CODE;
};

export const PinTrackingTile = ({
  qualifiedLegsPinLevelCode,
  globalPinLevelCode,
  currentPinLevelCode,
  highestPinLevelCode,
  fpTrackingData,
  currentInMarketPin,
  isLoading,
}: Props) => {
  const { t } = useTranslation(["annualPerformanceDashboard"]);
  const { shellConfig } = useAppSelector((state) => state.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const [showFppModal, setShowFppModal] = useState(false);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const foundersProgressModalParam = queryParams.get("foundersProgressModal") === "true";
  const { selectedPerformanceYear: previousPerformanceYear } = getSelectedPerformanceYearData(selectedPeriod, 1);
  const previousPY = previousPerformanceYear.slice(2, 4);

  const fppLabel = t("foundersPlatinumStatus", defaultContent["foundersPlatinumStatus"]).toString();

  useEffect(() => {
    if (foundersProgressModalParam && !showFppModal) {
      fppOnCloseClick();
    }
  }, [foundersProgressModalParam, showFppModal]);

  const fppOnCloseClick = () => {
    setShowFppModal(false);
    addParams("foundersProgressModal", "false", false, true);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "FPP_modal",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "cancel_icon",
    );
  };

  const fppOnOpenClick = () => {
    setShowFppModal(true);
    addParams("foundersProgressModal", "true", true, false);
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "launchFPP_modal",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "foundersPlatinumStatus",
    );
  };

  const getFoundersPlatinumStatus = () => {
    let foundersPlatinumStatus = AWARD_PROGRESS.NOT_TRACKING;

    if (fpTrackingData.IsQualified) {
      foundersPlatinumStatus = AWARD_PROGRESS.ACHIEVED;
    } else if (fpTrackingData.IsTracking) {
      foundersPlatinumStatus = AWARD_PROGRESS.TRACKING;
    }

    return foundersPlatinumStatus;
  };

  return (
    <LeafTile isLoading={isLoading}>
      <div className="TrackingTile__content-container">
        <div className="TrackingTile__content-row">
          <p>{t("pinTrackingQualifiedLegsAward", defaultContent["pinTrackingQualifiedLegsAward"])}</p>
          <span className="TrackingTile__content-row--bold">
            <LeafPinLevelName pinLevelCode={qualifiedLegsPinLevelCode} />
          </span>
        </div>
        <div className="TrackingTile__mobileDivider">
          <LeafTileDivider />
        </div>
        {shellConfig.persona >= PERSONA.EMERALD ? (
          <div className="TrackingTile__content-row">
            <p>{t("pinTrackingGlobalAwardRecognition", defaultContent["pinTrackingGlobalAwardRecognition"])}</p>
            <span className="TrackingTile__content-row--bold">
              <LeafPinLevelName pinLevelCode={globalPinLevelCode} />
            </span>
          </div>
        ) : (
          <></>
        )}
        <LeafTileDivider />
        <div className="TrackingTile__content-row">
          <p>
            {t("currentInMarketPin", defaultContent["currentInMarketPin"], {
              shortPY: previousPY,
            })}
          </p>
          <span className="TrackingTile__content-row--bold">
            <LeafPinLevelName pinLevelCode={currentInMarketPin} />
          </span>
        </div>
        <div className="TrackingTile__mobileDivider">
          <LeafTileDivider />
        </div>
        {currentPinLevelCode >= PIN_LEVEL_CODE.GAR_EXECUTIVE_DIAMOND ? (
          <>
            <div className="TrackingTile__content-row">
              <p>
                {t("awardLevelPY", defaultContent["awardLevelPY"], {
                  shortPY: previousPY,
                })}
              </p>
              <span className="TrackingTile__content-row--bold">
                <LeafPinLevelName pinLevelCode={currentPinLevelCode} />
              </span>
            </div>
            <div className="TrackingTile__mobileDivider">
              <LeafTileDivider />
            </div>
          </>
        ) : (
          <></>
        )}
        <div className="TrackingTile__content-row">
          <p>{t("highestAwardLevel", defaultContent["highestAwardLevel"])}</p>
          <span className="TrackingTile__content-row--bold">
            <LeafPinLevelName pinLevelCode={highestPinLevelCode} />
          </span>
        </div>
        <div className="TrackingTile__mobileDivider">
          <LeafTileDivider />
        </div>
        <div className="TrackingTile__content-row">
          <p>
            <button
              aria-label={fppLabel}
              className="TrackingTile__link"
              onClick={fppOnOpenClick}
              id="foundersPlatinumProgressButton"
            >
              {t("pinTrackingFpStatus", defaultContent["pinTrackingFpStatus"])}
            </button>
            <FoundersPlatinumProgress
              show={showFppModal}
              onCloseClick={fppOnCloseClick}
              status={getFoundersPlatinumStatus()}
            />
          </p>
          <span>
            <AwardProgressBadge
              status={getFoundersPlatinumStatus()}
              id={`foundersPlatBadge_${getFoundersPlatinumStatus()}`}
            />
          </span>
        </div>
      </div>
    </LeafTile>
  );
};
