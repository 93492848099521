import { useState } from "react";

export const IconDelete = () => {
  const [hovered, setHovered] = useState(false);

  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <circle cx="18" cy="18" r="18" fill={hovered ? "#A9142A" : "#D91734"} />
      <path
        d="M16.1641 10.375C16.0469 10.375 15.9688 10.4531 15.8906 10.5312L15.1484 11.625H20.8125L20.0703 10.5312C20.0312 10.4531 19.9141 10.375 19.7969 10.375H16.1641ZM23.0781 11.625H23.5859H25.5H25.8125C26.3203 11.625 26.75 12.0547 26.75 12.5625C26.75 13.1094 26.3203 13.5 25.8125 13.5H25.3438L24.4062 26.1953C24.2891 27.5234 23.2344 28.5 21.9062 28.5H14.0547C12.7266 28.5 11.6719 27.5234 11.5547 26.1953L10.6172 13.5H10.1875C9.64062 13.5 9.25 13.1094 9.25 12.5625C9.25 12.0547 9.64062 11.625 10.1875 11.625H10.5H12.375H12.8828L14.3281 9.47656C14.7188 8.89062 15.4219 8.5 16.1641 8.5H19.7969C20.5391 8.5 21.2422 8.89062 21.6328 9.47656L23.0781 11.625ZM23.4688 13.5H12.4922L13.4297 26.0781C13.4688 26.3906 13.7422 26.625 14.0547 26.625H21.9062C22.2188 26.625 22.4922 26.3906 22.5312 26.0781L23.4688 13.5Z"
        fill="white"
      />
    </svg>
  );
};
