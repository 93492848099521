import GarGenerator, { GarGeneratorPayload } from "./garGenerator";

export interface GeneratorType {
  generatorCode?: number;
  generators?: Array<GarGeneratorPayload>;
}

export interface GarGeneratorDetailPayload {
  aff?: number;
  abo?: number;
  perfYear?: number;
  closingPeriod?: number;
  generatorTypes?: GeneratorType[];
}

const DBR_GENERATOR_CODE = 1661;
const EBR_GENERATOR_CODE = 1662;
const FPLEG_GENERATOR_CODE = 1663;
const FDBR_GENERATOR_CODE = 1664;

export default class GarGeneratorDetail {
  _payload: GarGeneratorDetailPayload;
  _dbrGenerators: GeneratorType;
  _ebrGenerators: GeneratorType;
  _fpLegGenerators: GeneratorType;
  _fdbrGenerators: GeneratorType;
  constructor(payload: GarGeneratorDetailPayload) {
    this._payload = payload || {};
    const generatorTypes = this._payload.generatorTypes || [];
    this._dbrGenerators = generatorTypes.find((type) => type.generatorCode === DBR_GENERATOR_CODE) || {};
    this._ebrGenerators = generatorTypes.find((type) => type.generatorCode === EBR_GENERATOR_CODE) || {};
    this._fpLegGenerators = generatorTypes.find((type) => type.generatorCode === FPLEG_GENERATOR_CODE) || {};
    this._fdbrGenerators = generatorTypes.find((type) => type.generatorCode === FDBR_GENERATOR_CODE) || {};
  }

  /**
   * Affiliate number
   * @return {number}
   */
  get aff(): number | null {
    return this._payload.aff || null;
  }

  /**
   * ABO number
   * @return {number}
   */
  get abo(): number | null {
    return this._payload.abo || null;
  }

  /**
   * Performance year
   * @return {number}
   */
  get performanceYear(): number | null {
    return this._payload.perfYear || null;
  }

  /**
   * Closing period
   * @return {number}
   */
  get closingPeriod(): number | null {
    return this._payload.closingPeriod || null;
  }

  /**
   * GAR DBR Generator.
   * @return {array} - Array of GarGenerator's
   */
  get dbrGenerators(): Array<GarGenerator> {
    const _dbrGenerators = this._dbrGenerators.generators || [];
    return _dbrGenerators.map((generator) => new GarGenerator(generator));
  }

  /**
   * GAR EBR Generator.
   * @return {array} - Array of GarGenerator's
   */
  get ebrGenerators(): Array<GarGenerator> {
    const _ebrGenerators = this._ebrGenerators.generators || [];
    return _ebrGenerators.map((generator) => new GarGenerator(generator));
  }

  /**
   * GAR Founders Platinum Leg Generator.
   * @return {array} - Array of GarGenerator's
   */
  get fpLegGenerators(): Array<GarGenerator> {
    const _fpLegGenerators = this._fpLegGenerators.generators || [];
    return _fpLegGenerators.map((generator) => new GarGenerator(generator));
  }

  /**
   * GAR Founders DBR Generator.
   * @return {array} - Array of GarGenerator's
   */
  get fdbrGenerators(): Array<GarGenerator> {
    const _fdbrGenerators = this._fdbrGenerators.generators || [];
    return _fdbrGenerators.map((generator) => new GarGenerator(generator));
  }
}
