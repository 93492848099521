import Abo, { AboConstructorFields } from "./abo";
import CurrencyAmount from "./currencyAmount";

export class Goal<T> {
  current: T;
  target: T;
  achieved: boolean;
  constructor({ current, target, achieved }: { current: T; target: T; achieved: boolean }) {
    this.current = current;
    this.target = target;
    this.achieved = achieved;
  }
}
export class GoalsKpis {
  vcsSales: Goal<CurrencyAmount>;
  newBuyingRegisteredCustomers: Goal<number>;
  trainingCourses: Goal<number>;
  constructor({
    vcsSales,
    newBuyingRegisteredCustomers,
    trainingCourses,
  }: {
    vcsSales: Goal<CurrencyAmount>;
    newBuyingRegisteredCustomers: Goal<number>;
    trainingCourses: Goal<number>;
  }) {
    this.vcsSales = vcsSales;
    this.newBuyingRegisteredCustomers = newBuyingRegisteredCustomers;
    this.trainingCourses = trainingCourses;
  }
}

/**
 * Model for results from the goals action report services.
 *
 * @export
 * @class ArGoalsResults
 */
export default class ArGoalsResult extends Abo {
  goalsKpis: GoalsKpis;
  blockedDueToEntryModel: boolean;

  constructor({
    aboConstructorFields,
    goalsKpis,
    blockedDueToEntryModel,
  }: {
    aboConstructorFields: AboConstructorFields;
    goalsKpis: GoalsKpis;
    blockedDueToEntryModel: boolean;
  }) {
    super(aboConstructorFields);
    this.goalsKpis = goalsKpis;
    this.blockedDueToEntryModel = blockedDueToEntryModel;
  }
}
