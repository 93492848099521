import { useState } from "react";
import { useTranslation } from "react-i18next";
import { LeafBannerToast } from "../../common/leaf/LeafBannerToast/LeafBannerToast";
import { LeafModal } from "../../common/leaf/LeafModal/LeafModal";
import defaultContent from "../../content/browser";
import "./GrayListBanner.scss";

export const GrayListBanner = ({ show }: { show: boolean }) => {
  const [showBanner, setShowBanner] = useState(true);
  const [showReadMoreModal, setShowReadMoreModal] = useState(false);
  const [hasSeenGrayListWarning, setHasSeenGrayListWarning] = useState(
    sessionStorage.getItem("hasSeenGraylistWarning") || false,
  );
  const { t } = useTranslation(["browser"]);

  const hideBanner = () => {
    setShowBanner(false);
    setHasSeenGrayListWarning(true);
    sessionStorage.setItem("hasSeenGraylistWarning", "true");
  };

  const handleModalOnClose = () => {
    setShowReadMoreModal(false);
  };

  const handleModalOpen = () => {
    setShowReadMoreModal(true);
  };

  return (
    <>
      {show && (
        <LeafBannerToast theme="yellow" closeFunction={hideBanner} show={showBanner && !hasSeenGrayListWarning}>
          <section className="grayListBanner">
            <span className="grayListBanner__title">
              {t("browserNotSupported", defaultContent["browserNotSupported"])}
            </span>
            <p className="grayListBanner__message">{t("browserBanner", defaultContent["browserBanner"])}</p>
            <span className="grayListBanner__readMoreLink" onClick={handleModalOpen} role="link">
              {t("readMoreLink", defaultContent["readMoreLink"])}
            </span>
          </section>
          <LeafModal
            title={`${t("bannerModal", defaultContent["bannerModal"])}`}
            open={showReadMoreModal}
            onCloseCallbackFn={handleModalOnClose}
            id={"grayListBanner"}
          >
            <ul className="grayListBanner__browserList">
              <li>{t("chrome", defaultContent["chrome"])}</li>
              <li>{t("safari", defaultContent["safari"])}</li>
              <li>{t("firefox", defaultContent["firefox"])}</li>
              <li>{t("edge", defaultContent["edge"])}</li>
            </ul>
          </LeafModal>
        </LeafBannerToast>
      )}
    </>
  );
};
