import { Route, Navigate } from "react-router-dom";
import { lazy } from "react";
import Customers from "../../components/Customers/Customers";
const Overview = lazy(() => import("../../components/Customers/Overview/Overview"));
const CustomerList = lazy(() => import("../../components/Customers/CustomerList/CustomerList"));
const OrderHistory = lazy(() => import("../../components/Customers/OrderHistory/OrderHistory"));

export enum CUSTOMER_ROUTES {
  BASE_PATH = "/customer/",
  OVERVIEW = `overview`,
  LIST = `list`,
  ORDER = `order`,
}

export const CUSTOMER_OVERVIEW_ROUTE = `${CUSTOMER_ROUTES.BASE_PATH}${CUSTOMER_ROUTES.OVERVIEW}`;
export const CUSTOMER_ROUTE_PATHS = [CUSTOMER_ROUTES.OVERVIEW, CUSTOMER_ROUTES.LIST, CUSTOMER_ROUTES.ORDER].map(
  (route) => `${CUSTOMER_ROUTES.BASE_PATH}${route}`,
);

export const CustomersRoutes = () => {
  return (
    <Route path={CUSTOMER_ROUTES.BASE_PATH} element={<Customers />}>
      <Route path={CUSTOMER_ROUTES.OVERVIEW} element={<Overview />} />
      <Route path={CUSTOMER_ROUTES.LIST} element={<CustomerList />} />
      <Route path={CUSTOMER_ROUTES.ORDER} element={<OrderHistory />} />
      <Route path={CUSTOMER_ROUTES.BASE_PATH} element={<Navigate to={CUSTOMER_ROUTES.OVERVIEW} />} />
    </Route>
  );
};
