import { createSlice } from "@reduxjs/toolkit";
import storageSession from "redux-persist/lib/storage/session";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";

const initialSavedKpiPreferences = {
  savedKpiPreferencesData: {},
};

const { actions, reducer } = createSlice({
  name: "savedKpiPreferencesData",
  initialState: initialSavedKpiPreferences,
  reducers: {
    setSavedKpiPreferencesData: (state, action) => {
      const { payload } = action;
      try {
        return {
          ...state,
          savedKpiPreferencesData: JSON.parse(payload),
        };
      } catch (error) {
        return {
          ...state,
          savedKpiPreferencesData: payload,
        };
      }
    },
  },
});

export const { setSavedKpiPreferencesData } = actions;

const persistConfig = {
  key: "savedKpiPreferencesData",
  storage: storageSession,
  stateReconciler: autoMergeLevel2,
};

export default persistReducer<any>(persistConfig, reducer);
