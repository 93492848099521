import ABOProfileBGT from "./ABOProfileBGT/ABOProfileBGT";
import ABOProfilePerfHistory from "./ABOProfilePerfHistory/ABOProfilePerfHistory";

const InsightsTab = () => {
  return (
    <div tabIndex={0}>
      <ABOProfileBGT />
      <ABOProfilePerfHistory />
    </div>
  );
};

export default InsightsTab;
