import "./LeafErrorMessage.scss";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";
import defaultContent from "../../../content/leafErrorMessage";
import { IconGeneralError } from "../../icons/IconGeneralError/IconGeneralError";

type Props = {
  show: boolean;
  icon?: ReactNode;
  headline?: string;
  content?: string;
  textOnly?: boolean;
  fontSize?: "small" | "normal" | "medium" | "big" | "huge" | "large";
  fontWeight?: "thin" | "regular" | "medium" | "semibold" | "bold";
  gap?: boolean;
  noHeadline?: boolean;
  noContent?: boolean;
};

export const LeafErrorMessage = (props: Props) => {
  const {
    show,
    headline,
    content,
    textOnly = false,
    noHeadline = false,
    noContent = false,
    icon,
    fontSize = "xl",
    fontWeight = "semibold",
    gap = false,
  } = props;
  const { t } = useTranslation(["leafErrorMessage"]);

  const renderHeadline = () => {
    if (noHeadline) {
      return <></>;
    }

    return (
      <p
        className={`leaf-error-message__error-headline leaf-error-message--${fontSize} leaf-error-message--${fontWeight}`}
      >
        {headline || `${t("headline", defaultContent["headline"])}`}
      </p>
    );
  };

  const renderContent = () => {
    if (noContent) {
      return <></>;
    }

    if (noHeadline) {
      return (
        <div className="leaf-error-message__error-content--noHeadline">
          {content || `${t("content", defaultContent["content"])}`}
        </div>
      );
    }

    return (
      <div className="leaf-error-message__error-content">{content || `${t("content", defaultContent["content"])}`}</div>
    );
  };

  return (
    <>
      {show && (
        <div className="leaf-error-message" data-testid="leafErrorMessage">
          {textOnly === false && (
            <div className={`leaf-error-message__error-icon${gap ? " gap" : ""}`} data-testid="leafErrorMessageIcon">
              {icon ? icon : <IconGeneralError />}
            </div>
          )}
          {renderHeadline()}
          {renderContent()}
        </div>
      )}
    </>
  );
};
