const content: { [key: string]: any } = {
  performance: "Performance",
  performanceAndIncentives: "Performance & Incentives",
  earlyIncentives: "Early Incentives",
  myGroup: "My Group",
  leaderGrowth: "Leader Growth",
  resources: "Resources",
  frequentlyUsed: "Frequently Used",
  toggleMenu: "Toggle Menu",
  academy: "Academy",
  linkTitle: {
    academyV2: "Academy",
    monthlyPerformance: "Monthly Performance",
    annualPerformance: "Annual Performance",
    performanceBonus: "Performance Bonus Detail",
    earningsCalculator: "Earnings Calculator",
    csi: "Customer Sales Incentive+",
    bronzeFoundation: "Bronze Foundation+",
    bronzeBuilder: "Bronze Builder+",
    cml: "Customer/Member List",
    customerOverview: "Customer Overview",
    los: "Line of Sponsorship (LOS)",
    edlos: "Emerald & Diamond LOS",
    actionReports: "Action Reports",
    watchlist: "Watchlist",
    performancePlus: "Performance Plus+",
    performanceElite: "Performance Elite+",
    performancePlusElite: "Performance Plus & Elite+",
    personalGrowthMonthly: "Monthly Personal Group Growth+",
    personalGrowthAnnual: "Annual Personal Group Growth+",
    personalGroupGrowth: "Personal Group Growth+",
    frontlineGrowth: "Frontline Group Growth+",
    twoTimeCashIncentive: "Two Time Cash Incentive+",
    feedback: "Provide Us Feedback",
    learnAboutCpp: "Learn About Core Plus+",
    logOut: "Log Out",
    hat: "HAT/GAR",
    faa: "FAA",
    leadershipSeminar: "Leadership Seminar",
    personalGroupGrowthGCR: "Personal Group Growth GCR",
    newAboActivation: "Path to Bronze+",
    bronzeGroupTracking: "Bronze Group Tracking",
    mbi: "My Business Income",
    academy: "Academy",
    performanceDashboard: "Performance Dashboard",
    strongStartIncentive: "Strong Start Incentive+",
    bronzeIncentives: "Bronze Incentives",
    qcg: "QC Growth Incentive+",
    visionBonus: "Vision Bonus+",
  },
};
export default content;
