/**
 * Configuration setter enum for Grid options
 * @property {string} UI_MENU_SHOW_PROFILE
 * @property {string} UI_MENU_SHOW_PERFHISTORY
 * @property {string} UI_MENU_SHOW_WATCHLIST
 * @property {string} DATA_SHOW_INACTIVEABOS
 * @property {string} DATA_SHOW_KPIS
 * @property {string} DATA_SHOW_ZEROVOLUMEABOS
 * @readonly
 * @enum string
 */
export const LOS_GRID_CONFIG = Object.freeze({
  UI_MENU_SHOW_PROFILE: "uiMenuShowProfile",
  UI_MENU_SHOW_PERFHISTORY: "uiMenuShowPerfHistory",
  UI_MENU_SHOW_ISOLATE: "uiMenuShowIsolate",
  UI_MENU_SHOW_WATCHLIST: "uiMenuShowWatchlist",
  UI_MENU_SHOW_EXPANDGROUP: "uiMenuShowExpandGroup",
  STRING_AWARD_PLATINUM_OR_ABOVE: "stringAwardPlatinumOrAbove",
  STRING_AWARD_ASSOCIATE_BELOW_SILVER: "stringAwardAssociateBelowSilver",
  STRING_BADGE_ABO: "stringBadgeAbo",
  DATA_SHOW_INACTIVEABOS: "dataShowInactiveAbos",
  DATA_SHOW_KPIS: "dataShowKpis",
  DATA_SHOW_ZEROVOLUMEABOS: "dataShowZeroVolumeAbos",
  DATA_SHOW_CUSTOMER_MEMBERS: "dataShowCustomerMembers",
  DATA_SHOW_INTERNATIONAL_ABOS: "dataShowInternationalAbos",
});

export const LOS_DATA_FILTER = Object.freeze({
  DATA_HIDE_KPIS: "dataHideKpis",
});

export const LOS_GRID_EVENT = Object.freeze({
  DATA_FILTER: "acc-los-data-filter",
  DATA_FILTER_CLEARED: "acc-los-data-filter-cleared",
  DATA_FIND: "acc-los-data-find",
  ACTION_SEND_ABO: "acc-wc-view-los-indented-action",
  ACTION_EXTERNAL_LINK: "acc-wc-view-los-indented-external-link",
});

export const LOS_SAVED_VIEW = Object.freeze({
  CUSTOMIZED_FIELDS: "accWcLosCustomizedFields",
});

export const SPONSOR_LABEL = Object.freeze({
  IS_FOSTER_SPONSOR: "isFosterSponsor",
  IS_INTERNATIONAL_SPONSOR: "isInternational",
});

export const TEALIUM_ACTION_LINKS = {
  downlineLos: { method: "link", category: "rwLOS", action: "downlineLOS" },
  profile: { method: "link", category: "rwLOS", action: "profile" },
  performanceHistory: {
    method: "link",
    category: "rwLOS",
    action: "performance",
  },
  "external.anaVolumeDetails": { method: "link", category: "rwLOS", action: "viewVolumeDetails" },
};
