import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "../Lit/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { storedLocale } from "../impersonation/util";
import { mapAttributes } from "../../common/util/config";
import { useAppDispatch, useAppSelector } from "../../store";
import { showProfile } from "../../reducers/profile";
import ABOProfile from "../ABOProfile/ABOProfile";
import defaultContent from "../../content/edlos";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import { ErrorTile } from "../../common/components/ErrorTile/ErrorTile";
import "./EdLos.scss";
import LeafRectangleLoader from "../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";

const loadComponent = async () => {
  return import("../Lit/acc-wc-los-ed/acc-wc-los-ed.js");
};

export default function EdLos() {
  const { t } = useTranslation(["edlos"]);
  const { configuration } = useAppSelector((state) => state.boot);
  const dispatch = useAppDispatch();
  const marketConfig = configuration?.edLos?.marketConfiguration;
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const [requestFailed, setRequestFailed] = useState(false);
  const [errorReloading, setErrorReloading] = useState(false);

  useEffect(() => {
    if (!isPending) {
      const edlos = document.getElementById("acc_wc_los_ed_component") as HTMLElement;
      mapAttributes(edlos, configuration.edLos, {});

      const handleLitError = (event: CustomEvent) => {
        setRequestFailed(true);
      };

      edlos.addEventListener("acc-wc-los-ed-error", handleLitError as EventListener);

      return () => {
        edlos.removeEventListener("acc-wc-los-ed-error", handleLitError as EventListener);
      };
    }
  }, [isPending, configuration]);

  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();
  const [showOnlyPerformanceHistory, setShowOnlyPerformanceHistory] = useState(false);

  //controlling the new abo profile event on main edlos
  const showABOProfileEd = useCallback(
    (event: CustomEventInit) => {
      event.detail.select === "historyGroup"
        ? setShowOnlyPerformanceHistory(true)
        : setShowOnlyPerformanceHistory(false);
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    },
    [dispatch],
  );
  //controlling the new abo profile event for downline los
  const showABOProfileDownline = useCallback(
    (event: CustomEventInit) => {
      // market config == 1 is full los view and market config == 2 is restricted view, only appicable on downline los
      switch (marketConfig) {
        case 1:
          setShowOnlyPerformanceHistory(false);
          dispatch(showProfile(event.detail.abo.affAbo));
          setModalOpenedByElement(event.detail.target);
          break;
        case 2:
          setShowOnlyPerformanceHistory(true);
          dispatch(showProfile(event.detail.abo.affAbo));
          setModalOpenedByElement(event.detail.target);
          break;
        default:
          break;
      }
    },
    [dispatch, marketConfig],
  );

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfileDownline);
    window.addEventListener("acc-wc-los-ed-indented-action-profile", showABOProfileEd);

    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfileDownline);
      window.removeEventListener("acc-wc-los-ed-indented-action-profile", showABOProfileEd);
    };
  }, [showABOProfileDownline, showABOProfileEd]);

  if (isPending) {
    return <div className="EdLos__wrapper"></div>;
  }

  const reloadEdLos = () => {
    setErrorReloading(true);
    window.location.reload();
  };

  const getErrorComponent = () => {
    return errorReloading ? (
      <div className="EdLos__custom-error EdLos__custom-error--loading">
        <LeafRectangleLoader isLoading={true} width={"100%"} height={"14rem"} />
      </div>
    ) : (
      <div className="EdLos EdLos__custom-error">
        <ErrorTile
          clickFunction={reloadEdLos}
          errorStatus={true}
          isLoading={false}
          errorMessage={`${t("timeoutError", defaultContent["timeoutError"])}`}
          btnText={`${t("refresh", defaultContent["refresh"])}`}
          tealiumTrackId="ED_LOS"
        />
      </div>
    );
  };

  return (
    <main>
      <PageTitleSection title={`${t("pageTitle", defaultContent["pageTitle"])}`} hideTimeStamp />
      <div className="EdLos__wrapper">
        {requestFailed ? (
          getErrorComponent()
        ) : (
          <>
            <acc-wc-los-ed id="acc_wc_los_ed_component" mode="standalone" locale={storedLocale()}></acc-wc-los-ed>
            <ABOProfile
              showOnlyPerformanceHistory={showOnlyPerformanceHistory}
              previousElement={modalOpenedByElement}
            />
          </>
        )}
      </div>

      <BonusInfoFooter hideDate />
    </main>
  );
}
