import "./LeafRectangleLoader.scss";
import React, { ReactNode } from "react";

type Props = {
  isLoading: boolean;
  width: string;
  height: string;
  margin?: string;
  numRows?: number;
  numColumns?: number;
  View?: React.ElementType;
  children?: ReactNode;
};

const LeafRectangleLoader = (props: Props) => {
  const { isLoading, width, height, margin, numRows = 1, numColumns = 1, View, children } = props;

  const totalCells = numColumns * numRows;

  const LeafRectangleLoaderView = () => {
    const renderRectangleLoaders = [];
    for (let i = 1; i <= totalCells; i++) {
      renderRectangleLoaders.push(
        <div
          className="rectangle-loader"
          key={`rectangle-loader-${i}`}
          style={{ width, height, margin }}
          data-testid={`leafRectangleLoader-${i}`}
        ></div>,
      );
    }
    return (
      <div
        className="rectangle-loader__container"
        style={{
          width,
          gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
          gridTemplateRows: `repeat(${numRows}, auto)`,
        }}
      >
        {renderRectangleLoaders}
      </div>
    );
  };

  return <>{isLoading ? <LeafRectangleLoaderView /> : (View && <View />) || children}</>;
};

export default LeafRectangleLoader;
