import hatFaaHelper, { HatFaaHelperPayload } from "./hatFaaHelper";

export interface FAAIncomeBusinessPayload extends HatFaaHelperPayload {
  ABONo: number;
  ISOCountry: string;
  ISOCurrency: string;
  Primary: boolean;
  LocalIncomeTotal: number;
  LocalIncomeAverage: number;
  LocalISOCurrency: string;
  IncomeAverage: number;
}

/**
 * A model of a FAA Income Business
 *
 * @export
 * @class FAAIncomeBusiness
 */
export default class FAAIncomeBusiness extends hatFaaHelper {
  _p: FAAIncomeBusinessPayload;

  /**
   * Creates an instance of payload.
   * @param {object} payload
   * @memberof FAAIncomeBusiness
   */
  constructor(payload: FAAIncomeBusinessPayload) {
    super(payload);
    this._p = payload;
  }

  /**
   * Gets abo number.
   * @returns {Number}
   * @memberof FAAIncomeBusiness
   */
  get abo(): number {
    return this._p.ABONo;
  }

  /**
   * Gets iso country code.
   * @returns {Number}
   * @memberof FAAIncomeBusiness
   */
  get isoCountry(): string {
    return this._p.ISOCountry;
  }

  /**
   * Gets iso currency.
   * @returns {String}
   * @memberof FAAIncomeBusiness
   */
  get isoCurrency(): string {
    return this._p.ISOCurrency;
  }

  /**
   * Gets if primary or not.
   * @returns {Boolean}
   * @memberof FAAIncomeBusiness
   */
  get isPrimary(): boolean {
    return this._p.Primary;
  }

  /**
   * Gets Local Income Total.
   * @returns {Number}
   * @memberof FAAIncomeBusiness
   */
  get localIncomeTotal(): number {
    return this._p.LocalIncomeTotal;
  }

  /**
   * Gets Local Income Average.
   * @returns {Number}
   * @memberof FAAIncomeBusiness
   */
  get localIncomeAverage(): number {
    return this._p.LocalIncomeAverage;
  }

  /**
   * Gets Local ISO Currency.
   * @returns {String}
   * @memberof FAAIncomeBusiness
   */
  get localISOCurrency(): string {
    return this._p.LocalISOCurrency;
  }

  /**
   * Gets Income Average.
   * @returns {Number}
   * @memberof FAAIncomeBusiness
   */
  get incomeAverage(): number {
    return this._p.IncomeAverage;
  }
}
