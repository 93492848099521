import LeafRectangleLoader from "../../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import "./DownloadList.scss";

type Props = {
  numberOfLoaders: number;
  isFetching: boolean;
};

const DownloadList = ({ numberOfLoaders, isFetching }: Props) => {
  if (isFetching === false) {
    return <></>;
  }

  return (
    <>
      {Array.from({ length: numberOfLoaders }, (_, index) => (
        <div key={index} className="server-side-export-download-loader__export-detail">
          <LeafRectangleLoader isLoading={isFetching} width="90%" height={"1.2rem"} />
          <div className="server-side-export-download-loader__divider-line"></div>
          <LeafRectangleLoader isLoading={isFetching} width="50%" height={"1.2rem"} />
          <LeafRectangleLoader isLoading={isFetching} width="70%" height={"1.2rem"} />
          <LeafRectangleLoader isLoading={isFetching} width="70%" height={"1.2rem"} />
          <LeafRectangleLoader isLoading={isFetching} width="50%" height={"1.2rem"} />
        </div>
      ))}
    </>
  );
};

export default DownloadList;
