import { createSlice } from "@reduxjs/toolkit";

type CustomerExport = {
  tncAgreement: boolean;
  showTnC: boolean;
  isShowExport: boolean;
};

const initialState: CustomerExport = {
  tncAgreement: false,
  showTnC: false,
  isShowExport: false,
};

const { actions, reducer } = createSlice({
  name: "customerExport",
  initialState,
  reducers: {
    showExport: (state) => ({
      ...state,
      isShowExport: true,
    }),
    hideExport: (state) => ({
      ...state,
      isShowExport: false,
    }),
    toggleTnC: (state) => ({
      ...state,
      showTnC: !state.showTnC,
    }),
    acceptTnCAgreement: (state) => ({
      ...state,
      tncAgreement: true,
    }),
    declineTnCAgreement: (state) => ({
      ...state,
      tncAgreement: false,
    }),
  },
});

export const { showExport, hideExport, toggleTnC, acceptTnCAgreement, declineTnCAgreement } = actions;

export default reducer;
