import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import "./LeafGenderInfo.scss";

type Props = {
  gender: string;
  privacyFlag: boolean;
  show?: boolean;
};

const LeafGenderInfo = (props: Props) => {
  const { t } = useTranslation(["profile"]);

  const { gender, privacyFlag, show } = props;

  if (show === true) {
    return (
      <div className="leaf-gender">
        <div className="leaf-gender__container">
          <div className="leaf-gender__content-title">{`${t(
            "aboProfile.aboApplicants.gender",
            defaultContent["aboProfile"]["aboApplicants"]["gender"],
          )}`}</div>
          <div className="leaf-gender__content-data">
            {privacyFlag === true
              ? "-"
              : `${t(`aboProfile.aboApplicants.${gender}`, defaultContent["aboProfile"]["aboApplicants"][gender])}`}
          </div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafGenderInfo;
