import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2";
import storage from "redux-persist/lib/storage";
import { normalizeLocale, normalizeSsoOrigin, parseHash } from "../components/impersonation/impersonation-util";
import { ImpersonationDetails } from "../common/interfaces/impersonation";

export type LoginFields = {
  locale: string;
  token: string;
  ssoOrigin: string;
  entryPage: string;
  loginOrigin: string;
  impersonating: boolean;
  targetAbo: string;
  cognitoToken: string;
};

type PageViewCounts = {
  [key: string]: string;
};

type LoginLocalStorageType = {
  loginFields: LoginFields;
  deviceType: string;
  deviceSize: "desktop" | "mobile";
  clientId: string;
  pageViewCounts: PageViewCounts;
  accessibilityWarningsEnabled: boolean;
  impersonationHistory: ImpersonationDetails[];
  headless: boolean;
  footless: boolean;
};

const params = new URLSearchParams(window.location.search);
const hash = parseHash(window.location.hash);

const getInitialPageViews = () => {
  let initialPageViews = {};

  // copy over existing page view counts to redux
  if (
    localStorage.getItem("pageViewCounts") !== null &&
    Object.keys(JSON.parse(localStorage.getItem("pageViewCounts") as string) || {}).length > 0
  ) {
    initialPageViews = JSON.parse(localStorage.getItem("pageViewCounts") as string);

    // if things went well and copy was sucessful clean up old reference
    if (
      Object.keys(initialPageViews).length ===
      Object.keys(JSON.parse(localStorage.getItem("pageViewCounts") as string)).length
    ) {
      localStorage.removeItem("pageViewCounts");
    }
  }

  return initialPageViews;
};

const getImpersonationHistory = () => {
  let impersonationHistory = [];

  // copy over existing impersonation history to redux
  if (localStorage.getItem("history") !== null) {
    const previousStoredHistory = JSON.stringify(localStorage.getItem("history"));
    impersonationHistory = JSON.parse(JSON.parse(previousStoredHistory));

    // if things went well and copy was sucessful clean up old reference
    if (JSON.parse(JSON.parse(previousStoredHistory)).length === impersonationHistory.length) {
      localStorage.removeItem("history");
    }
  }
  return impersonationHistory;
};

export const initialState: LoginLocalStorageType = {
  loginFields: {
    locale: normalizeLocale(params.get("locale")) || normalizeLocale(hash.locale) || "",
    token: params.get("token") || "",
    ssoOrigin: normalizeSsoOrigin(params.get("ssoOrigin")) || normalizeSsoOrigin(hash.ssoOrigin) || "US",
    entryPage: params.get("entryPage") || hash.entryPage || "",
    targetAbo: params.get("targetAbo") || hash.targetAbo || "",
    loginOrigin: "",
    impersonating: true,
    cognitoToken: params.get("cognitoToken") || "",
  },
  deviceType: "desktop",
  deviceSize: "desktop",
  clientId: "",
  pageViewCounts: getInitialPageViews(),
  accessibilityWarningsEnabled: false,
  impersonationHistory: getImpersonationHistory(),
  headless: false,
  footless: false,
};

const { actions, reducer } = createSlice({
  name: "loginLocalStorage",
  initialState: initialState,
  reducers: {
    setloginLocalStorage: (state, action) => {
      return {
        ...state,
        loginFields: { ...state.loginFields, ...action.payload.loginFields },
      };
    },
    setLocalStorageItem: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setDeviceSize: (state, action) => {
      state.deviceSize = action.payload.deviceSize;
    },
  },
});

export const { setloginLocalStorage, setLocalStorageItem, setDeviceSize } = actions;

const loginLocalStoragePersistConfig = {
  key: "loginLocalStorage",
  // to avoid iframe session and non iframe session override with each other
  blacklist: ["headless", "footless"],
  storage: storage,
  stateReconciler: autoMergeLevel2,
};

export default persistReducer<LoginLocalStorageType>(loginLocalStoragePersistConfig, reducer);
