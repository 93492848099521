import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { showProfile } from "../profile";
import { OrderGroup, upsertOrderGroup, upsertOrderGroups } from "../model/order";
import { CustomerOrder, OrderResponse } from "../../services/Customer/CustomerOrderList/customerOrderListAPI.types";

type ProfileOrders = {
  Period: number;
  PageNum: number;
  PageSize: number;
  PageLimit: number;
  TotalOrderCount: number;
  allOrderCount: number;
  AffNo: string;
  AboNo: string;
  AffAbo: string;
  NextPageNum: number;
  NextPeriod: number;
  CustomerOrderList: CustomerOrder[];
  orderGroups: OrderGroup[];
  isFetching?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  orderSearchKeyword?: string;
  requestId?: string;
};

export const initialState: ProfileOrders = {
  Period: 0,
  PageNum: 0,
  PageSize: 50,
  PageLimit: 0,
  TotalOrderCount: 0,
  allOrderCount: 0,
  AffNo: "",
  AboNo: "",
  AffAbo: "",
  NextPageNum: 1,
  NextPeriod: 0,
  CustomerOrderList: [],
  orderGroups: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  orderSearchKeyword: "",
  requestId: "",
};

const { reducer, actions } = createSlice({
  name: "profileOrders",
  initialState,
  reducers: {
    updateProfileOrders: (state, { payload }: PayloadAction<{ requestId: string; data: OrderResponse }>) => {
      const { requestId, data } = payload;

      if (state.requestId === requestId) {
        const { Period, CustomerOrderList } = data;
        const { orderGroups, allOrderCount } = state;
        const orderGroup = upsertOrderGroup(data, orderGroups);
        const _orderGroups = upsertOrderGroups(Period, orderGroups, orderGroup);

        return {
          ...state,
          ...data,
          orderGroups: _orderGroups,
          allOrderCount: allOrderCount + CustomerOrderList?.length,
          isSuccess: true,
          isError: false,
          isFetching: false,
        };
      }

      return state;
    },
    updateIsFetching: (state, { payload }: PayloadAction<{ requestId: string; isFetching: boolean }>) => ({
      ...state,
      ...payload,
      isError: false,
      isSuccess: false,
    }),
    updateIsError: (state, { payload }: PayloadAction<{ requestId: string; isError: boolean }>) => {
      const { requestId, isError } = payload;

      if (state.requestId === requestId) {
        return {
          ...state,
          requestId,
          isError,
          isSuccess: isError === false,
          isFetching: false,
        };
      }
      return state;
    },
    updateSearchKeyword: (_state, { payload }: PayloadAction<string>) => ({
      ...initialState,
      orderSearchKeyword: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(showProfile, () => initialState);
  },
});

export const { updateProfileOrders, updateIsFetching, updateIsError, updateSearchKeyword } = actions;

export default reducer;
