import { useMemo } from "react";
import Table, { CommonTableData } from "../../../../common/components/Table/Table";
import { ReactComponent as IconExportDelete } from "../../../../common/icons/IconExportDelete/svg/icon_export_delete.svg";
import { IconMobilePhone } from "../../../../common/icons/IconMobilePhone/IconMobilePhone";
import { IconUser } from "../../../../common/icons/IconUser/IconUser";
import { ImpersonationDetails } from "../../../../common/interfaces/impersonation";
import { getCountryFlag, getCountryFlagByAff } from "../../../../common/util/getCountryFlag";
import { Markets } from "../../../Lit/acc-es-data/model";
import { affiliates } from "../../impersonation-util";
import "./ImpersonationTable.scss";
import useWindowSize from "../../../../common/hooks/useWindowSize";

type Actions = {
  impersonateWithSelectedABO: Function;
  impersonateWithAmywayPlus: Function;
  remove: Function;
};

export type ImpersonationTableProps = {
  impersonationHistory: ImpersonationDetails[];
  actions: Actions;
};

const createAction = (impersonation: ImpersonationDetails, actions: Actions) => {
  const impersonationButton = () => {
    if (impersonation.impersonationType === "amwayPlus") {
      return (
        <button
          type="button"
          className="ImpersonationTable__impersonateButton"
          onClick={() => actions.impersonateWithAmywayPlus(impersonation)}
        >
          <IconMobilePhone fill="white" />
        </button>
      );
    }
    return (
      <button
        type="button"
        className="ImpersonationTable__impersonateButton"
        onClick={() => actions.impersonateWithSelectedABO(impersonation)}
      >
        <IconUser iconStyle="filled" color="white" />
      </button>
    );
  };

  return (
    <div className="ImpersonationTable__rowItem ImpersonationTable__buttonGroup">
      <button type="button" className="ImpersonationTable__deleteButton" onClick={() => actions.remove(impersonation)}>
        <IconExportDelete color="white" />
      </button>

      {impersonationButton()}
    </div>
  );
};

const createCountry = (impersonation: ImpersonationDetails) => {
  const country = new Markets().getByCtry(impersonation.country);
  let isoCountryCode = "US";
  if (country) {
    isoCountryCode = country.iso;
  }

  return (
    <span className="ImpersonationTable__rowItem">
      <span>{getCountryFlag(isoCountryCode)}</span>
      <span>{impersonation.country}</span>
    </span>
  );
};

const createTableData = (impersonationHistory: ImpersonationDetails[], actions: Actions): CommonTableData[] => {
  const tableData: CommonTableData[] = [];

  impersonationHistory.forEach((impersonation) => {
    tableData.push({
      abo: {
        searchKey: impersonation.abo,
        displayValue: impersonation.abo,
      },
      country: {
        searchKey: impersonation.country,
        displayValue: createCountry(impersonation),
      },
      partyId: {
        searchKey: impersonation.partyId ?? "",
        displayValue: impersonation.partyId ?? "",
      },
      note: {
        searchKey: impersonation.note ?? "",
        displayValue: impersonation.note || "-",
      },
      actions: {
        searchKey: impersonation.abo,
        displayValue: createAction(impersonation, actions),
      },
    });
  });
  return tableData;
};

const getHeader = (isDesktop: boolean) => {
  const header = [
    { label: "ABO", accessor: "abo" },
    { label: "Country", accessor: "country" },
    { label: "Note", accessor: "note" },
    { label: "", accessor: "actions" },
  ];

  if (!isDesktop) {
    header.splice(2, 1);
  }

  return header;
};

export const ImpersonationTable = ({ impersonationHistory, actions }: ImpersonationTableProps) => {
  const { width } = useWindowSize();
  const isDesktop = !!(width && width > 600);

  const tableHeader = getHeader(isDesktop);
  const tableData = createTableData(impersonationHistory, actions);
  const countryCodes = useMemo(
    () => [...new Set(affiliates.map((aff) => aff.country))].sort((a, b) => a.localeCompare(b)),
    [],
  );

  const countryCodesFilterTags = useMemo(
    () =>
      countryCodes.map((country) => ({
        filterKey: country,
        displayValue: (
          <span className="ImpersonationTable__filterTagItem">
            <span>{getCountryFlagByAff(country)}</span>
            <span>{country}</span>
          </span>
        ),
      })),
    [countryCodes],
  );
  return (
    <Table
      header={tableHeader}
      data={tableData}
      enableFilter={true}
      enableSearch={true}
      filterMenuData={{ accessor: "country", filterTags: countryCodesFilterTags }}
    />
  );
};
