import { ReactNode, useRef } from "react";
import "./BottomDrawer.scss";
import { useLockBodyScrolling } from "../../../common/util/cssUtils";

type BottomDrawProps = {
  show: boolean;
  setShow: Function;
  children?: ReactNode;
};

export const BottomDrawer = ({ show, setShow, children }: BottomDrawProps) => {
  const drawerClasses = show ? "mobile-period-selector__drawer--open" : "mobile-period-selector__drawer";
  const modalRef = useRef<HTMLDivElement>(null);
  useLockBodyScrolling(show, modalRef);

  return (
    <div className={drawerClasses} ref={modalRef}>
      <div className="mobile-period-selector__handle" onClick={() => setShow(false)}>
        <svg width="50" height="3" viewBox="0 0 50 3" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="50" height="3" rx="1.5" fill="#5E5E5E" />
        </svg>
      </div>
      {children}
    </div>
  );
};
