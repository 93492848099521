import { formatInteger, formatReqVolume } from "@amwaycommon/acc-mixins/l10n";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "../../../../../store";
import { storedLocale } from "../../../../impersonation/util";
import { ROUTE_PATHS } from "../../../../../common/enums/routing-enums";
import LeafTile from "../../../../../common/leaf/LeafTile/LeafTile";
import LeafTileDivider from "../../../../../common/leaf/LeafTileDivider/LeafTileDivider";
import LeafTileTitleLink from "../../../../../common/leaf/LeafTileTitleLink/LeafTileTitleLink";
import defaultContent from "../../../../../content/annualPerformanceDashboard";
import "./GarTrackingTile.scss";
import { AWARD_PROGRESS } from "../../../../../common/enums/award-progress-codes";
import TEALIUM from "../../../../../common/enums/tealium-enums";
import { track } from "../../../../../common/util/tealium";
import { AwardProgressBadge } from "../../../../../common/components/AwardProgressBadge/AwardProgressBadge";
import { getLongPeriodFormat } from "../../../../../common/util/period";
import { PIN_LEVEL_CODE } from "../../../../../common/enums/pin-level-codes";

type Props = {
  lastMonthProcessed: number;
  founderDiamondStatus: string;
  foundersPlatinumLegs: number;
  qualificationCredits: number;
  trackingLevelRank: number;
  isLoading: boolean;
};

export const GarTrackingTile = ({
  lastMonthProcessed,
  founderDiamondStatus,
  foundersPlatinumLegs,
  qualificationCredits,
  trackingLevelRank,
  isLoading,
}: Props) => {
  const { t } = useTranslation(["annualPerformanceDashboard"]);
  const locale = storedLocale();
  const { user } = useAppSelector((state) => state.boot);
  const { shellConfig } = useAppSelector((state) => state.boot);
  const { selectedPeriod } = useAppSelector((state) => state.period);
  const market = user.isoCountryCode;
  const disable = !shellConfig.shouldShowHAT;
  const lastProcessedPeriod = Number(selectedPeriod) < lastMonthProcessed ? selectedPeriod : lastMonthProcessed;

  const trackTileLinkClick = () => {
    track(
      TEALIUM.U_TAG_METHOD.LINK,
      TEALIUM.EVENT_NAME.CLICK_ACTION,
      "GAR Tracking",
      TEALIUM.CATEGORY.MY_PERFORMANCE_DASH,
      "",
      "trackingTile",
    );
  };

  const getFoundersPlatinumStatus = (): AWARD_PROGRESS => {
    return (
      {
        QF: AWARD_PROGRESS.ACHIEVED,
        QT: AWARD_PROGRESS.TRACKING,
      }[founderDiamondStatus] || AWARD_PROGRESS.NOT_TRACKING
    );
  };

  return (
    <LeafTile isLoading={isLoading}>
      <div className="GAR">
        <div className="GAR__title-link">
          <LeafTileTitleLink
            title={t("garTileTitle", defaultContent["garTileTitle"])}
            link={ROUTE_PATHS.HAT}
            disable={disable}
            onClick={trackTileLinkClick}
          />
        </div>
        <p className="GAR__lastMonthProcessed">
          <span>{t("lastMonthProcessed", defaultContent["lastMonthProcessed"])}:</span>
          &nbsp;
          <span className="GAR--bold">{getLongPeriodFormat(lastProcessedPeriod.toString(), locale)}</span>
        </p>
        <div className="GAR__divider-spacing">
          <LeafTileDivider />
        </div>
        <div className="GAR__content-container">
          <p className="GAR__row-label">{t("foundersDiamondStatus", defaultContent["foundersDiamondStatus"])}</p>
          <span className="GAR__row-value">
            <AwardProgressBadge status={getFoundersPlatinumStatus()} id={`garBadge__${getFoundersPlatinumStatus()}`} />
          </span>

          <p className="GAR__row-label">{t("foundersPlatinumLegs", defaultContent["foundersPlatinumLegs"])}</p>
          <span className="GAR__row-value">
            {foundersPlatinumLegs < 0 ? "--" : formatInteger(foundersPlatinumLegs, locale)}
          </span>

          <p className="GAR__row-label">{t("qualificationCredits", defaultContent["qualificationCredits"])}</p>
          <span className="GAR__row-value">
            {qualificationCredits < 0
              ? "--"
              : formatReqVolume(qualificationCredits, locale, market, {
                  maximumFractionDigits: 1,
                  minimumFractionDigits: 0,
                })}
          </span>
        </div>
      </div>
    </LeafTile>
  );
};
