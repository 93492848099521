import { useTranslation } from "react-i18next";
import { Tooltip } from "react-tooltip";
import {
  BRONZE_REQ_ORDER,
  REQUIREMENT_TYPES,
  THREE_LEGS_3_PERCENT,
  THREE_LEGS_6_PERCENT,
  TWO_LEGS_3_PERCENT_WITH_VCS,
  TWO_LEGS_6_PERCENT_WITH_VCS,
} from "../../../common/constants/requirements-constants";
import LeafMiniSegmentedCircle from "../../../common/leaf/LeafMiniSegmentedCircle/LeafMiniSegmentedCircle";
import { Requirement } from "../../../services/BronzeGroupTracking/bronzeGroupTracking.types";
import { getReqShortTitle, getReqTitle } from "../bronzeGroupTrackingUtils";
import "./BGTRequirementIcons.scss";
import commonContent from "../../../content/common";
import useWindowSize from "../../../common/hooks/useWindowSize";

type props = {
  requirements: Requirement[];
  layout: "vertical" | "horizontal";
  enableToolTips?: boolean;
};

// the sorting logic is going to be implemented on the new API,
// but adding here for now. Can be removed once API is integrated
const findDisplaySequence = (req: Partial<Requirement>) => {
  const index = BRONZE_REQ_ORDER.findIndex(
    ({ requirement, requirementType }) =>
      requirement.toUpperCase() === req.requirement?.toUpperCase() &&
      requirementType.toUpperCase() === req.requirementType?.toUpperCase(),
  );
  // if requirement is not found in the sort sequence, place at the end
  return index >= 0 ? index : BRONZE_REQ_ORDER.length + 1;
};

function formatPercentWithDecimals({ value, maxDecimals }: { value: number; maxDecimals: number }) {
  const decimals = value.toString().split(".")[1];
  // if number has decimals limit to maxDecimals
  return decimals && decimals.length > 0 ? value.toFixed(maxDecimals) : value;
}

const formatReqValue = (req: Requirement, value: number) => {
  if (
    req.requirementType === REQUIREMENT_TYPES.PERCENT &&
    ![THREE_LEGS_3_PERCENT.requirement, THREE_LEGS_6_PERCENT.requirement].includes(req.requirement)
  ) {
    return `${formatPercentWithDecimals({ value, maxDecimals: 2 })}%`;
  }
  return `${value}`;
};

const getLegProgressValue = (req: Requirement) => {
  return req.requirement === TWO_LEGS_3_PERCENT_WITH_VCS.requirement ? req.tracking + req.actual : req.actual;
};

const getProgress = (req: Requirement) => {
  return (
    <div className="BGTRequirementDonuts__progress">
      <span className={req.met ? "BGTRequirementDonuts__req-met" : "BGTRequirementDonuts__req-not-met"}>
        {formatReqValue(req, getLegProgressValue(req))}
      </span>
      /<span>{formatReqValue(req, req.target)}</span>
    </div>
  );
};

export const BGTRequirementIcons = ({ requirements, layout, enableToolTips = false }: props) => {
  const { t } = useTranslation(["common"]);
  const exemptLabel = t("exempt", commonContent.exempt).toString();
  const { width } = useWindowSize();

  let sortedRequirements = requirements;
  if (BRONZE_REQ_ORDER && BRONZE_REQ_ORDER.length > 0) {
    sortedRequirements = [...requirements].sort((reqA, reqB) => {
      return findDisplaySequence(reqA) - findDisplaySequence(reqB);
    });
  }

  let maxLabelLength = 1;
  sortedRequirements.forEach((req) => {
    maxLabelLength = Math.max(maxLabelLength, getReqShortTitle(req).toString().length);
  });

  const renderRequirements = () =>
    sortedRequirements.map((req, index) => {
      const isLegRequirement = [
        THREE_LEGS_3_PERCENT.requirement.toLowerCase(),
        THREE_LEGS_6_PERCENT.requirement.toLowerCase(),
        TWO_LEGS_3_PERCENT_WITH_VCS.requirement.toLowerCase(),
        TWO_LEGS_6_PERCENT_WITH_VCS.requirement.toLowerCase(),
      ].includes(req.requirement.toLowerCase());

      const label = layout === "vertical" ? getReqShortTitle(req).toString() : getReqTitle(req).toString();
      const progress = req.exemptionApplied ? exemptLabel : getProgress(req);

      let shouldTruncateText = layout === "vertical" && label.length > 8;

      const requirementClasses = [`BGTRequirementDonuts__${layout}`];

      if (layout === "vertical") {
        const labelClass = sortedRequirements.length > 3 ? "labelMoreThanThree" : "label";

        requirementClasses.push(`BGTRequirementDonuts__${labelClass}${shouldTruncateText ? "--long" : ""}`);

        if (Number(width) > 1025 && Number(width) < 1200 && sortedRequirements.length > 3) {
          shouldTruncateText = label.length > 3;
        }
      }

      return (
        <>
          <div
            key={"req_" + req.requirement}
            className={requirementClasses.join(" ")}
            data-tooltip-id={`req-${index}`}
            data-tooltip-content={label}
            data-tooltip-hidden={!enableToolTips}
          >
            {isLegRequirement ? (
              <LeafMiniSegmentedCircle
                label={layout === "vertical" ? label : ""}
                totalSegments={req.target}
                filledSegments={getLegProgressValue(req)}
                ariaTitle={req.requirementType}
                horizontal={layout === "horizontal"}
              />
            ) : (
              <LeafMiniSegmentedCircle
                label={layout === "vertical" ? label : ""}
                currentAmount={req.actual}
                targetAmount={req.target}
                ariaTitle={req.requirementType}
                horizontal={layout === "horizontal"}
              />
            )}

            {layout === "horizontal" && (
              <div className="BGTRequirementDonuts__req-info">
                <div>{label}</div>
                <div> {progress}</div>
              </div>
            )}
          </div>
          {enableToolTips && shouldTruncateText && <Tooltip id={`req-${index}`} place="bottom" />}
        </>
      );
    });

  return <div className={`BGTRequirementDonuts__wrapper--${layout}`}>{renderRequirements()}</div>;
};
