import React, { useEffect, useRef } from "react";
import { Select as BaseSelect, SelectProps, SelectRootSlotProps } from "@mui/base/Select";
import { Option } from "@mui/base/Option";
import "./LeafSelector.scss";
import { IconArrowUp } from "../../icons/IconArrowUp/IconArrowUp";
import { IconArrowDropdown } from "../../icons/IconArrowDropdown/IconArrowDropdown";

export type OptionType = {
  id: string;
  label: string;
  selected?: boolean;
};

export type Props = {
  options: OptionType[];
  onSelected: (option: OptionType) => void;
  ariaLabel?: string;
};

const LeafSelector = (props: Props) => {
  const { onSelected, options, ariaLabel } = props;
  const dropdownOptionRef = useRef<HTMLDivElement>(null);
  const toggleButtonRef = useRef<HTMLButtonElement>(null);
  const selectedOption = options.find((option) => option.selected) || null;

  const handleOptionClick = (option: OptionType) => {
    onSelected(option);
  };

  useEffect(() => {
    toggleButtonRef.current?.focus();
  }, [selectedOption]);

  const Button = React.forwardRef(function Button<TValue extends NonNullable<unknown>, Multiple extends boolean>(
    props: SelectRootSlotProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) {
    const { ownerState, ...other } = props;
    return (
      <button
        {...other}
        ref={ref}
        className={`leaf-selector__dropdown-toggle leaf-selector__dropdown-toggle${
          ownerState.open === true ? "--open" : ""
        }`}
      >
        {other.children}
        <div className="leaf-selector__dropdown-icon">
          {ownerState.open === true ? (
            <IconArrowUp backgroundClass={"blue"} />
          ) : (
            <IconArrowDropdown backgroundClass={"black"} />
          )}
        </div>
      </button>
    );
  });

  const Select = React.forwardRef(function CustomSelect<TValue extends NonNullable<unknown>, Multiple extends boolean>(
    props: SelectProps<TValue, Multiple>,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) {
    const slots: SelectProps<TValue, Multiple>["slots"] = {
      root: Button,
      ...props.slots,
    };

    return <BaseSelect {...props} ref={ref} slots={slots} />;
  }) as <TValue extends NonNullable<unknown>, Multiple extends boolean>(
    props: SelectProps<TValue, Multiple> & React.RefAttributes<HTMLButtonElement>,
  ) => React.JSX.Element;

  return (
    <Select
      value={selectedOption?.label}
      key={ariaLabel}
      slotProps={{
        root: { ref: toggleButtonRef },
        listbox: { className: "leaf-selector__dropdown-options" },
        popup: { disablePortal: true, className: "leaf-selector__dropdown-menu", ref: dropdownOptionRef },
      }}
    >
      {options.map((option, index) => (
        <Option
          key={option.id}
          value={option.label}
          onClick={() => handleOptionClick(option)}
          className={option === selectedOption ? "selected" : ""}
        >
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

export default LeafSelector;
