import { useTranslation } from "react-i18next";
import { IconCarrot } from "../../../../common/icons/IconCarrot/IconCarrot";
import { AboTileData } from "../../../../services/BronzeGroupTracking/bronzeGroupTracking.types";
import defaultContent from "../../../../content/bronzeGroupTracking";
import useWindowSize from "../../../../common/hooks/useWindowSize";
import "./TrackingTile.scss";
import { BRONZE_TAGS } from "../../../../reducers/model/bgtFilters";
import { BGTTags, GetABODisplayName, getReqTitle, IncentiveTag } from "../../bronzeGroupTrackingUtils";
import breakpoints from "../../../../common/scss/breakpoints.module.scss";
import { BGTRequirementIcons } from "../../BGTRequirementDonuts/BGTRequirementIcons";

type Props = AboTileData & {
  viewState?: "desktop" | "mobile";
};

export const TrackingTile = ({
  eligibilityTag,
  aboName,
  aboLocalName,
  isoLanguage,
  aboNumber,
  affNumber,
  reqTrackingTag,
  closeNewTag,
  incentiveTag,
  requirements,
  graduated,
  graduationPeriod,
  restarted,
  viewState = "desktop",
}: Props) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);
  const { width } = useWindowSize();

  requirements = requirements.map((req) => {
    return { ...req, text: getReqTitle(req).toString() };
  });

  const BGTLeafTags = () => {
    return (
      <>
        {[closeNewTag, reqTrackingTag, eligibilityTag].map((tag) =>
          BGTTags(tag, false, tag === BRONZE_TAGS.IN_WINDOW || tag === BRONZE_TAGS.PRE_WINDOW),
        )}
        {BGTTags("", restarted)}
      </>
    );
  };

  const EligibilityTag = () => <p>{`${t(`${eligibilityTag}`, defaultContent[eligibilityTag])}`}</p>;

  const AboTrackingTileMobile = () => {
    return (
      <div className="aboTrackingTile aboTrackingTile__display--mobile">
        <div className="aboTrackingTile__group">
          <div className="aboTrackingTile__incentiveTags aboTrackingTile__incentiveTags--mobile">
            <IncentiveTag incentiveTag={incentiveTag} />
            <div className="aboTrackingTile__eligiblityTag--mobile">
              <EligibilityTag />
            </div>
          </div>
          <div className="aboTrackingTile__aboName">
            <GetABODisplayName aboName={aboName} aboLocalName={aboLocalName} aboIsoLanguage={isoLanguage} />
          </div>
          <p className="aboTrackingTile__aboNum">#{aboNumber}</p>
          <div className="aboTrackingTile__requirements--mobile">
            <BGTRequirementIcons requirements={requirements} layout="vertical" />
          </div>
          <div className="aboTrackingTile__infoTags aboTrackingTile__cell aboTrackingTile__cell--tags">
            <BGTLeafTags />
          </div>
        </div>
        <div className="aboTrackingTile__arrow">
          <IconCarrot orientation="right" color="black" />
        </div>
      </div>
    );
  };

  const AboTrackingTileDesktop = () => {
    return (
      <div className="aboTrackingTile aboTrackingTile__display--desktop">
        <div className="aboTrackingTile__aboNameNum aboTrackingTile__cell aboTrackingTile__cell--name">
          <span className="aboTrackingTile__aboName">
            {<GetABODisplayName aboName={aboName} aboLocalName={aboLocalName} aboIsoLanguage={isoLanguage} />}
          </span>
          <span className="aboTrackingTile__aboNum">#{aboNumber}</span>
        </div>
        <div className="aboTrackingTile__incentiveTags aboTrackingTile__cell">
          <IncentiveTag incentiveTag={incentiveTag} />
        </div>

        <div className="aboTrackingTile__infoTags aboTrackingTile__cell aboTrackingTile__cell--tags">
          <BGTLeafTags />
        </div>

        <div className="aboTrackingTile__cell aboTrackingTile__cell--progress">
          <BGTRequirementIcons requirements={requirements} layout="vertical" enableToolTips />
        </div>

        <div className="aboTrackingTile__cell aboTrackingTile__cell--eligibility">
          <EligibilityTag />
        </div>

        <div className="aboTrackingTile__arrow">
          <IconCarrot orientation="right" color="black" />
        </div>
      </div>
    );
  };

  return (
    <>
      {(width && Number(width) < Number(breakpoints.desktop) + 1) || viewState === "mobile" ? (
        <AboTrackingTileMobile />
      ) : (
        <AboTrackingTileDesktop />
      )}
    </>
  );
};
