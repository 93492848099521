/**
 * AFF string to ISO Country Code
 */
export const AFFISO: Readonly<{ [key: string]: string }> = Object.freeze({
  "380": "AR",
  "210": "AT",
  "030": "AU",
  "120": "BE",
  "620": "BG",
  "780": "BN",
  "310": "BR",
  "750": "BW",
  "020": "CA",
  "110": "CH",
  "400": "CL",
  "360": "CN",
  "190": "CO",
  "290": "CR",
  "390": "CZ",
  "060": "DE",
  "470": "DK",
  "580": "DO",
  "800": "EE",
  "250": "ES",
  "460": "FI",
  "080": "FR",
  "040": "GB",
  "480": "GR",
  "260": "GT",
  "050": "HK",
  "520": "HN",
  "650": "HR",
  "700": "HT",
  "340": "HU",
  "220": "ID",
  "300": "IE",
  "430": "IN",
  "160": "IT",
  "070": "JP",
  "180": "KR",
  "830": "KZ",
  "810": "LT",
  "820": "LV",
  "980": "MN",
  "170": "MX",
  "100": "MY",
  "740": "NA",
  "090": "NL",
  "280": "NO",
  "240": "NZ",
  "230": "PA",
  "350": "PH",
  "150": "PL",
  "270": "PT",
  "590": "RO",
  "420": "RU",
  "370": "SE",
  "530": "SG",
  "140": "SI",
  "490": "SK",
  "510": "SV",
  "200": "TH",
  "450": "TR",
  "130": "TW",
  "660": "UA",
  "010": "US",
  "440": "UY",
  "600": "VE",
  "500": "VN",
  "570": "ZA",
});

/**
 * ISO Country Code to AFF map
 */
export const ISOAFF: Readonly<{ [key: string]: string }> = Object.freeze({
  MY: "100",
  CH: "110",
  BE: "120",
  TW: "130",
  SI: "140",
  PL: "150",
  IT: "160",
  MX: "170",
  KR: "180",
  CO: "190",
  TH: "200",
  AT: "210",
  ID: "220",
  PA: "230",
  NZ: "240",
  ES: "250",
  GT: "260",
  PT: "270",
  NO: "280",
  CR: "290",
  IE: "300",
  BR: "310",
  HU: "340",
  PH: "350",
  CN: "360",
  SE: "370",
  AR: "380",
  CZ: "390",
  CL: "400",
  RU: "420",
  IN: "430",
  UY: "440",
  TR: "450",
  FI: "460",
  DK: "470",
  GR: "480",
  SK: "490",
  VN: "500",
  SV: "510",
  HN: "520",
  SG: "530",
  ZA: "570",
  DO: "580",
  RO: "590",
  VE: "600",
  BG: "620",
  HR: "650",
  UA: "660",
  HT: "700",
  NA: "740",
  BW: "750",
  BN: "780",
  EE: "800",
  LT: "810",
  LV: "820",
  KZ: "830",
  AU: "030",
  CA: "020",
  DE: "060",
  FR: "080",
  GB: "040",
  HK: "050",
  JP: "070",
  NL: "090",
  US: "010",
});
