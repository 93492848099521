import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import "./LeafPrimaryTag.scss";

type Props = {
  isPrimary?: boolean;
};

const LeafPrimaryTag = (props: Props) => {
  const { isPrimary } = props;
  const { t } = useTranslation(["profile"]);

  return isPrimary ? (
    <span className="tag-primary">{`${t(
      "customerProfile.tags.primary",
      defaultContent["customerProfile"]["tags"]["primary"],
    )}`}</span>
  ) : (
    <></>
  );
};

export default LeafPrimaryTag;
