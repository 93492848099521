import EmeraldDiamondLosResult, { EdlosServiceResult } from "./emeraldDiamondLosResult";

export interface EmeraldDiamondLosPayload {
  currentPerfYear: number;
  previousPerfYear: number;
  totalResults: number;
  startsAt: number;
  endsAt: number;
  lastClosedPeriod: number;
  pendingAnnualBonus: boolean;
  results: Array<EdlosServiceResult>;
}

/**
 * Model for emerald/diamond los
 *
 * @export
 * @class EmeraldDiamondLos
 */
export default class EmeraldDiamondLos {
  currentPerformanceYear: number;
  previousPerformanceYear: number;
  totalResults: number;
  startingIndex: number;
  endingIndex: number;
  lastClosedPeriod: number;
  pendingAnnualBonus: boolean;
  results: Array<EmeraldDiamondLosResult>;
  /**
   * Creates an instance of EmeraldDiamondLos.
   * @param {object} emeraldDiamondLos
   * @memberof EmeraldDiamondLos
   */
  constructor(emeraldDiamondLos: EmeraldDiamondLosPayload) {
    this.currentPerformanceYear = emeraldDiamondLos.currentPerfYear;
    this.previousPerformanceYear = emeraldDiamondLos.previousPerfYear;
    this.totalResults = emeraldDiamondLos.totalResults;
    this.startingIndex = emeraldDiamondLos.startsAt;
    this.endingIndex = emeraldDiamondLos.endsAt;
    this.lastClosedPeriod = emeraldDiamondLos.lastClosedPeriod;
    this.pendingAnnualBonus = emeraldDiamondLos.pendingAnnualBonus;
    this.results = emeraldDiamondLos.results.map((result) => {
      return new EmeraldDiamondLosResult(result);
    });
  }
}
