/**
 * Locales codes
 */
export const LOCALE: Readonly<{ [key: string]: string }> = Object.freeze({
  // English
  EN_US: "en-US",
  // Chinese (Simplified)
  ZH_CN: "zh-CN",
  // Japanese
  JA_JP: "ja-JP",
  // Indonesian - Bahasa Indonesia
  ID_ID: "id-ID",
  // Korean
  KO_KR: "ko-KR",
  // Malay- Bahasa Malaysia
  MS_MY: "ms-MY",
  // Russian
  RU_RU: "ru-RU",
  // Thai
  TH_TH: "th-TH",
  // Vietnamese
  VI_VN: "vi-VN",
  // Chinese (Traditional)
  ZH_TW: "zh-TW",
});
