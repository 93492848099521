import { useEffect, useState } from "react";
import LeafFilterButton from "../../../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import LeafRadioMenu from "../../../../common/leaf/LeafRadioMenu/LeafRadioMenu";
import "./ServerSideExportMenu.scss";
import { ExportAffABO } from "../../../../services/ExportV2/exportV2API.types";
import MenuList from "../ServerSideExportDownload/Loader/MenuList/MenuList";
import { dispatchRequestExportDetailsEvent, ExportDetailEvent } from "../ServerSideExportUtil/ServerSideExportEvents";
import { exportMenuTypeTrack } from "../../../../common/tealium/Export/ServerSideExportTrack";
import LeafRectangleLoader from "../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";

export type ExportDetailsPayload = {
  moduleId: string;
  fullmap: {
    avaliableKPIs: string[];
    selectedKPIs: string[];
    marketType: string;
  };
  currentView: {
    currentViewList: ExportAffABO[];
    avaliableKPIs: string[];
    selectedKPIs: string[];
  };
  reportAffAbo: string;
};

export type ExportButtonPayload = {
  selectedId: string;
} & ExportDetailsPayload;

type Option = {
  id: string;
  text: string;
};

type Props = {
  moduleId: string;
  title: string;
  subTitle: string;
  options: Option[];
  exportButton: {
    text: string;
    onClick: (payload: ExportButtonPayload) => void;
  };
  showExportButton?: boolean;
  viewExportsButton?: {
    text: string;
    onClick: () => void;
  };
  showViewExportsButton?: boolean;
  show?: boolean;
  isFetching: boolean;
};

const ServerSideExportMenu = (props: Props) => {
  const {
    moduleId,
    title,
    subTitle,
    options,
    exportButton,
    showExportButton = true,
    viewExportsButton,
    showViewExportsButton = false,
    show = true,
    isFetching,
  } = props;

  const [selectedId, setSelectedId] = useState("");

  // setup event listener to listen to "ExportDetails" event from accWcLosHeader (Lit component)
  useEffect(() => {
    const receivedExportDetails = (event: CustomEventInit) => {
      const exportDetails: ExportDetailsPayload = event.detail;
      exportButton.onClick({
        ...exportDetails,
        selectedId,
      });
    };

    window.addEventListener(ExportDetailEvent, receivedExportDetails);

    return () => {
      window.removeEventListener(ExportDetailEvent, receivedExportDetails);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedId]);

  const setOnSelected = (id: string) => setSelectedId(id);

  const exportButtonOnclick = () => {
    if (selectedId !== "") {
      exportMenuTypeTrack(selectedId);
      dispatchRequestExportDetailsEvent(moduleId);
    }
  };

  if (show === false) {
    return <></>;
  }

  return (
    <div className="server-side-export-menu" data-testid="serverSideExportMenu">
      <div className="server-side-export-menu__header">
        <LeafRectangleLoader isLoading={isFetching} width={"90%"} height={"1.5rem"}>
          <div className="server-side-export-menu__header__title">{title}</div>
        </LeafRectangleLoader>

        <LeafRectangleLoader isLoading={isFetching} width={"60%"} height={"1rem"}>
          <div className="server-side-export-menu__header__subtitle">{subTitle}</div>
        </LeafRectangleLoader>
      </div>
      <div className="server-side-export-menu__content">
        <MenuList numberOfLoaders={3} isFetching={isFetching} />
        {!isFetching && <LeafRadioMenu options={options} onSelected={setOnSelected} selectedId={selectedId} />}
      </div>
      <div className="server-side-export-menu__footer">
        {showExportButton && (
          <LeafFilterButton
            active={selectedId !== ""}
            text={exportButton.text}
            onButtonClick={exportButtonOnclick}
            theme={"dark"}
          />
        )}
        {showViewExportsButton && (
          <LeafFilterButton
            text={viewExportsButton ? viewExportsButton.text : ""}
            onButtonClick={() => viewExportsButton?.onClick()}
          />
        )}
      </div>
    </div>
  );
};

export default ServerSideExportMenu;
