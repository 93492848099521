import { AWARD_CODES } from "../../enums/award-codes";
import { DROPDOWN_KEYS, DROPDOWN_LABELS, ROUTE_LABELS, ROUTE_PATHS } from "../../enums/routing-enums";
import { NavData, NavDropdown } from "../../interfaces/routing";
import { filterDropdown } from "./createMenuConfig";

export const createEarlyIncentivesDropdown = (navConfigs: NavData[]): NavDropdown => {
  const earlyIncentivesDropdown = {
    label: DROPDOWN_LABELS.EARLY_INCENTIVES,
    key: DROPDOWN_KEYS.EARLY_INCENTIVES,
    // incentives should be sorted in ascending order based on award code
    items: [
      {
        label: ROUTE_LABELS.STRONG_START_INCENTIVE,
        path: ROUTE_PATHS.STRONG_START_INCENTIVE,
        class: ROUTE_PATHS.STRONG_START_INCENTIVE,
        code: AWARD_CODES.STRONG_START_INCENTIVE,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.CSI,
        path: ROUTE_PATHS.CSI,
        class: ROUTE_PATHS.CSI,
        code: AWARD_CODES.CSI,
        navPosition: 1,
      },
      {
        label: ROUTE_LABELS.BRONZE_INCENTIVES,
        path: ROUTE_PATHS.BRONZE_INCENTIVES,
        class: ROUTE_PATHS.BRONZE_INCENTIVES,
        code: AWARD_CODES.BRONZE_INCENTIVES,
        navPosition: 2,
      },
    ],
  };

  return filterDropdown(navConfigs, earlyIncentivesDropdown);
};
