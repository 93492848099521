import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import LeafDateTime from "../../../LeafDateTime/LeafDateTime";
import "./LeafRecentSponsorDateInfo.scss";

export type Props = {
  date: string;
  privacyFlag?: boolean;
  show?: boolean;
};

const LeafRecentSponsorDateInfo = (props: Props) => {
  const { date, privacyFlag, show } = props;
  const { t } = useTranslation(["profile"]);

  const renderInfo = date ? (
    <LeafDateTime date={date} options={{ month: "long", day: "numeric", year: "numeric" }} ignoreTimeZone={true} />
  ) : (
    `${t(
      "aboProfile.datesTab.noRecentSponsoringDate",
      defaultContent["aboProfile"]["datesTab"]["noRecentSponsoringDate"],
    )}`
  );

  const privacyView = privacyFlag === true ? "-" : renderInfo;

  if (show === true) {
    return (
      <div className="leaf-recent-sponsor-date">
        <div className="leaf-recent-sponsor-date__container">
          <div className="leaf-recent-sponsor-date__content-title">{`${t(
            "aboProfile.datesTab.recentSponsoringDate",
            defaultContent["aboProfile"]["datesTab"]["recentSponsoringDate"],
          )}`}</div>
          <div className="leaf-recent-sponsor-date__content-data">{privacyView}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafRecentSponsorDateInfo;
