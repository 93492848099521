import FaaBusinessLeg, { FaaBusinessLegPayload } from "./faaBusinessLeg";
import hatFaaHelper, { HatFaaHelperPayload } from "./hatFaaHelper";

export interface FaaBusinessPayload extends HatFaaHelperPayload {
  Legs?: Array<FaaBusinessLegPayload>;
  ABONo?: number;
  Country?: string;
  ISD?: boolean;
  Primary?: boolean;
  EBRPeriod?: number;
  DBRPeriod?: number;
  FPLTPeriod?: number;
  Points?: number;
  PaymentPoints?: number;
}
export default class FaaBusiness extends hatFaaHelper {
  _p: FaaBusinessPayload;
  _legs: Array<FaaBusinessLeg>;
  constructor(payload: FaaBusinessPayload) {
    super(payload);
    this._p = payload || {};
    const legs = this._p.Legs || [];
    this._legs = legs.map((leg) => new FaaBusinessLeg(leg));
  }

  /**
   * Business abo number
   * @return {number}
   */
  get abo(): number | null {
    return this._p.ABONo || null;
  }

  /**
   * ISO country code
   * @return {string}
   */
  get isoCountryCode(): string | null {
    return this._p.Country || null;
  }

  /**
   * ISD
   */
  get isIsd(): boolean {
    return this._p.ISD || false;
  }

  /**
   * True if this is the ABO's primary business.
   */
  get isPrimary(): boolean | null {
    return this._p.Primary || null;
  }

  /**
   * EBR Period.
   * @return {number}
   */
  get ebrPeriod(): number | null {
    return this._p.EBRPeriod || null;
  }

  /**
   * DBR Period.
   * @return {number}
   */
  get dbrPeriod(): number | null {
    return this._p.DBRPeriod || null;
  }

  /**
   * FP Period.
   * @return {number}
   */
  get fpPeriod(): number | null {
    return this._p.FPLTPeriod || null;
  }

  /**
   * Points.
   * @return {number}
   */
  get points(): number | null {
    return this._p.Points || null;
  }

  /**
   * Payment Points.
   * @return {number}
   */
  get paymentPoints(): number | null {
    return this._p.PaymentPoints || null;
  }

  /**
   * Business legs
   * @return {array} - Array of FaaBusinessLeg's
   */
  get legs(): Array<FaaBusinessLeg> {
    return this._legs;
  }
}
