import FaaGenerator, { FaaGeneratorPayload } from "./faaGenerator";

const DBR_GENERATOR_CODE = 661;
const EBR_GENERATOR_CODE = 662;
const FPLEG_GENERATOR_CODE = 663;

export interface GeneratorType {
  GeneratorCode?: number;
  Generators?: FaaGeneratorPayload[];
}

export interface FaaGeneratorDetailPayload {
  GeneratorTypes?: Array<GeneratorType>;
  Aff: number;
  ABONo: number;
  PerformanceYear: number;
  ClosingPeriod: number;
}

export default class FaaGeneratorDetail {
  _p: FaaGeneratorDetailPayload;
  _dbrGenerators: GeneratorType;
  _ebrGenerators: GeneratorType;
  _fpLegGenerators: GeneratorType;
  constructor(payload: FaaGeneratorDetailPayload) {
    this._p = payload;
    const generatorTypes = this._p.GeneratorTypes || [];
    this._dbrGenerators = generatorTypes.find((type) => type.GeneratorCode === DBR_GENERATOR_CODE) || {};
    this._ebrGenerators = generatorTypes.find((type) => type.GeneratorCode === EBR_GENERATOR_CODE) || {};
    this._fpLegGenerators = generatorTypes.find((type) => type.GeneratorCode === FPLEG_GENERATOR_CODE) || {};
  }

  /**
   * Affiliate number
   * @return {number}
   */
  get aff(): number {
    return this._p.Aff;
  }

  /**
   * ABO number
   * @return {number}
   */
  get abo(): number {
    return this._p.ABONo;
  }

  /**
   * Performance year
   * @return {number}
   */
  get performanceYear(): number {
    return this._p.PerformanceYear;
  }

  /**
   * Closing period
   * @return {number}
   */
  get closingPeriod(): number {
    return this._p.ClosingPeriod;
  }

  /**
   * FAA DBR Generator.
   * @return {array} - Array of FaaGenerator's
   */
  get dbrGenerators(): Array<FaaGenerator> {
    const _dbrGenerators = this._dbrGenerators.Generators || [];
    return _dbrGenerators.map((generator) => new FaaGenerator(generator));
  }

  /**
   * FAA EBR Generator.
   * @return {array} - Array of FaaGenerator's
   */
  get ebrGenerators(): Array<FaaGenerator> {
    const _ebrGenerators = this._ebrGenerators.Generators || [];
    return _ebrGenerators.map((generator) => new FaaGenerator(generator));
  }

  /**
   * FAA Founders Platinum Leg Generator.
   * @return {array} - Array of FaaGenerator's
   */
  get fpLegGenerators(): Array<FaaGenerator> {
    const _fpLegGenerators = this._fpLegGenerators.Generators || [];
    return _fpLegGenerators.map((generator) => new FaaGenerator(generator));
  }
}
