import { track } from "../../../util/tealium";
import TEALIUM from "../../../enums/tealium-enums";

export const closeProfileClickTrack = () => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.ABO_PROFILE,
    "",
    "close_profile",
  );
};
