import { track } from "../../util/tealium";
import { IconArrowDropdown } from "../../icons/IconArrowDropdown/IconArrowDropdown";
import "./LeafNavigationDropdown.scss";
import { TealiumClickActionMapper } from "../../util/tealiumClickActionMapper";
import TEALIUM from "../../enums/tealium-enums";
import { LinkUtil } from "../../util/LinkUtil";

type Link = {
  label: string;
  path: string;
};

type Props = {
  links: Link[];
  title: string;
  clickFunction: Function;
};

export const closeDropdown = (dropdownId: string, clickFunction: Function, link: Link) => {
  const mobileDropdownEl = document.getElementById(dropdownId) as HTMLInputElement;
  mobileDropdownEl.checked = false;
  clickFunction();
  /* istanbul ignore next */
  handleTealiumTrackClickAction(link);
};

/* istanbul ignore next */
export const handleTealiumTrackClickAction = (link: Link) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.DROP_DOWN,
    "",
    TealiumClickActionMapper[link.path],
  );
};

export const LeafNavigationDropdown = (props: Props) => {
  const { title, links, clickFunction } = props;
  const linkId = title.replace(/\s/g, "");
  const inputId = linkId + "__input";
  const htmlLinks = links.map((link, index) => {
    return (
      <LinkUtil to={link} id={index} key={index}>
        <div
          onClick={() => {
            closeDropdown(inputId, clickFunction, link);
          }}
          data-testid={`link-${index}`}
        >
          {link.label}
        </div>
      </LinkUtil>
    );
  });

  return (
    <div id={linkId} className="leaf-nav-dropdown" data-testid="leafNavigationDropdown">
      <input
        aria-label={title}
        id={inputId}
        className="leaf-nav-dropdown__checkbox"
        type="checkbox"
        autoComplete="off"
      ></input>
      <div className="leaf-nav-dropdown__dropdown">
        <span className="leaf-nav-dropdown__dropdown-title">{title}</span>
        <span className="leaf-nav-dropdown__dropdown-arrow">
          <IconArrowDropdown backgroundClass="blue" />
        </span>
      </div>
      <div id={linkId + "__dropdownLinks"} className="leaf-nav-dropdown__links" data-testid={`__dropdownLinks`}>
        {htmlLinks}
      </div>
    </div>
  );
};
