import { useTranslation } from "react-i18next";
import LeafABOAddress from "../../../../../../../common/leaf/LeafProfile/LeafABOAddress/LeafABOAddress";
import defaultContent from "../../../../../../../content/profile";
import { Address } from "../../../../../../../services/Profile/profileAPI.types";
import "./ABOProfilePrimaryAddress.scss";
import { cardActionClickTrack } from "../../../../../../../common/tealium/ABOProfile/Profile/ApplicantCardTrack";

type Props = {
  address: Address;
  privacyFlag: boolean;
};

const ABOProfilePrimaryAddress = (props: Props) => {
  const { t } = useTranslation(["profile"]);
  const { address, privacyFlag } = props;
  const { addressLines, municipality, region, postalCode, country } = address || {};

  const addressInfo = [municipality, region, postalCode, country];
  const noAddressInfo =
    addressInfo.every((addressInfo) => addressInfo === "--" || addressInfo === null) &&
    (!addressLines || addressLines.length === 0);
  const hideAddress = noAddressInfo === true || privacyFlag === true;

  const applicantTrackOnclick = (event?: string) => {
    cardActionClickTrack(event, true);
  };

  if (hideAddress === true) return <></>;

  return (
    <div>
      <h2 className="abo-profile-primary-address__header">{`${t(
        "aboProfile.overviewTab.primaryAddress",
        defaultContent["aboProfile"]["overviewTab"]["primaryAddress"],
      )}`}</h2>
      <div className="abo-profile-primary-address__content">
        <LeafABOAddress
          addresses={address}
          privacyFlag={privacyFlag}
          applicantTrackOnclick={applicantTrackOnclick}
          hideLabel={true}
        />
      </div>
    </div>
  );
};

export default ABOProfilePrimaryAddress;
