import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  FORMAT_CSV,
  FORMAT_PDF,
  FORMAT_XLSX,
  FULL_MAP,
  STATUS_DONE,
  STATUS_ERROR,
  STATUS_IN_PROGRESS,
  STATUS_PENDING,
} from "../../../../common/constants/export-constants";
import { IconCsv } from "../../../../common/icons/IconCsv/IconCsv";
import { IconDelete } from "../../../../common/icons/IconDelete/IconDelete";
import { IconPdf } from "../../../../common/icons/IconPdf/IconPdf";
import { IconXls } from "../../../../common/icons/IconXls/IconXls";
import LeafDateTime from "../../../../common/leaf/LeafDateTime/LeafDateTime";
import LeafFilterButton from "../../../../common/leaf/LeafFilter/LeafFilterButton/LeafFilterButton";
import { unixEpochMilliSecToISO } from "../../../../common/util/formatDate";
import { getLongPeriodFormat } from "../../../../common/util/period";
import { storedLocale } from "../../../impersonation/util";
import defaultContent from "../../../../content/serverSideExport";
import { getCountryNameFromCode } from "../../../../common/util/formatCountryName";
import "./ServerSideExportDownload.scss";
import { ExportDetails } from "../../../../services/ExportV2/exportV2API.types";
import {
  useDeleteExportV2Mutation,
  useLazyGetExportDownloadV2Query,
  util as ExportAPIUtil,
} from "../../../../services/ExportV2/exportV2API";
import { losFileNameBuilder } from "../ServerSideExportUtil/ServerSideExportFileName";
import { useAppDispatch } from "../../../../store";
import { dispatchExportCountEvent } from "../ServerSideExportUtil/ServerSideExportEvents";
import DownloadList from "./Loader/DownloadList/DownloadList";

type Props = {
  title: string;
  subTitle: string;
  exportTypeContent: {
    fullMap: string;
    fullMapAllKPIs: string;
    currentView: string;
  };
  exports?: ExportDetails[];
  exportAnotherButton?: {
    exportText: string;
    exportAnotherText: string;
    active?: boolean;
    onClick: (showExports: boolean) => void;
  };
  showExportAnotherButton?: boolean;
  moduleId: string;
  show: boolean;
  downloadButtonOnClick: (exportDetails: ExportDetails, fileFormat: string, moduleId: string) => void;
  deleteButtonOnClick: (exportDetails: ExportDetails, moduleId: string) => void;
  isFetching: boolean;
};

const ServerSideExportDownload = ({
  title,
  subTitle,
  exportTypeContent,
  exports = [],
  exportAnotherButton,
  showExportAnotherButton,
  downloadButtonOnClick,
  deleteButtonOnClick,
  moduleId,
  show,
  isFetching,
}: Props) => {
  const { t } = useTranslation(["serverSideExport"]);

  const dispatch = useAppDispatch();

  const locale = storedLocale();

  const showExports = exports.length > 0;

  const {
    exportText = "",
    exportAnotherText = "",
    active: activeExportAnotherButton = true,
  } = exportAnotherButton || {};

  const options = {
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    year: "numeric",
    month: "short",
    hour12: true,
  } as Intl.DateTimeFormatOptions;

  const [getExportDownload] = useLazyGetExportDownloadV2Query();
  const [deleteExport] = useDeleteExportV2Mutation();

  const updateExportList = (exportList: ExportDetails[]) => {
    dispatch(
      ExportAPIUtil.upsertQueryData(
        "getExportListV2",
        { moduleId },
        {
          Exports: exportList,
        },
      ),
    );
  };

  useMemo(() => {
    if (exports.length === 0) {
      dispatchExportCountEvent(0, 0);
    }
  }, [exports]);

  const setDownloadIconLoading = (isFetching: boolean, targetExportDetails: ExportDetails, fileFormat: string) => {
    const isPDF = fileFormat === FORMAT_PDF;
    const isExcel = fileFormat === FORMAT_XLSX;
    const isCSV = fileFormat === FORMAT_CSV;

    const updatedExportList = exports.map((details) => {
      if (details.ExportID === targetExportDetails.ExportID) {
        return {
          ...details,
          PDFLoading: isPDF && isFetching,
          ExcelLoading: isExcel && isFetching,
          CSVLoading: isCSV && isFetching,
        };
      }
      return details;
    });

    updateExportList(updatedExportList);
  };

  const exportDownloadButtonOnClick = async (exportDetails: ExportDetails, fileFormat: string, moduleId: string) => {
    downloadButtonOnClick(exportDetails, fileFormat, moduleId);

    const { ExportID } = exportDetails;
    setDownloadIconLoading(true, exportDetails, fileFormat);

    const { data } = await getExportDownload({
      ExportID,
      FileFormat: fileFormat,
      DownloadFileName: losFileNameBuilder(exportDetails.Period),
    });

    const link = document.createElement("a");
    link.href = data?.DownloadLink || "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setDownloadIconLoading(false, exportDetails, fileFormat);
  };

  const exportDeleteButtonOnClick = (targetExportDetails: ExportDetails, moduleId: string) => {
    deleteButtonOnClick(targetExportDetails, moduleId);

    const { ExportID } = targetExportDetails;

    const updatedExportList = exports.filter((details) => details.ExportID !== ExportID);
    updateExportList(updatedExportList);

    deleteExport({ exportId: ExportID });
  };

  const showIcon = (status: string) => {
    return [STATUS_IN_PROGRESS, STATUS_ERROR, STATUS_PENDING].includes(status) === false;
  };

  const getExportTypeTitle = (exp: ExportDetails) => {
    if (exp.ExportType === FULL_MAP) {
      return exp.FilteredByKPIS === true ? exportTypeContent.fullMap : exportTypeContent.fullMapAllKPIs;
    }
    return exportTypeContent.currentView;
  };

  const getDoneMessage = (expiresInSeconds: number) => {
    const hours = expiresInSeconds / 3600;
    const moreThan1Hrs = hours > 1;
    const left1Hr = hours === 1;

    const lessThanMsg =
      moreThan1Hrs || left1Hr
        ? ""
        : `${t(
            "availableExportModal.exportFile.lessThan",
            defaultContent["availableExportModal"]["exportFile"]["lessThan"],
          )}`;
    const remainingHrs = moreThan1Hrs ? Math.floor(hours) : 1;
    const hourMsg = moreThan1Hrs
      ? `${t(
          "availableExportModal.exportFile.doneHoursMessage",
          defaultContent["availableExportModal"]["exportFile"]["doneHoursMessage"],
        )}`
      : `${t(
          "availableExportModal.exportFile.doneHourMessage",
          defaultContent["availableExportModal"]["exportFile"]["doneHourMessage"],
        )}`;

    return ` - ${t(
      "availableExportModal.exportFile.doneExpiresInMessage",
      defaultContent["availableExportModal"]["exportFile"]["doneExpiresInMessage"],
    )} ${lessThanMsg} ${remainingHrs} ${hourMsg} `;
  };

  const getExportStatusMessage = (exp: ExportDetails) => {
    switch (exp.ExportStatus) {
      case STATUS_ERROR:
        return `${t(
          "availableExportModal.exportFile.exportError",
          defaultContent["availableExportModal"]["exportFile"]["exportError"],
        )}`;
      case STATUS_IN_PROGRESS:
      case STATUS_PENDING:
        return `${t(
          "availableExportModal.exportFile.inProgressMessage",
          defaultContent["availableExportModal"]["exportFile"]["inProgressMessage"],
        )}`;
      case STATUS_DONE:
        return getDoneMessage(exp.ExpiresInSeconds);
    }
  };

  if (show === false) return <></>;

  return (
    <div className="server-side-export-download">
      <div className="server-side-export-download__header">
        <div className="server-side-export-download__header__title">{title}</div>
        {showExports === true && <div className="server-side-export-download__header__subtitle">{subTitle}</div>}
      </div>
      <div className="server-side-export-download__content">
        {isFetching === false && showExports === false && (
          <div className="server-side-export-download__no-content">
            <Trans>
              {t(
                "availableExportModal.noExportsAvailable",
                defaultContent["availableExportModal"]["noExportsAvailable"],
              )}
            </Trans>
          </div>
        )}
        <DownloadList numberOfLoaders={1} isFetching={isFetching} />
        {exports.map((exp, index) => (
          <div className="server-side-export-download__export-detail" key={index}>
            <div className="server-side-export-download__export-type">{getExportTypeTitle(exp)}</div>
            <div className="server-side-export-download__divider-line"></div>
            <div className="server-side-export-download__export-period">
              <Trans>
                {" "}
                {t(
                  "availableExportModal.exportFile.period",
                  defaultContent["availableExportModal"]["exportFile"]["period"],
                )}
              </Trans>{" "}
              {getLongPeriodFormat(exp.Period, locale)}
            </div>
            <div className="server-side-export-download__affAbo-country">
              <Trans>
                {t(
                  "availableExportModal.exportFile.aboDetail",
                  defaultContent["availableExportModal"]["exportFile"]["aboDetail"],
                  { aboNo: exp.ReportAbo, country: getCountryNameFromCode(exp.CountryCode || "", locale) },
                )}
              </Trans>
            </div>
            <div className="server-side-export-download__start-time">
              <Trans>
                {t(
                  "availableExportModal.exportFile.startTime",
                  defaultContent["availableExportModal"]["exportFile"]["startTime"],
                )}
              </Trans>{" "}
              <LeafDateTime date={unixEpochMilliSecToISO(exp.StartTime)} options={options} ignoreTimeZone={false} />
            </div>
            <div className="server-side-export-download__icons">
              <div
                className={`server-side-export-download__download-icons server-side-export-download__download-icons${
                  showIcon(exp.ExportStatus) ? "--show" : ""
                }`}
              >
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => exportDownloadButtonOnClick(exp, FORMAT_XLSX, moduleId)}
                  aria-label={`${t(
                    "availableExportModal.excelLabel",
                    defaultContent["availableExportModal"]["excelLabel"],
                  )}`}
                  className="server-side-export-download__icon"
                >
                  <IconXls loading={exp.ExcelLoading} />
                </div>
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => exportDownloadButtonOnClick(exp, FORMAT_CSV, moduleId)}
                  aria-label={`${t(
                    "availableExportModal.csvLabel",
                    defaultContent["availableExportModal"]["csvLabel"],
                  )}`}
                  className="server-side-export-download__icon"
                >
                  <IconCsv loading={exp.CSVLoading} />
                </div>
                <div
                  tabIndex={0}
                  role="button"
                  onClick={() => exportDownloadButtonOnClick(exp, FORMAT_PDF, moduleId)}
                  aria-label={`${t(
                    "availableExportModal.pdfLabel",
                    defaultContent["availableExportModal"]["pdfLabel"],
                  )}`}
                  className="server-side-export-download__icon"
                >
                  <IconPdf loading={exp.PDFLoading} />
                </div>
                <div className="server-side-export-download__icon-divider"></div>
              </div>
              <div
                tabIndex={0}
                role="button"
                aria-label={`${t(
                  "availableExportModal.deleteLabel",
                  defaultContent["availableExportModal"]["deleteLabel"],
                )}`}
                className="server-side-export-download__icon"
                onClick={() => exportDeleteButtonOnClick(exp, moduleId)}
              >
                <IconDelete />
              </div>
            </div>
            <div className="server-side-export-download__export-status">
              <div
                className={`server-side-export-download__ready server-side-export-download__ready--${exp.ExportStatus}`}
              >
                <Trans>
                  {t(
                    "availableExportModal.exportFile.doneBoldMessage",
                    defaultContent["availableExportModal"]["exportFile"]["doneBoldMessage"],
                  )}
                </Trans>
              </div>
              <div
                className={`server-side-export-download__status-message server-side-export-download__status-message--${exp.ExportStatus}`}
              >
                {getExportStatusMessage(exp)}
              </div>
            </div>
          </div>
        ))}
      </div>
      {showExportAnotherButton === true && (
        <div className="server-side-export-download__footer">
          <LeafFilterButton
            active={activeExportAnotherButton}
            text={showExports ? exportAnotherText : exportText}
            onButtonClick={() => {
              if (activeExportAnotherButton) {
                exportAnotherButton?.onClick(showExports);
              }
            }}
            theme="dark"
          />
        </div>
      )}
    </div>
  );
};

export default ServerSideExportDownload;
