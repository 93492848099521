import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { HATSummaryAPIResponse } from "../../../../services/HATSummary/hatSummaryAPI.types";
import { FoundersPlatinumTrackingResponse } from "../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI.types";
import { useGetFoundersPlatinumTrackingQuery } from "../../../../services/AnnualPerformanceDashboard/annualPerformanceDashboardAPI";
import { getCurrentPeriod, getPerformanceYear } from "../../../../common/util/period";
import { useGetHATSummaryQuery } from "../../../../services/HATSummary/hatSummaryAPI";

export const defaultTrackingPinData = (): HATSummaryAPIResponse => {
  return {
    currentAwardDetail: {
      currentAwardRank: 0,
      highestAwardRank: 0,
      isGlobalAward: false,
      nextPyCurrentAwardRank: 0,
      trackingLevelRank: 0,
    },
    currentProcessingMonth: "",
    garDetail: {
      closingPeriods: [],
      founderDiamondStatus: "QT",
      qcAwardLevelStatus: "QT",
      foundersPlatinumLegs: 0,
      globalAwardRank: 0,
      isFoundersDiamond: false,
      lastMonthProcessed: 0,
      qualCreditDtls: [
        {
          achieved: false,
          count: 0,
          maxCountPerLeg: 0,
        },
        {
          achieved: false,
          count: 0,
          maxCountPerLeg: 0,
        },
      ],
      qualCreditMaxPerLeg: 0,
      qualCredits: 0,
      qualCreditsNotCounting: 0,
    },
    hasGarAccess: false,
    hasHatAccess: false,
    hatDetail: {
      inMarketLegs: 0,
      foundersLegs: 0,
      legTrackingAwardRank: "",
      totalLegs: 0,
      currentAwardRank: "",
      highestTrackingAwardLegsRank: "",
    },
  };
};

const defaultFppResponse = (): FoundersPlatinumTrackingResponse => {
  return {
    IsTracking: false,
    IsQualified: false,
  };
};

export const TrackingPinData = (period: string) => {
  const { configuration, user } = useAppSelector((state) => state.boot);
  const currentPY = getPerformanceYear(`${getCurrentPeriod()}`);
  const selectedPY = getPerformanceYear(period);
  const {
    data: hatSummaryData,
    isLoading: isHatQueryLoading,
    isFetching: isHatQueryFetching,
    isError: isHatQueryError,
    refetch: hatRefetch,
  } = useGetHATSummaryQuery({
    baseUrl: configuration.lambdaUrlBase,
    aboNo: user.abo,
    affNo: user.aff,
    period: period,
    // if period is previous PY use selected period, otherwise use last processed month for current PY
    overridePeriod: selectedPY < currentPY,
  });

  const {
    data: fpTrackingApiResponse,
    isLoading: isFpTrackingQueryLoading,
    isFetching: isFpTrackingQueryFetching,
    isError: isFpTrackingQueryError,
    refetch: fpTrackingRefetch,
  } = useGetFoundersPlatinumTrackingQuery({
    abo: user.abo,
    aff: user.aff,
    period: period,
  });

  const refetch = () => {
    hatRefetch();
    fpTrackingRefetch();
  };

  const [isLoading, setIsLoading] = useState(true);
  const [errorStatus, setErrorStatus] = useState(false);
  const [trackingPinData, setTrackingPinData] = useState<HATSummaryAPIResponse>(defaultTrackingPinData());
  const [fpTrackingData, setFpTrackingData] = useState<FoundersPlatinumTrackingResponse>(defaultFppResponse());

  // if loading or fetching set loading flag to true
  useEffect(() => {
    if (isHatQueryLoading || isHatQueryFetching || isFpTrackingQueryLoading || isFpTrackingQueryFetching) {
      setIsLoading(true);
    }
  }, [isHatQueryLoading, isHatQueryFetching, isFpTrackingQueryLoading, isFpTrackingQueryFetching]);

  useEffect(() => {
    if (isHatQueryError || isFpTrackingQueryError) {
      setErrorStatus(true);
      setIsLoading(false);
    }
  }, [isHatQueryError, isFpTrackingQueryError]);

  // once loading has finished and data is resolved, save to state and set loading to false
  useEffect(() => {
    if (
      !isFpTrackingQueryFetching &&
      !isFpTrackingQueryLoading &&
      !isHatQueryLoading &&
      !isHatQueryFetching &&
      !isFpTrackingQueryError &&
      !isHatQueryError &&
      trackingPinData &&
      fpTrackingApiResponse &&
      hatSummaryData
    ) {
      setTrackingPinData(hatSummaryData);
      setFpTrackingData(fpTrackingApiResponse);
      setIsLoading(false);
      setErrorStatus(false);
    }
  }, [
    trackingPinData,
    fpTrackingApiResponse,
    isHatQueryError,
    isHatQueryLoading,
    isHatQueryFetching,
    hatSummaryData,
    isFpTrackingQueryFetching,
    isFpTrackingQueryLoading,
    isFpTrackingQueryError,
  ]);

  return { isLoading, trackingPinData, fpTrackingData, errorStatus, refetch };
};
