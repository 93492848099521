export interface HatFaaHelperPayload {
  Aff?: number;
  aff?: number;
  ABOName?: string;
  aboName?: string;
  Name?: string;
  ABONameLocalized?: string;
  localizedName?: string;
  NameLocalized?: string;
  ISOLanguageCode?: string;
  isoLngCd?: string;
}

export default class hatFaaHelper {
  _p: HatFaaHelperPayload;
  /**
   * @param {Object} payload
   */
  constructor(payload: HatFaaHelperPayload) {
    this._p = payload || {};
  }

  /**
   * Returns either english or local name based on language code.
   * If the name is blank, we assume this is a confidential user,
   * and we return the confidential user string, if provided.
   * @param {string} language - ISO language code
   * @param {string} confidentialUserString - localized confidential string
   */
  getName(language: string, confidentialUserString: string): string {
    let name = this.englishName;
    if (language === this.isoLanguageCode && this.localName) {
      name = this.localName;
    }
    return name || confidentialUserString || "";
  }

  /**
   * Business affiliate number
   * @return {number}
   */
  get aff(): number | null {
    if (this._p) {
      return this._p.Aff || this._p.aff || null;
    }
    return null;
  }

  /**
   * English name. You should use getName whenever possible.
   * @return {string}
   */
  get englishName(): string | null {
    if (this._p) {
      return this._p.ABOName || this._p.aboName || this._p.Name || null;
    }
    return null;
  }

  /**
   * Local language name. You should use getName whenever possible.
   * @return {string}
   */
  get localName(): string | null {
    if (this._p) {
      return this._p.ABONameLocalized || this._p.localizedName || this._p.NameLocalized || null;
    }
    return null;
  }

  /**
   * ISO language code
   * @return {string}
   */
  get isoLanguageCode(): string | null {
    if (this._p) {
      return this._p.ISOLanguageCode || this._p.isoLngCd || null;
    }
    return null;
  }
}
