import { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { usePDF } from "@react-pdf/renderer";
import defaultContent from "../../../../../content/monthlyPerformanceTotalModals";
import { LeafModal } from "../../../../../common/leaf/LeafModal/LeafModal";
import "../MonthlyPerformanceTotalModals.scss";
import { IconAlert } from "../../../../../common/icons/IconAlert/IconAlert";
import LeafRectangleLoader from "../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import { BonusBreakdownLineData } from "../../../../../common/interfaces/monthlyPerformanceDash";
import { ANA_MARKET_CODES } from "../../../../../common/constants/market-constants";
import { useAppSelector } from "../../../../../store";
import LeafABOName from "../../../../../common/leaf/LeafProfile/LeafABOName/LeafABOName";
import LeafLinkButton from "../../../../../common/leaf/LeafLinkButton/LeafLinkButton";
import { IconExportPdf } from "../../../../../common/icons/IconExportPdf/IconExportPdf";
import DifferentialBonusPDF from "../../../../PDFExport/DifferentialBonusPDF/DifferentialBonusPDF";
import "./DifferentialBonusModal.scss";
import { BonusInfoFooter } from "../../../../../common/components/BonusInfoFooter/BonusInfoFooter";
import {
  BonusPercent,
  DifferentialBonusEntry,
  GroupEntry,
  GroupNonBonusableEntry,
  MonthlyPerformanceResponse,
} from "../../../../../services/MonthlyPerformanceDashboard/monthlyPerformanceDashboardAPI.types";

export type Table = {
  aboBonusPercent: string;
  aboGpv: string;
  personalBV: string;
  bonusPercent: string;
  personalBonusAmount: string;
  nonBonusable: string;
  total: string;
};

export type PerformanceTotal = {
  title: string;
  subTitle: string;
  table: Partial<Table>;
  exportTitle: string;
};

export type BonusData = {
  bonusPercent: BonusPercent;
  differentialBonus: DifferentialBonusEntry[];
};

export const findNonBonusableAmount = (isoCurrencyCode: string, groupNonBonusable: GroupNonBonusableEntry[]) => {
  const nonBonusable = groupNonBonusable?.find(
    (item: { isoCurrencyCode: string }) => isoCurrencyCode === item.isoCurrencyCode,
  );
  return nonBonusable?.groupBV?.formatted || "0.00";
};

const DifferentialBonusModal = ({
  openModal,
  onCloseModal,
  performanceData,
  performanceTotal,
  isFetching,
  isError,
}: {
  openModal: boolean;
  onCloseModal: Function;
  performanceData?: Partial<MonthlyPerformanceResponse>;
  performanceTotal?: Partial<BonusBreakdownLineData>;
  isFetching: boolean;
  isError: boolean;
}) => {
  const { t } = useTranslation("monthlyPerformanceTotalModals");
  const user = useAppSelector((state) => state.boot?.user);
  const anaMarket = ANA_MARKET_CODES.includes(user.aff);
  const { selectedPeriod } = useAppSelector((state) => state.period);

  const [instance, updateInstance] = usePDF();

  const isPDFReady = !instance.error && instance.url && instance.loading === false;

  useEffect(() => {
    if (!isFetching && performanceData && performanceTotal && openModal) {
      updateInstance(
        <DifferentialBonusPDF
          performanceData={performanceData}
          performanceTotal={performanceTotal}
          anaMarket={anaMarket}
          key="DifferentialBonusPDF"
        />,
      );
    }
  }, [isFetching, updateInstance, performanceData, performanceTotal, anaMarket, openModal]);

  const contents = t("differential", {
    returnObjects: true,
    defaultValue: defaultContent.differential,
  }) as PerformanceTotal;

  if (isError) return <></>;

  const renderLoader = (children: React.ReactNode) => (
    <LeafRectangleLoader isLoading={isFetching} height="2rem" width="auto">
      {children}
    </LeafRectangleLoader>
  );

  const downloadOnClick = () => {
    if (!isFetching && isPDFReady && instance.blob) {
      const blob = new Blob([instance.blob], { type: "application/octet-stream" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `Differential_Bonus_${selectedPeriod}.pdf`;
      link.click();
      URL.revokeObjectURL(link.href);
    }
  };

  const RenderDownloadButton = () => {
    if (instance.loading) return <LeafRectangleLoader isLoading={true} width={"8rem"} height={"2rem"} />;

    if (instance.error) return <IconAlert />;

    return (
      <LeafLinkButton
        as="button"
        onClick={downloadOnClick}
        disabled={false}
        label={contents?.exportTitle}
        fontSize="medium"
        fontWeight="bold"
      >
        <div className="differential-bonus-modal__export-title-section">
          <span className="differential-bonus-modal__export-title">{contents?.exportTitle}</span>
          <span>
            <IconExportPdf />
          </span>
        </div>
      </LeafLinkButton>
    );
  };
  return (
    <div className="monthly-bonus-modal">
      <LeafModal open={openModal} onCloseCallbackFn={onCloseModal} id={"monthly-bonus-modal"}>
        <div className="monthly-bonus-modal_bonus-header">
          <h2>{contents?.title}</h2>
          <p>
            <Trans defaults={contents?.subTitle} components={{ 1: <strong /> }} />
          </p>
          {isFetching === false && performanceData?.differentialBonus && (
            <div className="differential-bonus-modal__export-button">
              <RenderDownloadButton />
            </div>
          )}
        </div>
        <div className="monthly-bonus-modal_content">
          <div className="monthly-bonus-modal_bonus-details">
            {renderLoader(
              performanceData?.differentialBonus?.map((item: DifferentialBonusEntry) => (
                <div className="wrapper" key={item.aboName}>
                  <div className="abo">
                    <span>
                      <LeafABOName
                        name={item.aboName}
                        localName={item.localizedName}
                        privacyFlag={item.privacyFlag}
                        languageCode={item.isoLanguageCode}
                      />
                    </span>
                    <div className="abo-details">
                      <span>
                        <Trans
                          defaults={contents?.table?.aboBonusPercent}
                          values={{ aboBonusPercent: item.bonusPercent.formatted }}
                        />
                      </span>
                      <span> | </span>
                      <span>
                        <Trans defaults={contents?.table?.aboGpv} values={{ aboGpv: item.totalGPV.formatted }} />
                      </span>
                    </div>
                  </div>
                  {item.groupBonus?.map((groupItem: GroupEntry, index: number) => (
                    <div key={`${item.aboName}-${groupItem.isoCurrencyCode}-${index}`}>
                      <div className="personal-bv">
                        <span>
                          <Trans
                            defaults={contents?.table?.personalBV}
                            values={{ countryCode: groupItem.isoCountryCode }}
                          />
                        </span>
                        <span>{groupItem.groupBV.formatted}</span>
                      </div>
                      <div className="bonus-percent">
                        <span>
                          <Trans
                            defaults={contents?.table?.bonusPercent}
                            values={{
                              bonusPercent: `${performanceData?.bonusPercent?.formatted} - ${item.bonusPercent?.formatted}`,
                            }}
                          />
                        </span>
                        <span>x {item.differentialPercent?.formatted}</span>
                      </div>
                      <div className="current-total">
                        <span>{contents?.table?.personalBonusAmount}</span>
                        <span>{groupItem.differentialAmount.formatted}</span>
                      </div>
                      {anaMarket && (
                        <div className="non-bonusable-leg">
                          <span>{contents?.table?.nonBonusable}</span>
                          <span className="non-bonusable-leg_content">
                            <span>{findNonBonusableAmount(groupItem.isoCurrencyCode, item.groupNonBonusable)}</span>
                            <IconAlert />
                          </span>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )),
            )}
            {renderLoader(
              <div className="personal-bonus-total">
                <span className="personal-bonus-total_text">{contents?.table?.total}</span>
                <span className="personal-bonus-total_amount">{performanceTotal?.amount}</span>
              </div>,
            )}
          </div>
        </div>
        <div className="monthly-bonus-modal__footer">
          <BonusInfoFooter hideDate={true} />
        </div>
      </LeafModal>
    </div>
  );
};

export default DifferentialBonusModal;
