import {
  CustomerListFilterQueryParams,
  CustomersResponse,
} from "../../../services/Customer/CustomerList/customerListAPI.types";
import { FilterGroup, getFilterQueryParams } from "../filters";

interface CustomerList extends CustomersResponse {
  isFetching?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  refreshFilterTime: number;
  requestId?: string;
}

export const initialState: CustomerList = {
  Aff: 0,
  Abo: 0,
  Period: 0,
  PageNum: 0,
  NextPageNum: 1,
  PageSize: 50,
  PageLimit: 0,
  SumOfTotalOrder: 0,
  TotalCustomerCount: 0,
  SumOfTotalPv: 0,
  CustomerList: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  refreshFilterTime: new Date().getTime(),
  requestId: "",
  TotalGuestTypeCustomer: 0,
  TotalMemberTypeCustomer: 0,
  TotalShareBarTypeCustomer: 0,
  NewCustomerCount: 0,
};

export const resetList = (requestId?: string): CustomerList => ({
  ...initialState,
  requestId,
  refreshFilterTime: new Date().getTime(),
});

export const includeGuestCustomerBN = (enableGuestCustomer: boolean) => {
  return enableGuestCustomer === true ? { businessNature: "3,4,17" } : {};
};

const getDefaultBusinessNatureQueryParam = (
  filterGroups: FilterGroup[],
  enableGuestCustomer: boolean,
): CustomerListFilterQueryParams => {
  const [{ queryParamValue }] = filterGroups.filter(({ group }) => group === "businessNature");
  return queryParamValue === void 0 ? includeGuestCustomerBN(enableGuestCustomer) : {};
};

export const getCustomerFilterQueryParams = (
  filterGroups: FilterGroup[],
  enableGuestCustomer: boolean,
): CustomerListFilterQueryParams => {
  const filterQueryParams = getFilterQueryParams<CustomerListFilterQueryParams>(filterGroups);
  const businessNatureQueryParams = getDefaultBusinessNatureQueryParam(filterGroups, enableGuestCustomer);
  return { ...filterQueryParams, ...businessNatureQueryParams };
};
