import { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import {
  AboTileData,
  BronzeGroupTrackingApiResponse,
  BGTEligibilityDetails,
  Requirement,
  BGTEligibilityWindow,
} from "../../services/BronzeGroupTracking/bronzeGroupTracking.types";
import { openProfileModal } from "../../reducers/BronzeGroupTracking/bronzeGroupTracking";
import { useAppDispatch, useAppSelector } from "../../store";
import LeafTag from "../../common/leaf/LeafTag/LeafTag";
import { BRONZE_TAGS } from "../../reducers/model/bgtFilters";
import defaultContent from "../../content/bronzeGroupTracking";
import { isProgramActiveBulkCheck } from "../../common/util/checkProgramActiveStatus";
import { AWARD_CODES } from "../../common/enums/award-codes";
import { bgtOpenMiniProfileClickActionTrack } from "../../common/tealium/BronzeGroupTracking/BronzeGroupTracking";
import { BGT_TABS } from "../../common/enums/bronze-group-tracking-enums";
import defaultUserContent from "../../content/leafAboName";
import { storedLocale } from "../impersonation/util";
import { checkUpperCaseEqual } from "../../common/util/common";
import {
  PCPV,
  PERFORMANCE_BONUS,
  PPV,
  PAPV,
  THREE_LEGS_3_PERCENT,
  THREE_LEGS_6_PERCENT,
  TWO_LEGS_3_PERCENT_WITH_VCS,
  TWO_LEGS_6_PERCENT_WITH_VCS,
  VCS_PERCENT,
  VCS_PV,
} from "../../common/constants/requirements-constants";

export const isReq = (requirements: Requirement, req: { requirement: string; requirementType: string }) => {
  return (
    checkUpperCaseEqual(requirements.requirement, req.requirement) &&
    checkUpperCaseEqual(requirements.requirementType, req.requirementType)
  );
};

export const returnDefaultBronzeTrackingData = (): BronzeGroupTrackingApiResponse => {
  return {
    requirementFilters: [],
    restartIsActive: false,
    awardProgress: {
      trackingTilesData: [],
      graduatedTilesData: [],
    },
    overviewKpis: {
      selectedPeriod: {
        closeCount: 0,
        newCount: 0,
        inWindowCount: 0,
        graduatedCount: 0,
      },
      previousPeriod: {
        closeCount: 0,
        newCount: 0,
        inWindowCount: 0,
        graduatedCount: 0,
      },
    },
    errors: undefined,
  };
};

interface BGTListItemProps extends React.HTMLAttributes<HTMLLIElement> {
  children: ReactNode;
  aboTileData: AboTileData;
  key: number;
}

export const BGTListItem = (props: BGTListItemProps) => {
  const { children, aboTileData, ...htmlAttributes } = props;
  const dispatch = useAppDispatch();
  const displayProfileModal = () => {
    dispatch(openProfileModal(aboTileData));
  };

  const { activeView } = useAppSelector((state) => state.bronzeGroupTrackingTab);

  const handleRowClicked = () => {
    displayProfileModal();
    bgtOpenMiniProfileClickActionTrack(activeView);
  };

  const handleRowEnter = (e: React.KeyboardEvent<HTMLLIElement>) => {
    if (e.key === "Enter") {
      displayProfileModal();
    }
  };

  return (
    <li tabIndex={0} onClick={handleRowClicked} onKeyDown={handleRowEnter} {...htmlAttributes}>
      {children}
    </li>
  );
};

const LeafTagColor = (tag: string) => {
  switch (tag) {
    case BRONZE_TAGS.CLOSE:
      return "lightBlue";
    case BRONZE_TAGS.NEW:
      return "medOrange";
    case BRONZE_TAGS.ACHIEVED:
      return "medGreen";
    case BRONZE_TAGS.IN_WINDOW:
      return "medRed";
    default:
      return "medOrange";
  }
};

export const BGTTags = (tag: string, restart = false, specialHide = false) => {
  const { t } = useTranslation(["bronzeGroupTracking"]);

  if (restart) {
    return (
      <LeafTag key={tag} variant="lightYellow" hideTag={!restart || specialHide}>{`${t(
        `restarted`,
        defaultContent["restarted"],
      )}`}</LeafTag>
    );
  }

  const tagColor = LeafTagColor(tag);
  return (
    tag.length > 0 && (
      <LeafTag key={tag} hideTag={!tag || specialHide} variant={tagColor}>{`${t(
        `${tag}`,
        defaultContent[tag],
      )}`}</LeafTag>
    )
  );
};

export const useBronzeIncentiveStatus = () => {
  const [isBronzeFoundationActive, setIsBronzeFoundationActive] = useState<boolean>(false);
  const [isBronzeBuilderActive, setIsBronzeBuilderActive] = useState<boolean>(false);
  const { eligibleIncentives } = useAppSelector((state) => state.boot.shellConfig);

  useEffect(() => {
    const bronzeFoundation = isProgramActiveBulkCheck(eligibleIncentives, [
      AWARD_CODES.BRONZE_FOUNDATION,
      AWARD_CODES.MN_BRONZE_FOUNDATION,
      AWARD_CODES.RESTART_BRONZE_FOUNDATION,
      AWARD_CODES.RESTART_TWO_BRONZE_FOUNDATION,
    ]);
    const bronzeBuilder = isProgramActiveBulkCheck(eligibleIncentives, [
      AWARD_CODES.BRONZE_BUILDER,
      AWARD_CODES.MN_BRONZE_BUILDER,
      AWARD_CODES.RESTART_BRONZE_BUILDER,
      AWARD_CODES.RESTART_TWO_BRONZE_BUILDER,
    ]);

    setIsBronzeFoundationActive(bronzeFoundation);
    setIsBronzeBuilderActive(bronzeBuilder);
  }, [eligibleIncentives]);

  return { isBronzeFoundationActive, isBronzeBuilderActive };
};

export const returnDefaultBgtMiniProfileData = () => {
  return {
    affAbo: "",
    bronzeFoundation: {
      firstTime: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
        showEligibility: false,
        closeToGraduating: false,
      },
      restartOne: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
        showEligibility: false,
        closeToGraduating: false,
      },
      restartTwo: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
        showEligibility: false,
        closeToGraduating: false,
      },
    },
    bronzeBuilder: {
      firstTime: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
        showEligibility: false,
        closeToGraduating: false,
      },
      restartOne: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
        showEligibility: false,
        closeToGraduating: false,
      },
      restartTwo: {
        graduationDate: null,
        eligibilityWindow: {
          startPeriod: 0,
          endPeriod: 0,
        },
        opportunitiesRemaining: 0,
        achievedMonths: 0,
        missedMonths: 0,
        showEligibility: false,
        closeToGraduating: false,
      },
    },
    CallResults: null,
    Error: null,
  };
};

export const getLatestGradDate = (dataSets: BGTEligibilityDetails[], selectedTab: string) => {
  if (selectedTab === BGT_TABS.TRACKING_TAB || !dataSets) return "";
  const gradDates: number[] = [];
  dataSets.forEach((dataSet) => {
    if (dataSet.graduationDate) gradDates.push(dataSet.graduationDate);
  });

  return gradDates.length ? Math.max(...gradDates).toString() : "";
};

export const GetABODisplayName = ({
  aboName,
  aboLocalName,
  aboIsoLanguage,
}: {
  aboName: string;
  aboLocalName: string;
  aboIsoLanguage: string;
}) => {
  const { t } = useTranslation(["leafAboName"]);
  const locale = storedLocale();
  const confidentialUser = t("ConfidentialUser", defaultUserContent["ConfidentialUser"]);
  const language = locale.slice(0, 2);
  const preferredName = aboLocalName && aboIsoLanguage === language ? aboLocalName : aboName;
  const displayName = preferredName && preferredName.length > 0 ? preferredName : confidentialUser;

  return <>{displayName}</>;
};

export const resetEligMonths = (eligGroup: BGTEligibilityDetails): BGTEligibilityDetails => {
  return {
    ...eligGroup,
    opportunitiesRemaining:
      eligGroup && eligGroup.opportunitiesRemaining + eligGroup.achievedMonths + eligGroup.missedMonths,
    achievedMonths: 0,
    missedMonths: 0,
  };
};

export const IncentiveTag = ({ incentiveTag }: { incentiveTag: string }) => (
  <LeafTag hideTag={!incentiveTag} variant={incentiveTag === BRONZE_TAGS.BRONZE_BUILDER ? "darkRed" : "medRed"}>{`${t(
    `${incentiveTag}`,
    defaultContent[incentiveTag],
    { ns: "bronzeGroupTracking" },
  )}`}</LeafTag>
);

export const getReqTitle = (requirement: Requirement) => {
  switch (true) {
    case isReq(requirement, PERFORMANCE_BONUS):
      return `${requirement.target}% ${t("performanceBonus", defaultContent["performanceBonus"], {
        ns: "bronzeGroupTracking",
      })}`;
    case isReq(requirement, PPV):
      return `${t("ppv", defaultContent["ppv"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, PAPV):
      return `${t("papv", defaultContent["papv"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, PCPV):
      return `${t("pcpv", defaultContent["pcpv"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, THREE_LEGS_6_PERCENT):
      return `${t("threeLegs6", defaultContent["threeLegs6"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, TWO_LEGS_6_PERCENT_WITH_VCS):
      return `${t("twoLegs6", defaultContent["twoLegs6"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, TWO_LEGS_3_PERCENT_WITH_VCS):
      return `${t("twoLegs3", defaultContent["twoLegs3"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, THREE_LEGS_3_PERCENT):
      return `${t("threeLegs3", defaultContent["threeLegs3"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, VCS_PV):
      return `${t("vcsPv", defaultContent["vcsPv"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, VCS_PERCENT):
      return `${t("vcs", defaultContent["vcs"], { ns: "bronzeGroupTracking" })}`;
    default:
      return "";
  }
};

export const getReqShortTitle = (requirement: Requirement) => {
  switch (true) {
    case isReq(requirement, PERFORMANCE_BONUS):
      return `${requirement.target}%`;
    case isReq(requirement, PPV):
      return `${t("ppvShort", defaultContent["ppvShort"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, PAPV):
      return `${t("papvShort", defaultContent["papvShort"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, PCPV):
      return `${t("pcpv", defaultContent["pcpv"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, THREE_LEGS_6_PERCENT):
    case isReq(requirement, THREE_LEGS_3_PERCENT):
      return `${t("legsShort", defaultContent["legsShort"], { numLegs: 3, ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, TWO_LEGS_6_PERCENT_WITH_VCS):
    case isReq(requirement, TWO_LEGS_3_PERCENT_WITH_VCS):
      return `${t("vcsLegsShort", defaultContent["vcsLegsShort"], { numLegs: 2, ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, VCS_PV):
      return `${t("vcsPv", defaultContent["vcsPv"], { ns: "bronzeGroupTracking" })}`;
    case isReq(requirement, VCS_PERCENT):
      return `${t("vcs", defaultContent["vcs"], { ns: "bronzeGroupTracking" })}`;
    default:
      return "";
  }
};

const getEligGroup = (eligGroup: BGTEligibilityDetails, eligGroupName: string) => {
  return {
    ...eligGroup,
    [eligGroupName]: resetEligMonths(eligGroup),
  };
};

export const getEligibilityData = (
  selectedIncentive: BGTEligibilityWindow,
  eligibilityTag: string,
): BGTEligibilityWindow => {
  const { firstTime, restartOne, restartTwo } = selectedIncentive;
  if (eligibilityTag === BRONZE_TAGS.PRE_WINDOW && (firstTime || restartOne || restartTwo) && eligibilityTag) {
    let updatedEligData;
    if (restartOne.showEligibility) {
      updatedEligData = getEligGroup(selectedIncentive.restartOne!, "restartOne");
    } else if (restartTwo.showEligibility) {
      updatedEligData = getEligGroup(selectedIncentive.restartTwo!, "restartTwo");
    } else {
      updatedEligData = getEligGroup(selectedIncentive.firstTime!, "firstTime");
    }

    return {
      ...selectedIncentive,
      ...updatedEligData,
    };
  }
  return selectedIncentive;
};
