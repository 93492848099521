import "./IconArrowUp.scss";

type Props = {
  backgroundClass: "blue" | "white" | "black";
  size?: "sm" | "md" | "lg";
  thickness?: "thick" | "";
};

export const IconArrowUp = ({ backgroundClass = "black", size = "sm", thickness = "" }: Props) => {
  return (
    <span
      className={`icon-arrow-up icon-arrow-up--${backgroundClass} icon-arrow-up--${thickness} icon-arrow-up--${size}`}
    ></span>
  );
};
