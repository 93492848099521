import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import "./MBI.scss";
import { useTranslation } from "react-i18next";
import { PageTitleSection } from "../../common/components/PageTitleSection/PageTitleSection";
import defaultContent from "../../content/myBusinessIncome";
import MBILoader from "./MBILoader/MBILoader";

const MBI = () => {
  const { t } = useTranslation(["myBusinessIncome"]);
  return (
    <main className="mbi">
      <div className="mbi__header-container">
        <PageTitleSection title={`${t("myBusinessIncome", defaultContent["myBusinessIncome"])}`} />
      </div>
      <Suspense fallback={<MBILoader />}>
        <Outlet />
      </Suspense>
    </main>
  );
};

export default MBI;
