import { ABOAWARD, AFFISO, ISOAFF, ACCOUNTPERFORMANCEAWARD } from "../enums/index";
import InodeConstructor from "../interfaces/models/losInode";
import { RawInode, AboPvBvVolumeTypes } from "../interfaces/api/los";

/**
 * The LOS Inode is an ABO record for use within tree-based representations of
 * downline structure. It's inode property is contains a parent maps and allows
 * travel
 * @export
 * @class LosInode
 */
export default class LosInode {
  aff: string;
  abo: number;
  affAbo: string;
  individualPartyName: string;
  awards: {
    /**
     * Award rank as of currentQualPeriod; use with enum/aboAwardLevels
     * ABOAWARD_TOSTR to cross ref.
     *
     * ABOAWARD.ASSOCIATE is not a real return; it is a workaround for empty
     * awards data and is considered "member" or "associate" based on regional
     * case
     */
    currentAwardRank: number;
    /**
     * Period of current award rank attainment, YYYYMM format
     */
    currentQualPeriod: number;
    /**
     * Historical highest award rank of ABO; use with enum/aboAwardLevels
     * ABOAWARD_TOSTR to cross ref
     */
    highestAwardRank: number;
    /**
     * Period of highest award rank attainment, YYYYMM format
     */
    highestQualPeriod: number;
  };
  codes: {
    /**
     * Used to determine market status
     */
    losType: string | null;
    /**
     * Business nature of abo, cross ref with enum/businessNatures
     * BUSINESSNATURE
     */
    nature: number;
    segment: number;
    /**
     * Used to determine abo inactive or early stage
     */
    status: number;
    nameTypeFlag: string | undefined;
  };
  dates: {
    renewalDate: string;
  };
  profile: {
    country: string;
    localName: string;
    name: string;
    preferredLanguageCode: string;
  };
  sponsoring: {
    abo: number;
    groupCount: number;
    upline: { intlSponAbo: number; intlSponAff: number };
  };
  sponsor: {
    affNo: string;
    aboNo: string;
    name: string;
    localName: string;
    privacyFlag: boolean;
  };
  volumes: {
    pvbv: AboPvBvVolumeTypes[];
    percentages: {
      bonusPercent: number;
      leadershipPercent: number;
      personalVcsPercent: number;
      platinumVcsPercent: number;
      percentToNextLevel: number;
    };
  };
  __internal__: {
    /**
     * this holder the api response so we can make a determination if we have
     * shadowChildren we don't get back from the API (but may need to fetch
     * later). The API return by itself is not enough to determine this
     */
    hasDownlines: boolean;
    /**
     * how deep within the inode tree our parent is; used for fast calc
     * without determining in array
     */
    parentDepth: number;
    downlines: RawInode[];
  };
  flags: {
    /**
     * Whether this is ABO in considered international, most often used for
     * filtering; always opposite of isInMarket
     */
    isInternational: boolean;
    /**
     * Whether this is ABO in considered international, most often used for
     * filtering; always opposite of isInternational
     */
    isInMarket: boolean;
    /**
     * Whether this is ABO has a confidential flag set, most commonly used in
     * Japan
     */
    isPrivate: boolean;
    isInactive: boolean;
    /**
     * Terrible case check for if they have PV for filtering; expensive on the
     * API call to gather this and API data prone to strange errors
     */
    isVolumeForPvZero: boolean;
    /**
     * Check if currentAwardRank is above ABOAWARD.PLATINUM
     */
    isCurrentAwardRankPlatinumOrAbove: boolean;
    /**
     * Check if currentAwardRank is an associate below silver
     */
    isCurrentAwardRankAssociateBelowSilver: boolean;
    /**
     * Whether this is ABO in considered international && has zero volume
     * most often used for filtering
     */
    isInternationalVolumeForPvZero: boolean;

    isFirstContractYear: boolean;
  };
  inode: {
    /**
     * Verifies the internal data of the inode to determine if we should allow
     * parse/expansion of the inode.children
     */
    canExpand: boolean;
    /**
     * Whether the inode children have been expanded, most notably within the
     * UI. You should be wary of this flag and mind your memory management, as
     * you will end up with counterproductive behavior if you ref this poorly
     */
    isExpanded: boolean;
    /**
     * @type {LosInode[]} Downline ABOs and their inode.children
     */
    children: LosInode[];
    /**
     * We can't always rely on that fact that map actually returns, as as
     * such, if this flag is set, you should patch your inode.downlines for
     * this node
     */
    shadowChildren: boolean;
    /**
     * The depth of the this ABO within a given inode tree
     */
    depth: number;
    /**
     * single based index of ABO level for use within the UI
     */
    level: number;
    /**
     * The affAbo of the parent; can be the ABO itself if they have no parent
     * (the root)
     */
    parent: string;
    /**
     * An in-order map of the the ABO's tree linage
     */
    parentMap: Set<unknown>;
  };
  /**
   * @param {object} args
   * @param {object} args.data Whatever the terrible api gives us to work with
   * @param {string} args.parentAffAbo
   * @param {number} args.parentDepth
   * @param {string[]} args.parentMap
   */
  constructor(args: InodeConstructor) {
    /**
     * The market aff for a given ABO
     */
    this.aff = "";

    /**
     * The ABO id number
     */
    this.abo = 0;

    /**
     * The combined market/abo number, most often used for API calls and cross
     * referencing sets
     */
    this.affAbo = "";

    this.individualPartyName = "";

    /**
     * List of ABO award information
     */
    this.awards = {
      /**
       * Award rank as of currentQualPeriod; use with enum/aboAwardLevels
       * ABOAWARD_TOSTR to cross ref.
       */
      currentAwardRank: ABOAWARD.ASSOCIATE,
      /**
       * Period of current award rank attainment, YYYYMM format
       */
      currentQualPeriod: 202001,
      /**
       * Historical highest award rank of ABO; use with enum/aboAwardLevels
       * ABOAWARD_TOSTR to cross ref
       */
      highestAwardRank: ABOAWARD.ASSOCIATE,
      /**
       * Period of highest award rank attainment, YYYYMM format
       */
      highestQualPeriod: 202001,
    };

    /**
     * Various coding types for the ABO, used for primary for internal flag
     * checks but can be used for UI checking if required
     */
    this.codes = {
      /**
       * Used to determine market status
       */
      losType: "",
      /**
       * Business nature of abo, cross ref with enum/businessNatures
       * BUSINESSNATURE
       */
      nature: 0,
      segment: 0,
      /**
       * Used to determine abo inactive or early stage
       */
      status: 0,
      nameTypeFlag: undefined,
    };

    this.dates = {
      renewalDate: "",
    };

    /**
     * Basic profile information; use MDMS for better profile information
     */
    this.profile = {
      country: "US",
      localName: "",
      name: "",
      preferredLanguageCode: "en",
    };

    /**
     * Basic sponsoring information; use MDMS for better profile information
     */
    this.sponsoring = {
      abo: 0,
      groupCount: 0,
      upline: {
        intlSponAbo: 0,
        intlSponAff: 0,
      },
    };

    this.sponsor = {
      affNo: "",
      aboNo: "",
      name: "",
      localName: "",
      privacyFlag: false,
    };

    /**
     * In a better case, we would _never_ need this, but because of the
     * requirement for zero volume filtering, we have no choice but to ask for
     * this information
     */
    this.volumes = {
      pvbv: [],
      percentages: {
        bonusPercent: 0,
        leadershipPercent: 0,
        personalVcsPercent: 0,
        platinumVcsPercent: 0,
        percentToNextLevel: 0,
      },
    };

    /**
     * Internal holder for some bit checks that really don't belong in the
     * general use category
     */
    this.__internal__ = {
      /**
       * this holder the api response so we can make a determination if we have
       * shadowChildren we don't get back from the API (but may need to fetch
       * later). The API return by itself is not enough to determine this
       */
      hasDownlines: false,
      /**
       * how deep within the inode tree our parent is; used for fast calc
       * without determining in array
       */
      parentDepth: 0,
      downlines: [],
    };

    /**
     * Most commonly used flags for UI flip cases, encapsulated logic for easy
     * to use getters
     */
    this.flags = {
      /**
       * Whether this is ABO in considered international, most often used for
       * filtering; always opposite of isInMarket
       */
      isInternational: false,
      /**
       * Whether this is ABO in considered international, most often used for
       * filtering; always opposite of isInternational
       */
      isInMarket: false,
      /**
       * Whether this is ABO has a confidential flag set, most commonly used in
       * Japan
       */
      isPrivate: false,
      isInactive: false,
      /**
       * Terrible case check for if they have PV for filtering; expensive on the
       * API call to gather this and API data prone to strange errors
       */
      isVolumeForPvZero: false,
      /**
       * Check if currentAwardRank is above ABOAWARD.PLATINUM
       */
      isCurrentAwardRankPlatinumOrAbove: false,
      /**
       * Check if currentAwardRank is an associate below silver
       */
      isCurrentAwardRankAssociateBelowSilver: false,
      /**
       * Whether this is ABO in considered international && has zero volume
       * most often used for filtering
       */
      isInternationalVolumeForPvZero: false,

      isFirstContractYear: false,
    };

    this.inode = {
      /**
       * Verifies the internal data of the inode to determine if we should allow
       * parse/expansion of the inode.children
       */
      canExpand: false,
      /**
       * Whether the inode children have been expanded, most notably within the
       * UI. You should be wary of this flag and mind your memory management, as
       * you will end up with counterproductive behavior if you ref this poorly
       */
      isExpanded: false,
      /**
       * @type {LosInode[]} Downline ABOs and their inode.children
       */
      children: [],
      /**
       * We can't always rely on that fact that map actually returns, as as
       * such, if this flag is set, you should patch your inode.downlines for
       * this node
       */
      shadowChildren: false,
      /**
       * The depth of the this ABO within a given inode tree
       */
      depth: 0,
      /**
       * single based index of ABO level for use within the UI
       */
      level: 0,
      /**
       * The affAbo of the parent; can be the ABO itself if they have no parent
       * (the root)
       */
      parent: "",
      /**
       * An in-order map of the the ABO's tree linage
       */
      parentMap: new Set(),
    };

    // Are we starting up empty?
    if (args && args.data && Object.keys(args.data).length !== 0) {
      this.initialize({
        data: args.data,
        parentAffAbo: args.parentAffAbo || "",
        parentDepth: args.parentDepth || 0,
        parentMap: args.parentMap || [],
      });
    }
  }

  /**
   * @description
   * @param {object} args
   * @param {object} args.data
   * @param {string} args.parentAffAbo
   * @param {number} args.parentDepth
   * @param {string[]} args.parentMap
   */
  initialize({ data, parentAffAbo, parentDepth = 0, parentMap = [] }: InodeConstructor): void {
    this.aff = data.aff.toString().padStart(3, "0");
    this.abo = Number(data.abo);
    this.affAbo = `${this.aff}-${this.abo}`;

    /**
     * Used for internals, not for public use
     */
    this.__internal__ = {
      hasDownlines: data?.hasDownlines ? Boolean(data.hasDownlines) : false,
      parentDepth,
      downlines: data.downlines || [],
    };

    this.individualPartyName = data?.individualPartyName || "";

    this.profile = {
      name: data.aboAttributes.aboName.slice(0),
      localName: data?.aboAttributes?.aboLocalName ? data.aboAttributes.aboLocalName.slice(0) : "",
      preferredLanguageCode: data?.aboAttributes?.isoLanguage ? data.aboAttributes.isoLanguage.slice(0) : "",
      country: data?.aboAttributes?.isoCountry ? data.aboAttributes.isoCountry.slice(0) : AFFISO[this.aff],
    };

    this.codes = {
      status: parseInt(data.status.slice(0), 10),
      segment: parseInt(data.segmentCode.slice(0), 10),
      nature: parseInt(data.businessNature.slice(0), 10),
      losType: data.losType.slice(0) || data.intSponsFlag.slice(0) || null,
      nameTypeFlag: data.nameTypeFlag,
    };

    this.sponsoring = {
      abo: Number(data.sponsorAbo),
      ...data.sponsoringStats,
      upline: {
        ...data.uplineInfo,
      },
    };

    if (data.sponsor) {
      this.sponsor = data.sponsor;
    }

    this.volumes = {
      pvbv: [...(data?.volumeDetails?.aboPvBv?.volumeTypesPVBVDataList || [])],
      percentages: {
        bonusPercent: data.volumeDetails?.bonusPercent || 0,
        leadershipPercent: data.volumeDetails?.leadershipPercent || 0,
        personalVcsPercent: data.volumeDetails?.personalVcsPercent || 0,
        platinumVcsPercent: data.volumeDetails?.platinumVcsPercent || 0,
        percentToNextLevel: data.volumeDetails?.percentToNextLevel || 0,
      },
    };

    this.awards = {
      ...this.awards,
      ...data.awardLevelDetail,
    };

    const hasAwardsData = data?.awardLevelDetail;
    if (!hasAwardsData) {
      this.awards.currentAwardRank = ABOAWARD.NOT_AVAILABLE;
    }

    // Why do the defs this way? Because it's hold over
    this.flags.isInternational = this.__isInternational;
    this.flags.isInMarket = this.__isInMarket;
    this.flags.isPrivate = data?.aboAttributes?.privacyFlag ? Boolean(data.aboAttributes.privacyFlag) : false;
    this.flags.isInactive = this.__isInactive;
    this.flags.isVolumeForPvZero = this.__isVolumeForPvZero;
    this.flags.isCurrentAwardRankPlatinumOrAbove = this.__isCurrentAwardRankPlatinumOrAbove;
    this.flags.isCurrentAwardRankAssociateBelowSilver = this.__isCurrentAwardRankAssociateBelowSilver;
    this.flags.isInternationalVolumeForPvZero = this.__isInternationalVolumeForPvZero;

    this.inode.children = [];
    this.inode.shadowChildren = this.__shadowChildren;
    this.inode.parent = parentAffAbo || this.affAbo;
    this.inode.parentMap = new Set([...parentMap]);
    this.inode.depth = this.__getInodeDepth;
    this.inode.level = this.__getInodeLevel;
    this.inode.canExpand = this.__canExpand;
  }

  /**
   * Verify if the ABO is above the Platinum Award level. Used primary for
   * string restriction within the UI
   * @return {Boolean}
   */
  private get __isCurrentAwardRankPlatinumOrAbove(): boolean {
    // do not be fooled by the ASSOCIATE case: because we have high number special cases
    // because we can't guarantee non-collision (since we have no means to
    // verify on the DB side) we have to put a secondary check otherwise
    return this.awards.currentAwardRank >= ABOAWARD.PLATINUM && this.awards.currentAwardRank < ABOAWARD.ASSOCIATE;
  }

  /**
   * A common flag check for award status label making
   * @readonly
   */
  private get __isCurrentAwardRankAssociateBelowSilver(): boolean {
    if (
      (this.awards.currentAwardRank >= ABOAWARD.NOT_TRACKING &&
        this.awards.currentAwardRank < ABOAWARD.SILVER_SPONSOR) ||
      this.awards.currentAwardRank === ABOAWARD.NOT_AVAILABLE
    ) {
      return true;
    }
    return false;
  }

  /**
   * A common flag check for award status label making
   * @readonly
   */
  private get __isInternationalVolumeForPvZero(): boolean {
    return this.__isInternational && this.__isVolumeForPvZero;
  }

  /**
   * Is the ABO an international
   * @readonly
   */
  private get __isInternational(): boolean {
    return Boolean(this.codes.losType === "B" || this.codes.losType === "G");
  }

  /**
   * Is this ABO in market (within their AFF). This check is slightly broken
   * because of the lack of Foster Sponsored information available within the
   * API
   * @readonly
   */
  private get __isInMarket(): boolean {
    return !this.__isInternational;
  }

  /**
   * Determine if based on status codes where the ABO is inactive
   * @readonly
   */
  private get __isInactive(): boolean {
    return this.codes.status === 2;
  }

  /**
   * A series of checks to determine if the GPV is zero. This is expensive on
   * the caller API and is a terrible solution in practice. They lack
   * imaginative ability, let's just leave it at that.
   * @readonly
   */
  private get __isVolumeForPvZero(): boolean {
    // sigh, because sometimes the data doesn't actually come back
    if (this.volumes.pvbv.length === 0) {
      return true;
    }
    // if volumeTypeCode doesn't have 005 we need to return true
    if (this.volumes.pvbv.some((i) => i.volumeTypeCode !== ACCOUNTPERFORMANCEAWARD.GROUP)) {
      return true;
    }
    // if volumeTypeCode is 005 and pv is 0 we need to return true
    if (this.volumes.pvbv.some((i) => i.volumeTypeCode === ACCOUNTPERFORMANCEAWARD.GROUP && i.pv === 0)) {
      return true;
    }
    return false;
  }

  /**
   * Due to the full map actually _not_ giving us a full map, we have to do some
   * random checking to determine if we need to call the API again to get it's
   * children. &^() this API
   * @readonly
   */
  private get __shadowChildren(): boolean {
    return (
      this.__internal__.downlines.length === 0 &&
      this.__internal__.hasDownlines &&
      this.profile.country !== AFFISO[ISOAFF.CN]
    );
  }

  /**
   * Determine the expand behavior within the inode. This is what happens when
   * you have bad APIs that apparently can't do _anything_ worth a damn
   * Using this.__internal__.downlines.length instead of inode.children.length
   * because of when inode.children is mapped within recursiveInodeWalker
   * @readonly
   */
  private get __canExpand(): boolean {
    const ruleNoCnBusinesses = this.profile.country !== AFFISO[ISOAFF.CN];
    const ruleHasGroupGtZero = this.sponsoring.groupCount > 0;
    const ruleCannotByInactive = !this.flags.isInactive;
    const ruleBusinessUnit =
      this.awards.currentAwardRank < ABOAWARD.PLATINUM ||
      (this.codes.losType === "G" && this.inode.level === 2) ||
      this.awards.currentAwardRank === ABOAWARD.NOT_AVAILABLE;
    const ruleHasDownlines = this.inode.shadowChildren || this.__internal__.downlines.length >= 1;

    // There is a case where there are no downlines...yet there are children. So
    // this absolute ^&*() case has to be applied
    const ruleEdgeCase01 = ruleHasGroupGtZero || ruleHasDownlines;

    return ruleNoCnBusinesses && ruleCannotByInactive && ruleBusinessUnit && ruleEdgeCase01;
  }

  /**
   * Determine the inode depth based on the tree generation
   */
  private get __getInodeDepth(): number {
    return this.affAbo === this.inode.parent ? this.__internal__.parentDepth : this.__internal__.parentDepth + 1;
  }

  /**
   * Because the API doesn't actually determine this, because it's junk
   */
  private get __getInodeLevel(): number {
    return this.inode.depth + 1;
  }
}
