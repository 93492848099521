import hatFaaHelper, { HatFaaHelperPayload } from "./hatFaaHelper";

export interface GarGeneratorPayload extends HatFaaHelperPayload {
  abo: number;
  isoCountry: string;
  q6LegCount: number;
}

export default class GarGenerator extends hatFaaHelper {
  _payload: GarGeneratorPayload;
  constructor(payload: GarGeneratorPayload) {
    super(payload);
    this._payload = payload;
  }

  /**
   * Generator abo number
   * @return {number}
   */
  get abo(): number {
    return this._payload.abo;
  }

  /**
   * ISO country code
   * @return {string}
   */
  get isoCountryCode(): string {
    return this._payload.isoCountry;
  }

  /**
   * Leg Count
   * @return {number}
   */
  get legCount(): number {
    return this._payload.q6LegCount;
  }
}
