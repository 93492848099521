import { AWARD_CODES } from "../../enums/award-codes";
import { DROPDOWN_LABELS, DROPDOWN_KEYS, ROUTE_LABELS, ROUTE_PATHS } from "../../enums/routing-enums";
import { NavData, NavDropdown } from "../../interfaces/routing";
import { filterDropdown } from "./createMenuConfig";

export const createPerformanceDropdown = (navConfigs: NavData[]): NavDropdown => {
  const performanceDropdown = {
    label: DROPDOWN_LABELS.PERFORMANCE,
    key: DROPDOWN_KEYS.PERFORMANCE,
    items: [
      {
        label: ROUTE_LABELS.PERFORMANCE_DASHBOARD,
        path: ROUTE_PATHS.PERFORMANCE_DASHBOARD,
        class: ROUTE_PATHS.PERFORMANCE_DASHBOARD,
        code: AWARD_CODES.MONTHLY_PERFORMANCE,
        navPosition: 0,
      },
      {
        label: ROUTE_LABELS.EARNINGS_CALCULATOR,
        path: ROUTE_PATHS.EARNINGS_CALCULATOR,
        class: ROUTE_PATHS.EARNINGS_CALCULATOR,
        code: AWARD_CODES.EARNINGS_CALCULATOR,
        navPosition: 3,
      },
      {
        label: ROUTE_LABELS.MBI,
        path: ROUTE_PATHS.MBIMonthlyBonuses,
        class: ROUTE_PATHS.MBI,
        code: AWARD_CODES.MBI,
        navPosition: 4,
      },
    ],
  };

  return filterDropdown(navConfigs, performanceDropdown);
};
