import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ImpersonationDetails, ImpersonationType } from "../../../common/interfaces/impersonation";
import { useAppSelector } from "../../../store";
import { setLocalStorageItem } from "../../../reducers/loginLocalStorage";
import { getDeviceType } from "../impersonation";
import { ImpersonationTable } from "./impersonationTable/ImpersonationTable";

const parseJson = (val: any) => {
  return JSON.parse(JSON.stringify(val));
};

export const updateImpersonationHistory = (
  impersonationHistory: ImpersonationDetails[],
  currentEntry: ImpersonationDetails,
): ImpersonationDetails[] => {
  const history: ImpersonationDetails[] = parseJson(impersonationHistory);
  const index = history.findIndex(({ abo, country, impersonationType }: ImpersonationDetails) => {
    return (
      abo === currentEntry.abo &&
      country === currentEntry.country &&
      impersonationType === currentEntry.impersonationType
    );
  });

  if (index > -1) {
    history.splice(index, 1);
  }
  history.unshift(currentEntry);

  return history;
};

export const ImpersonationHistory = (props: any) => {
  const { impersonationAdded, impersonationAuthCallback, amwayPlusImpersonationCallback } = props;
  const { impersonationHistory } = useAppSelector((state) => state.loginLocalStorage);

  const dispatch = useDispatch();
  const isObjEmpty = (obj: ImpersonationDetails) => Object.keys(obj).length === 0;

  const updateRecentlyUsedWithNewABO = (userEntry: ImpersonationDetails) => {
    const history = updateImpersonationHistory(impersonationHistory, userEntry);
    dispatch(setLocalStorageItem({ impersonationHistory: history }));
  };

  const impersonateWithSelectedABO = (userEntry: ImpersonationDetails) => {
    updateRecentlyUsedWithNewABO(userEntry);
    impersonationAuthCallback(userEntry);
  };

  const impersonateWithAmywayPlus = (userEntry: ImpersonationDetails) => {
    const userEntryWithCurrentDeviceType: ImpersonationDetails = {
      ...userEntry,
      deviceType: getDeviceType(),
    };
    updateRecentlyUsedWithNewABO(userEntryWithCurrentDeviceType);
    amwayPlusImpersonationCallback(userEntryWithCurrentDeviceType);
  };

  const remove = (item: ImpersonationDetails) => {
    let history: ImpersonationDetails[] = parseJson(impersonationHistory);
    history = history.filter(
      (i: ImpersonationDetails) =>
        !(i.abo === item.abo && i.country === item.country && i.impersonationType === item.impersonationType),
    );
    dispatch(setLocalStorageItem({ impersonationHistory: history }));
  };

  useEffect(() => {
    const add = (item: ImpersonationDetails) => {
      const history = parseJson(impersonationHistory);
      history.unshift(item);
      dispatch(setLocalStorageItem({ impersonationHistory: history }));
    };

    const findABO = (abo: string, country: string, impersonationType: ImpersonationType) => {
      return parseJson(impersonationHistory).filter(
        (el: ImpersonationDetails) =>
          el.abo === abo && el.country === country && el.impersonationType === impersonationType,
      )[0];
    };

    const editDuplicate = (item: ImpersonationDetails) => {
      const historyObj = parseJson(impersonationHistory);
      historyObj.forEach((obj: ImpersonationDetails) => {
        if (obj.abo === item.abo && obj.country === item.country) {
          obj.note = item.note;
          obj.partyId = item.partyId;
        }
      });

      dispatch(setLocalStorageItem({ impersonationHistory: historyObj }));
    };

    if (!isObjEmpty(impersonationAdded)) {
      const duplicateABO = findABO(
        impersonationAdded.abo,
        impersonationAdded.country,
        impersonationAdded.impersonationType,
      );
      if (duplicateABO) {
        editDuplicate(impersonationAdded);
      } else {
        add(impersonationAdded);
      }

      updateRecentlyUsedWithNewABO(impersonationAdded);
    }
    // eslint-disable-next-line
  }, [impersonationAdded]);

  return (
    <div className="impersonationHistory">
      {impersonationHistory && parseJson(impersonationHistory).length > 0 ? (
        <ImpersonationTable
          impersonationHistory={impersonationHistory}
          actions={{
            impersonateWithSelectedABO,
            impersonateWithAmywayPlus,
            remove,
          }}
        />
      ) : null}
    </div>
  );
};
