/**
 * Common volume types used in the accountPerformance service checks and within
 */
export const ACCOUNTPERFORMANCEAWARD: Readonly<{ [key: string]: string }> = Object.freeze({
  PERSONAL: "001",
  GROUP: "005",
  MEMBER: "007",
  RUBY: "019",
  VE_VOLUME: "086",
  LEADERSHIP_GROUP: "105",
  QCPV_SUM: "153",
  VCS: "925",
  NON_VCS: "926",
  INVENTORY_SALES: "157",
  PERSONAL_TRANSACTION: "003",
});

/**
 * Common volume types used in the accountPerformance service checks and within
 */
export const ACCOUNTPERFORMANCEAWARD_TOSTR: Readonly<{ [key: string]: string }> = Object.freeze({
  "001": "Personal",
  "005": "Group",
  "007": "Member",
  "019": "Ruby",
  "086": "VE Volume",
  "105": "Leadership Group",
  "153": "QCPV Sum",
  "925": "VCS",
  "926": "Non VCS",
  "157": "Inventory Sales",
  "003": "Personal Transaction",
});
