import { useMemo } from "react";
import { MODULE_CUSTOMER } from "../../../../common/constants/export-constants";
import CustomerExportNotifyContainer from "../CustomerExportNotifyContainer";
import {
  TAG_EXPORT_LIST,
  useGetExportNotificationV2Query,
  util as ExportAPIUtil,
} from "../../../../services/ExportV2/exportV2API";
import { useAppDispatch } from "../../../../store";

const CustomerListNotify = () => {
  const { data, isFetching } = useGetExportNotificationV2Query(
    { moduleId: MODULE_CUSTOMER },
    {
      pollingInterval: 30000,
    },
  );

  const dispatch = useAppDispatch();

  return useMemo(() => {
    const { Exports = [] } = data || {};
    const haveDone = Exports.some(({ Done }) => Done);
    if (haveDone) {
      // invalidate Export List API cache to make it re-fetch the API
      dispatch(ExportAPIUtil.invalidateTags([{ type: TAG_EXPORT_LIST, id: MODULE_CUSTOMER }]));
    }
    const showToast = haveDone === true && isFetching === false;

    return <CustomerExportNotifyContainer showToast={showToast} />;
  }, [data]);
};

export default CustomerListNotify;
