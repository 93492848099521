// hooks/useChatbot.ts
import { jwtToJson } from "@amwaycommon/acc-mixins/src/modules/auth";
import { useState } from "react";
import { store, useAppSelector } from "../../store";
import { CHATBOT_ID, CHATBOT_DATA_HANDLE, CHATBOT_SCRIPT } from "../../common/constants/chatbot-constants";
declare global {
  interface Window {
    adaSettings?: {
      lazy: boolean;
      language: string;
      crossWindowPersistence: boolean;
      sensitiveMetaFields: Record<string, any>;
      metaFields: {
        login: string;
        country_code: string;
        abo_id: string;
        party_id: string;
        cart_id: string;
      };
    };
  }
}

export const useChatbot = () => {
  const { user } = useAppSelector((state) => state.boot);
  const showChatbot = useAppSelector((state) => state.boot.configuration.showChatbot);
  const [party, setParty] = useState(user.partyId);

  const enableChatBot = async (enable: boolean) => {
    if (!showChatbot) return;

    if (enable) {
      try {
        const data = await fetchAndSetChatbotData();
        await setSensitiveMetaFields(data);
        await loadChatbotScript();
        toggleChatBotView(true);
      } catch (error) {
        console.error("Error enabling chatbot:", error);
      }
    } else {
      toggleChatBotView(false);
    }
  };

  const toggleChatBotView = (display: boolean) => {
    if (!showChatbot) return;
    const chatBotContainer = document.getElementById("ada-entry");
    if (chatBotContainer) {
      chatBotContainer.style.display = display ? "block" : "none";
    }
  };

  const fetchAndSetChatbotData = async () => {
    if (!showChatbot) return;

    const decodedJwt = jwtToJson(user.idToken);

    let index = decodedJwt.accounts.findIndex((abo: any) => abo.abo === user.abo && abo.sales_plan_aff === user.aff);

    const aboNum = index !== -1 ? decodedJwt.accounts[index].abo : user.abo;
    setParty(index !== -1 ? decodedJwt.accounts[index].lcl_partyid : user.partyId);

    index = index === -1 ? 0 : index;

    return {
      jwt_token: user.idToken,
      selected_account_number: aboNum,
      selected_account_index: index,
    };
  };

  const setSensitiveMetaFields = async (sensitiveFields: any) => {
    if (!showChatbot) return;

    return new Promise<void>((resolve, reject) => {
      try {
        if ((window as any).adaEmbed) {
          (window as any).adaEmbed.setSensitiveMetaFields(sensitiveFields);
        }

        window.adaSettings = {
          lazy: false,
          language: "en",
          crossWindowPersistence: true,
          sensitiveMetaFields: sensitiveFields,
          metaFields: {
            login: "0",
            country_code: user.isoCountryCode,
            abo_id: user.abo,
            party_id: party,
            cart_id: "0",
          },
        };
        resolve();
      } catch (error) {
        console.error("Error setting sensitive meta fields:", error);
        reject(error);
      }
    });
  };

  const loadChatbotScript = async () => {
    if (!showChatbot || document.getElementById(CHATBOT_ID)) return;

    return new Promise<void>((resolve, reject) => {
      const script = document.createElement("script");
      script.id = CHATBOT_ID;
      script.title = "Amway Chatbot";
      script.src = CHATBOT_SCRIPT;
      script.async = true;
      script.setAttribute("data-handle", CHATBOT_DATA_HANDLE);
      script.onload = () => resolve();
      script.onerror = (err) => reject(err);
      document.body.appendChild(script);
    });
  };

  return { enableChatBot, toggleChatBotView };
};
