import { useTranslation } from "react-i18next";
import defaultContent from "../../../../content/profile";
import "./LeafABOType.scss";

type Props = {
  businessNature: number;
  registrationChannelCd: string;
};

const LeafABOType = (props: Props) => {
  const { t } = useTranslation(["profile"]);
  const { businessNature, registrationChannelCd } = props;
  return (
    <div className="leaf-abo-type">
      {businessNature === 17 && registrationChannelCd === "ShareBar"
        ? `${t(
            `aboProfile.aboApplicants.aboType.171`,
            defaultContent["aboProfile"]["aboApplicants"]["aboType"][`171`],
          )}`
        : `${t(
            `aboProfile.aboApplicants.aboType.${businessNature}`,
            defaultContent["aboProfile"]["aboApplicants"]["aboType"][businessNature],
          )}`}
    </div>
  );
};

export default LeafABOType;
