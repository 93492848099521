import hatFaaHelper, { HatFaaHelperPayload } from "./hatFaaHelper";

export interface FaaGeneratorPayload extends HatFaaHelperPayload {
  ABONo: number;
  Country: string;
  Q6LegCount: number;
}

export default class FaaGenerator extends hatFaaHelper {
  _p: FaaGeneratorPayload;

  constructor(payload: FaaGeneratorPayload) {
    super(payload);
    this._p = payload;
  }

  /**
   * Generator abo number
   * @return {number}
   */
  get abo(): number | null {
    if (this._p) {
      return this._p.ABONo;
    }
    return null;
  }

  /**
   * ISO country code
   * @return {string}
   */
  get isoCountryCode(): string | null {
    if (this._p) {
      return this._p.Country;
    }
    return null;
  }

  /**
   * Leg Count
   */
  get legCount(): number {
    if (this._p) {
      return this._p.Q6LegCount;
    }
    return 0;
  }
}
