import { ABOAWARD } from "../enums/index";
import {
  AboDetail,
  AboInfo,
  PartyInfo,
  AwardInfo,
  AdditionalRegInfo as responseAdditionalRegInfo,
  Address as responseAddress,
  FosterSponsor as responseFosterSponsor,
  Sponsor as responseSponsor,
  PlatinumSponsor as responsePlatinumSponsor,
  InternationalSponsor as responseInternationalSponsor,
  DownlineInfo,
} from "../interfaces/api/profileGraphql";
import {
  RawProfile,
  SponsorAndPlatinum,
  HighestPin,
  HighestGlobalPin,
  PreferredLanguage,
  Contact,
  ProfileAddress,
  EmailAddressAddress,
  AdditionalRegInfo,
  PhoneNumber,
  Dates,
  NameDetails,
  Sponsor,
  BirthdateList,
} from "../interfaces/api/profile";
import { RawProfileGraphQl } from "../interfaces/models/profileGraphql";

/**
 * A model of an ABO Profile
 *
 * @export
 * @class Profile
 */
export default class Profile {
  aff: string;
  abo: string;
  __internal__: RawProfile;

  constructor(args: RawProfile | RawProfileGraphQl) {
    /**
     * The market aff for a given ABO
     */
    this.aff = "";

    /**
     * The ABO id number
     */
    this.abo = "";

    this.__internal__ = {
      src: "",
      httpStatus: 200,
      aff: "",
      abo: "",
      name: "",
      abo_local_name: "",
      country_code: "",
      country: "",
      verified_customer_flag: "",
      current_award_level: "not-available",
      current_award_rank: 0,
      number_of_abos: 0,
      privacy_flag: false,
      preferred_language: "",
      preferred_language_code: "",
      status: "Active",
      business_nature: "1",
      phone_numbers: [],
      email_addresses: [],
      addresses: [],
      genders: [],
      preferred_languages: [],
      birthdate_list: [],
      dates: {
        renewal_date: "--",
        business_entry: "--",
        highest_pin: { pin: "not-available", date: "--", rank: 0 },
        highest_global_pin: {
          pin: "not-available",
          rank: 0,
          date: "--",
          country: "--",
          country_code: "--",
        },
        isoBirthdate: "--",
        recent_return_date: "--",
        recent_sponsoring_date: "--",
        recent_order_date: "--",
        birthday: "--",
        date_joined: "--",
      },
      sponsor_and_platinum: {
        sponsor: { number: "0", aff: "0" },
        platinum_sponsor: { number: "0", aff: "0" },
        foster_sponsor: { number: "0", aff: "0" },
        platinum_foster_sponsor: { number: "0", aff: "0" },
        international_sponsor: { number: "0", aff: "0" },
      },
    };

    // Are we starting up empty?
    if (args) {
      this.initialize(args);
    }
  }

  initialize(args: RawProfile | RawProfileGraphQl): void {
    if ("data" in args && args.data.aboDetail) {
      this.__internal__ = {
        ...this.__internal__,
        ...this.__getProfileDetails(args.data.aboDetail, args.info),
      };
    }
    if ("aff" in args) {
      this.__internal__ = { ...this.__internal__, ...args };
      this.aff = args.aff;
      this.abo = args.abo;
    }
    this.__cleanDates();
  }

  get affAbo(): string {
    return `${this.aff}-${this.abo}`;
  }

  get sponsorAffAbos(): SponsorAndPlatinum {
    return this.__internal__.sponsor_and_platinum;
  }

  /**
   * Get the proper name field to display within the UI
   */
  getDisplayName(locale: string, confidentialUserTranslatedString: string): string {
    const { abo_local_name, name } = this.__internal__;
    const displayName = locale.startsWith(this.preferredLanguageCode) ? abo_local_name : name;
    return !displayName || displayName === "--" ? confidentialUserTranslatedString : displayName;
  }

  /**
   * Get the abo local name field for only lambda services not for UI
   * @returns string
   */
  get aboName(): string {
    return this.__internal__.name;
  }

  /**
   * Get the abo local name field for only lambda services not for UI
   */
  get aboLocalName(): string {
    return this.__internal__.abo_local_name;
  }

  get countryCode(): string {
    return this.__internal__.country_code;
  }

  get currentAwardLevel(): string {
    return this.__internal__.current_award_level;
  }

  get currentAwardRank(): number {
    return this.__internal__.current_award_rank;
  }

  get highestAward(): HighestPin {
    return this.__internal__.dates.highest_pin;
  }

  get highestGlobalPin(): HighestGlobalPin {
    return this.__internal__.dates.highest_global_pin;
  }

  get numberOfAbos(): number {
    return this.__internal__.number_of_abos;
  }

  get preferredLanguage(): string {
    return this.__internal__.preferred_language;
  }

  get preferredLanguageCode(): string {
    return this.__internal__.preferred_language_code;
  }

  get preferredLanguageList(): PreferredLanguage[] {
    return this.__internal__.preferred_languages;
  }

  /**
   * Verify if the ABO is above the Platinum Award level. Used primary for
   * string restriction within the UI
   * @return {Boolean}
   */
  get __isCurrentAwardRankPlatinumOrAbove(): boolean {
    // do not be fooled by the ASSOCIATE case: because we have high number special cases
    // because we can't guarantee non-collision (since we have no means to
    // verify on the DB side) we have to put a secondary check otherwise
    return (
      this.__internal__.current_award_rank >= ABOAWARD.PLATINUM &&
      this.__internal__.current_award_rank < ABOAWARD.ASSOCIATE
    );
  }

  get __isCurrentAwardRankAssociateBelowSilver(): boolean {
    if (
      this.__internal__.current_award_rank >= ABOAWARD.PERCENT_3 &&
      this.__internal__.current_award_rank < ABOAWARD.SILVER_SPONSOR
    ) {
      return true;
    }
    return false;
  }

  get phoneNumber(): string {
    // unideal, but name search misses currently because of malformed data
    if (
      this.__internal__.phone_numbers.length >= 1 &&
      this.__internal__.phone_numbers[0].numbers &&
      this.__internal__.phone_numbers[0].numbers.length >= 1
    ) {
      return this.__internal__.phone_numbers[0].numbers[0].display_number;
    }
    return "";
  }

  get phoneNumberList(): Contact[] {
    // there is probably a better case to be had here
    return this.__internal__.phone_numbers.filter((item) => item?.numbers && item.numbers.length >= 0);
  }

  get emailAddress(): string {
    if (
      this.__internal__.email_addresses.length >= 1 &&
      this.__internal__.email_addresses[0].addresses &&
      this.__internal__.email_addresses[0].addresses.length >= 1
    ) {
      return this.__internal__.email_addresses[0].addresses[0].address;
    }
    return "";
  }

  get emailAddressList(): Contact[] {
    return this.__internal__.email_addresses;
  }

  get addressesList(): ProfileAddress[] {
    return this.__internal__.addresses;
  }

  get genderList(): Contact[] {
    return this.__internal__.genders;
  }

  get dates(): Dates {
    return this.__internal__.dates;
  }

  get contactList(): Contact[] {
    const unionSetOne: Set<string> = new Set(
      this.phoneNumberList.map((item) => {
        return item.name;
      }),
    );
    const unionSetTwo: Set<string> = new Set(
      this.emailAddressList.map((item) => {
        return item.name;
      }),
    );

    // better known as a union
    const uniqueNames: Set<Iterable<string>> = new Set([...unionSetOne, ...unionSetTwo]);

    // create the unique contact list
    return [...uniqueNames].map((person) => {
      const phoneListItem: Contact | undefined = this.phoneNumberList.find((item) => item.name === person);
      const emailAddressListItem: Contact | undefined = this.emailAddressList.find((item) => item.name === person);
      return { ...phoneListItem, ...emailAddressListItem } as Contact;
    });
  }

  /**
   * Convert string dates within the __internal__ to proper EcmaScript dates
   */
  private __cleanDates(): void {
    if (this.__internal__.dates) {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        for (const [key, value] of Object.entries(this.__internal__.dates)) {
          if (typeof value === "string") {
            if (value === "--") {
              this.__internal__.dates[key] = null;
            } else {
              this.__internal__.dates[key] = Profile.__generateDateFromString(value);
            }
          } else if ((value as { date: string }).date === "--") {
            this.__internal__.dates[key].date = null;
          } else {
            this.__internal__.dates[key].date = Profile.__generateDateFromString((value as { date: string }).date);
          }
        }
      } catch (error) {
        // edge case for really terrible data
      }
    }
  }

  /**
   * Generate a proper Date from a dashed string
   */
  static __generateDateFromString(string: string): Date | null {
    const [year, month, date] = string.split("-");
    if (year && month) {
      const d = new Date(
        Number(year.substring(0, 4)),
        Number(month.substring(0, 2)) - 1,
        (date && Number(date.substring(0, 2))) || 1,
        0,
        0,
        0,
      );
      return d;
    }
    return null;
  }

  /**
   * Get Profile Details
   * @public
   * @param {AboDetail} aboDetail
   * @param {string|string[]} info
   * @returns <Profile>
   */
  private __getProfileDetails(aboDetail: AboDetail, info: string | string[]): RawProfile {
    const { aboInfo, awardInfo, downlineInfo, partyInfo, additionalRegInfo } = aboDetail;
    this.aff = aboInfo.affNo;
    this.abo = aboInfo.aboNo;

    return this.mergeInfoParam(info, partyInfo, aboInfo, awardInfo, downlineInfo, additionalRegInfo);
  }

  /**
   * Merge party info
   * @public
   * @param {string|string[]} info
   * @param {PartyInfo} partyInfo
   * @param {AboInfo} aboInfo
   * @param {AwardInfo} awardInfo
   * @param {DownlineInfo} downlineInfo
   * @param {AdditionalRegInfo} additionalRegInfo
   * @returns <RawProfile>
   */
  mergeInfoParam(
    info: string | string[],
    partyInfo: PartyInfo[] | undefined,
    aboInfo: AboInfo,
    awardInfo: AwardInfo,
    downlineInfo: DownlineInfo,
    additionalRegInfo: AdditionalRegInfo | undefined,
  ): RawProfile {
    const settings: string | string[] = typeof info !== "string" ? info : info.split(",");
    const result: RawProfile = {
      aff: aboInfo.affNo,
      abo: aboInfo.aboNo,
      name: aboInfo.name,
      abo_local_name: aboInfo.localName,
      country_code: aboInfo.countryCode,
      country: aboInfo.countryCode,
      current_award_level: awardInfo.currentPinLevel,
      current_award_rank: awardInfo.currentPin,
      number_of_abos: downlineInfo.totalABOCount,
      privacy_flag: aboInfo.privacyFlag,
      preferred_language: aboInfo.language,
      preferred_language_code: aboInfo.isoLanguageCode,
      status: aboInfo.status,
      business_nature: aboInfo.businessNatureCode,
      verified_customer_flag: aboInfo.verifiedCustomerFlag,
      phone_numbers: this.__buildPhoneNumbers(partyInfo),
      email_addresses: this.__buildEmailAddresses(partyInfo),
      addresses: this.__buildAddresses(aboInfo.addresses),
      dates: this.__buildDates(aboInfo, awardInfo, settings),
      genders: this.__buildGenders(partyInfo),
      sponsor_and_platinum: this.__buildSponsorAndPlatinum(aboInfo, settings),
      preferred_languages: this.__buildPreferredLanguages(partyInfo),
      birthdate_list: this.__buildBirthdayList(partyInfo),
      additionalRegInfo: this.__buildAdditionalRegInfo(additionalRegInfo),
    };
    return result;
  }

  /**
   * Get nad details
   * @param {PartyInfo} partyInfo
   * @returns {NameDetails}
   */
  getNameDetails(partyInfo: PartyInfo): NameDetails {
    const result: NameDetails = {
      latin_name: {
        given_name: partyInfo.latinName.givenName,
        family_name: partyInfo.latinName.familyName,
        middle_name: partyInfo.latinName.middleName,
        suffix: partyInfo.latinName.suffix,
        title: partyInfo.latinName.title,
      },
      local_name: {
        given_name: partyInfo.localeName.givenName,
        family_name: partyInfo.localeName.familyName,
        middle_name: partyInfo.localeName.middleName,
        suffix: partyInfo.localeName.suffix,
        title: partyInfo.localeName.title,
      },
    };
    return result;
  }

  /**
   *
   * Get Phone Number details
   * @param {PartyInfo[]} partyInfo
   * @returns {Contact[]}
   */
  private __buildPhoneNumbers(partyInfo?: PartyInfo[]): Contact[] {
    const phoneNumbers: Contact[] = [];
    if (partyInfo && partyInfo.length > 0) {
      const partyPhoneInfo: PartyInfo[] = partyInfo.filter((p) => p.phoneNumbers && p.phoneNumbers.length > 0);
      partyPhoneInfo.forEach((party) => {
        const result: PhoneNumber[] = [];
        party.phoneNumbers?.forEach((phone) => {
          const numbers: PhoneNumber = {
            type: phone.type,
            number: phone.number,
            display_number: phone.displayNumber,
            out_of_country_calling_number: phone.outOfCountryCallingNumber,
            extension: phone.extension,
          };
          result.push(numbers);
        });
        const phone: Contact = {
          is_primary: party.isPrimary,
          name: party.displayName,
          local_name: party.localeDisplayName,
          preferred_name: party.preferredName,
          name_details: this.getNameDetails(party),
          numbers: result,
        };
        phoneNumbers.push(phone);
      });
    }
    return phoneNumbers;
  }

  /**
   * Get email addresses
   * @param {PartyInfo[]} partyInfo
   * @returns {Contact[]}
   */
  private __buildEmailAddresses(partyInfo?: PartyInfo[]): Contact[] {
    const emailAddresses: Contact[] = [];
    if (partyInfo && partyInfo.length > 0) {
      const partyEmailAddressInfo: PartyInfo[] = partyInfo.filter(
        (p) => p.emailAddresses && p.emailAddresses.length > 0,
      );
      partyEmailAddressInfo.forEach((party) => {
        const result: EmailAddressAddress[] = [];
        party.emailAddresses?.forEach((email) => {
          const emailAddress: EmailAddressAddress = {
            type: email.type,
            address: email.email,
          };
          result.push(emailAddress);
        });
        const email: Contact = {
          is_primary: party.isPrimary,
          name: party.displayName,
          local_name: party.localeDisplayName,
          preferred_name: party.preferredName,
          name_details: this.getNameDetails(party),
          addresses: result,
        };
        emailAddresses.push(email);
      });
    }
    return emailAddresses;
  }

  /**
   * Get addresses
   * @param {responseAddress[]} addresses
   * @returns {ProfileAddress[]}
   */
  private __buildAddresses(addresses?: responseAddress[]): ProfileAddress[] {
    const result: ProfileAddress[] = [];
    if (addresses && addresses.length > 0) {
      addresses.forEach((address) => {
        const aboAddress: ProfileAddress = {
          type: address.type,
          address_lines: address.addressLines,
          municipality: address.municipality,
          region: address.region,
          postal_code: address.postalCode,
          country: address.country,
        };
        result.push(aboAddress);
      });
    }
    return result;
  }

  /**
   * Get genders
   * @param {PartyInfo[]} partyInfo
   * @returns {Contact[]}
   */
  private __buildGenders(partyInfo?: PartyInfo[]): Contact[] {
    const genders: Contact[] = [];
    if (partyInfo && partyInfo.length > 0) {
      const partyGenderInfo: PartyInfo[] = partyInfo.filter((p) => p.gender && p.gender.length > 0);
      partyGenderInfo.forEach((party) => {
        const gender: Contact = {
          is_primary: party.isPrimary,
          name: party.displayName,
          local_name: party.localeDisplayName,
          preferred_name: party.preferredName,
          name_details: this.getNameDetails(party),
          gender: party.gender,
        };
        genders.push(gender);
      });
    }
    return genders;
  }

  /**
   * Get preferred language
   * @param {PartyInfo[]} partyInfo
   * @returns {PreferredLanguage[]}
   */
  private __buildPreferredLanguages(partyInfo?: PartyInfo[]): PreferredLanguage[] {
    const preferredLanguages: PreferredLanguage[] = [];
    if (partyInfo && partyInfo.length > 0) {
      const partyLanguageInfo: PartyInfo[] = partyInfo.filter((p) => p.preferredLanguage && p.preferredLanguageCode);
      partyLanguageInfo.forEach((party) => {
        const language: PreferredLanguage = {
          is_primary: party.isPrimary,
          name: party.displayName,
          local_name: party.localeDisplayName,
          preferred_name: party.preferredName,
          name_details: this.getNameDetails(party),
          language: party.preferredLanguage,
          language_code: party.preferredLanguageCode,
        };
        preferredLanguages.push(language);
      });
    }
    return preferredLanguages;
  }

  /**
   * Get birthday list
   * @param {PartyInfo[]} partyInfo
   * @returns {BirthdateList[]}
   */
  private __buildBirthdayList(partyInfo?: PartyInfo[]): BirthdateList[] {
    const birthdayList: BirthdateList[] = [];
    if (partyInfo && partyInfo.length > 0) {
      partyInfo.forEach((party) => {
        const birthday: BirthdateList = {
          is_primary: party.isPrimary,
          name: party.displayName,
          local_name: party.localeDisplayName,
          preferred_name: party.preferredName,
          name_details: this.getNameDetails(party),
          birthdate: party.birthdate,
          isoBirthdate: party.isoBirthdate,
        };
        birthdayList.push(birthday);
      });
    }
    return birthdayList;
  }

  /**
   * Get sponsor and platinum
   * @param {AboInfo} aboInfo
   * @param {(string | string[])} settings
   * @returns {SponsorAndPlatinum}
   */
  private __buildSponsorAndPlatinum(
    aboInfo: AboInfo,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    settings: string | string[],
  ): SponsorAndPlatinum {
    const isEnabled: boolean = settings?.includes("sponsor_and_platinum");
    const sponsorAndPlatinum: SponsorAndPlatinum = {
      sponsor: this.__locateSponsor(aboInfo.sponsor, isEnabled),
      platinum_sponsor: this.__locateSponsor(aboInfo.platinumSponsor, isEnabled),
      foster_sponsor: this.__locateSponsor(aboInfo.fosterSponsor, isEnabled),
      platinum_foster_sponsor:
        aboInfo.fosterSponsor && aboInfo.fosterSponsor.isPlatinum
          ? this.__locateSponsor(aboInfo.fosterSponsor, isEnabled)
          : { number: "--", aff: "--" },
      international_sponsor: this.__locateSponsor(aboInfo.internationalSponsor, isEnabled),
    };
    return sponsorAndPlatinum;
  }

  /**
   * Get sponsordetail
   * @param {(responseSponsor
   *       | responsePlatinumSponsor
   *       | responseFosterSponsor
   *       | responseInternationalSponsor)} sponsor
   * @param {boolean} isEnable
   * @returns {Sponsor}
   */
  private __locateSponsor(
    sponsor?: responseSponsor | responsePlatinumSponsor | responseFosterSponsor | responseInternationalSponsor,
    isEnable?: boolean,
  ): Sponsor {
    const result: Sponsor = { aff: "--", number: "--" };
    if (sponsor && isEnable) {
      result.aff = sponsor.affNo;
      result.number = sponsor.aboNo;
    }

    return result;
  }

  /**
   * Get dates
   * @param {AboInfo} aboInfo
   * @param {AwardInfo} awardInfo
   * @param {(string | string[])} settings
   * @returns {Dates}
   */
  __buildDates(aboInfo: AboInfo, awardInfo: AwardInfo, settings: string | string[]): Dates {
    const dates: Dates = {
      renewal_date: aboInfo.renewalDate,
      business_entry: aboInfo.entryDate,
      date_joined: aboInfo.entryDate,
      recent_sponsoring_date: aboInfo.recentSponsorDate,
      recent_order_date: "",
      recent_return_date: "",
      birthday: aboInfo.birthdate,
      isoBirthdate: aboInfo.isoBirthdate,
      highest_pin: {
        pin: awardInfo.highestAwardAchievedLevel,
        rank: awardInfo.highestAwardAchieved,
        date: awardInfo.highestAwardAchievedDate,
      },
      highest_global_pin: {
        pin: awardInfo.highestGlobalAwdLevel,
        rank: awardInfo.highestGlobalAwd,
        date: awardInfo.highestGlobalAwdDate,
        country: awardInfo.highestGlobalAwdCountry,
        country_code: awardInfo.highestGlobalAwdCountryCode,
      },
    };

    return settings && settings.includes("dates") ? dates : this.__internal__.dates;
  }

  __buildAdditionalRegInfo(additionalRegInfo?: responseAdditionalRegInfo): AdditionalRegInfo | undefined {
    let result: AdditionalRegInfo;
    if (additionalRegInfo) {
      result = {
        autoRenewal: additionalRegInfo.autoRenewal,
        orderBlock: additionalRegInfo.orderBlock,
        sponsorBlock: additionalRegInfo.sponsorBlock,
        passport: additionalRegInfo.passport,
        contractSigned: additionalRegInfo.contractSigned,
        isRegisteredCustomerPlus: additionalRegInfo.isRegisteredCustomerPlus,
      };
      return result;
    }
    return undefined;
  }
}
