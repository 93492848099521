import { CustomerOrder, OrderResponse } from "../../../services/Customer/CustomerOrderList/customerOrderListAPI.types";
import { FilterGroup, getFilterQueryParams } from "../filters";

export type OrderGroup = {
  period: number;
  periodDate: string;
  totalOrderCount: number;
  customerOrderList: CustomerOrder[];
};

export type OrderListFilterQueryParams = {
  filterOrderByVcs?: string;
  filterOrderBySourceType?: string;
  businessNature?: string;
};

type OrderList = {
  Period: number;
  PageNum: number;
  PageSize: number;
  PageLimit: number;
  TotalOrderCount: number;
  allOrderCount: number;
  Aff: string;
  Abo: string;
  AffAbo: string;
  NextPageNum: number;
  NextPeriod: number;
  orderGroups: OrderGroup[];
  isFetching?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  refreshFilterTime: number;
  requestId?: string;
};

export const initialState: OrderList = {
  Period: 0,
  PageNum: 0,
  PageSize: 50,
  PageLimit: 0,
  TotalOrderCount: 0,
  allOrderCount: 0,
  Aff: "",
  Abo: "",
  AffAbo: "",
  NextPageNum: 1,
  NextPeriod: 0,
  orderGroups: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  refreshFilterTime: 0,
  requestId: "",
};

const getOrderGroup = (period: number, orderGroups: OrderGroup[]) => {
  return orderGroups.find((order) => order.period === period);
};

const updateOrderList = (orderGroup: OrderGroup, customerOrderList: CustomerOrder[]) => {
  return {
    ...orderGroup,
    customerOrderList: [...orderGroup.customerOrderList, ...(customerOrderList || [])],
  };
};

const createOrderGroup = ({
  Period,
  PeriodDate,
  TotalOrderCount,
  CustomerOrderList,
}: Pick<OrderResponse, "Period" | "PeriodDate" | "TotalOrderCount" | "CustomerOrderList">) => {
  return {
    period: Period,
    periodDate: PeriodDate,
    totalOrderCount: TotalOrderCount,
    customerOrderList: CustomerOrderList || [],
  };
};

export const upsertOrderGroup = (
  orderData: Pick<OrderResponse, "Period" | "PeriodDate" | "TotalOrderCount" | "CustomerOrderList">,
  orderGroups: OrderGroup[],
) => {
  const { Period, CustomerOrderList } = orderData;
  const orderGroup = getOrderGroup(Period, orderGroups);

  return orderGroup ? updateOrderList(orderGroup, CustomerOrderList) : createOrderGroup(orderData);
};

const updateOrderGroups = (period: number, orderGroups: OrderGroup[], updatedGroup: OrderGroup) => {
  return orderGroups.map((order) => (order.period === period ? updatedGroup : order));
};

const insertOrderGroup = (orderGroups: OrderGroup[], updatedGroup: OrderGroup) => {
  return [...orderGroups, { ...updatedGroup }];
};

export const upsertOrderGroups = (period: number, orderGroups: OrderGroup[], updatedGroup: OrderGroup) => {
  const orderGroup = getOrderGroup(period, orderGroups);

  return orderGroup
    ? updateOrderGroups(period, orderGroups, updatedGroup)
    : insertOrderGroup(orderGroups, updatedGroup);
};

export const includeGuestCustomerBN = (enableGuestCustomer: boolean) => {
  return enableGuestCustomer === true ? { businessNature: "3,4,17" } : {};
};

export const getOrderFilterQueryParams = (
  filterGroups: FilterGroup[],
  enableGuestCustomer: boolean,
): OrderListFilterQueryParams => {
  const filterQueryParams = getFilterQueryParams<OrderListFilterQueryParams>(filterGroups);
  const businessNatureQueryParams = includeGuestCustomerBN(enableGuestCustomer);
  return { ...filterQueryParams, ...businessNatureQueryParams };
};

export const resetList = (requestId?: string): OrderList => ({
  ...initialState,
  requestId,
  refreshFilterTime: new Date().getTime(),
});
