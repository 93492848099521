/**
 *
 * @typedef awardInfoTaxonomy
 * @property {string} awardTrend
 * @property {string} newPin
 * @property {string} currentPin
 * @property {string} previousPYPin
 * @property {string} highestTrackingAward
 * @property {string} highestAwardAchieved
 * @property {string} highestAwardAchievedLevel
 * @property {string} highestAwardAchievedDate
 * @property {string} pointsToNextLevel
 * @property {string} pointsToNextLevelPercent
 * @property {string} performanceBonusPercent
 * @property {string} bonusPercent
 * @property {string} currentPinLevel
 * @property {string} highestGlobalAwd
 * @property {string} highestGlobalAwdDate
 * @property {string} highestGlobalAwdLevel
 * @property {string} highestGlobalAwdCountry
 * @property {string} highestGlobalAwdCountryCode
 * @readonly
 * @enum string
 */
export const AWARDINFO = Object.freeze({
  AWARD_TREND: "awardTrend",
  NEW_PIN: "newPin",
  CURRENT_PIN: "currentPin",
  PREVIOUS_PY_PIN: "previousPYPin",
  HIGHEST_TRACKING_AWARD: "highestTrackingAward",
  HIGHEST_AWARD_ACHIEVED: "highestAwardAchieved",
  HIGHEST_AWARD_ACHIEVED_LEVEL: "highestAwardAchievedLevel",
  HIGHEST_AWARD_ACHIEVED_DATE: "highestAwardAchievedDate",
  POINTS_TO_NEXT_LEVEL: "pointsToNextLevel",
  POINTS_TO_NEXT_LEVEL_PERCENT: "pointsToNextLevelPercent",
  PERFORMANCE_BONUS_PERCENT: "performanceBonusPercent",
  VE_ELIGIBLE: "veEligible",
  BONUS_PERCENT: "bonusPercent",
  CURRENT_PIN_LEVEL: "currentPinLevel",
  HIGHEST_GLOBAL_AWARD: "highestGlobalAwd",
  HIGHEST_GLOBAL_AWARD_DATE: "highestGlobalAwdDate",
  HIGHEST_GLOBAL_AWARD_LEVEL: "highestGlobalAwdLevel",
  HIGHEST_GLOBAL_AWARD_COUNTRY: "highestGlobalAwdCountry",
  HIGHEST_GLOBAL_AWARD_COUNTRY_CODE: "highestGlobalAwdCountryCode",
  PERSONAL_VCS_PERCENT: "personalVcsPercent",
});
