import { useCallback, useEffect, useMemo, useState } from "react";
import { useAsync } from "react-async";
import { LOS_GRID_EVENT } from "../Lit/acc-wc-views/src/acc-wc-view-los-indented/enum.js";
import { mapAttributes } from "../../common/util/config";
import { ErrorPage, ERROR_TYPE } from "../ErrorPage/ErrorPage";
import { storedLocale } from "../impersonation/util";
import { useAppDispatch, useAppSelector } from "../../store";
import { showProfile } from "../../reducers/profile";
import ABOProfile from "../ABOProfile/ABOProfile";
import { BonusInfoFooter } from "../../common/components/BonusInfoFooter/BonusInfoFooter";
import { useActionReportMenuOptions } from "./hooks/useActionReportMenuOptions";
import { getARComponentConfiguration } from "./utils/ActionReportHelpers";
import "./ActionReports.scss";

const loadComponent = async () => {
  return import("../Lit/acc-wc-los-ar/acc-wc-los-ar.js");
};

export default function IboOnBoardingActionReport() {
  const locale = storedLocale().toLowerCase();
  const dispatch = useAppDispatch();
  const [apiError, setApiError] = useState(false);
  const { actionReportMenuOptions } = useActionReportMenuOptions();
  const { configuration } = useAppSelector((state) => state.boot);
  const { onboardingAr = {} } = getARComponentConfiguration(configuration);
  const { isPending } = useAsync({ promiseFn: loadComponent });
  const [modalOpenedByElement, setModalOpenedByElement] = useState<HTMLElement>();

  const updatedOnboardingAr = useMemo(() => {
    return {
      ...onboardingAr,
      uiGrid: onboardingAr.uiGrid
        .split(",")
        .filter((item: string) => item !== "uiMenuShowPerfHistory")
        .join(","),
    };
  }, [onboardingAr]);

  useEffect(() => {
    if (!isPending) {
      const arOnBoarding = document.getElementById("acc_wc_los_ar_on_boarding_component") as HTMLElement;
      mapAttributes(arOnBoarding, updatedOnboardingAr, {});
    }
  }, [isPending, updatedOnboardingAr]);

  useEffect(() => {
    if (!apiError) {
      window.addEventListener("acc-api-error", () => setApiError(true));
    }

    if (apiError) {
      window.removeEventListener("acc-api-error", () => {});
    }
  }, [apiError]);

  const showABOProfile = useCallback(
    (event: CustomEventInit) => {
      dispatch(showProfile(event.detail.abo.affAbo));
      setModalOpenedByElement(event.detail.target);
    },
    [dispatch],
  );

  useEffect(() => {
    window.addEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    return () => {
      window.removeEventListener(LOS_GRID_EVENT.ACTION_SEND_ABO, showABOProfile);
    };
  }, [showABOProfile]);

  if (apiError) {
    return <ErrorPage errorType={ERROR_TYPE.GENERIC_ERROR} />;
  }

  const renderIboOnBoarding = () => {
    return (
      <acc-wc-los-ar
        id="acc_wc_los_ar_on_boarding_component"
        locale={locale}
        uiHeaderReportSelectorOptions={JSON.stringify(actionReportMenuOptions)}
      ></acc-wc-los-ar>
    );
  };

  if (isPending) {
    return <div className="actionReportWrapper"></div>;
  }
  return (
    <main>
      <div id="ar-on-boarding-wrapper" className="actionReportWrapper">
        {renderIboOnBoarding()}
      </div>
      <ABOProfile previousElement={modalOpenedByElement} useCurrentMonthData={true} />
      <BonusInfoFooter hideDate />
    </main>
  );
}
