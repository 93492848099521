import { NavDropdown } from "../../interfaces/routing";
import { Config } from "../../interfaces/config";
import { DROPDOWN_KEYS, DROPDOWN_LABELS } from "../../enums/routing-enums";
import { BootUser } from "../../../reducers/boot";

export const createAcademyMenu = (configuration: Config, user: BootUser): NavDropdown => {
  const showAcademy = configuration?.navigation?.showAcademy;
  const configLink = configuration?.externalIntegration?.academy?.navigationlink;

  const getNavigationLink = () => {
    // TODO: To enhance the logic based on the tokens and link patterns for different markets in future
    if (user?.cognitoToken) {
      return `${configLink}?q=${user?.cognitoToken}`;
    }
    return configLink;
  };

  const navigationlink = getNavigationLink();

  if (showAcademy && navigationlink !== "") {
    return {
      key: DROPDOWN_KEYS.ACADEMY,
      label: DROPDOWN_LABELS.ACADEMY,
      path: navigationlink,
      items: [],
      skip: false,
    };
  }
  return {
    key: DROPDOWN_KEYS.ACADEMY,
    label: DROPDOWN_LABELS.ACADEMY,
    items: [],
    skip: true,
  };
};
