/**
 * Common award levels for ABOs
 */
export const ABOAWARD: Readonly<{ [key: string]: number }> = Object.freeze({
  NOT_TRACKING: 0,
  ASSOCIATE: 2022, // special sauce 🚧
  NOT_AVAILABLE: 2026, // special sauce 🚧
  SILVER_SPONSOR: 305,
  SILVER_PRODUCER: 310,
  GOLD_PRODUCER: 320,
  PLATINUM: 330,
  PLATINUM_OR_ABOVE: 2020, // special sauce 🚧
  RUBY: 340,
  FOUNDERS_PLATINUM: 342,
  FOUNDERS_RUBY: 345,
  PEARL: 350,
  SAPPHIRE: 365,
  FOUNDERS_SAPPHIRE: 367,
  EMERALD: 370,
  FOUNDERS_EMERALD: 375,
  DIAMOND: 380,
  FOUNDERS_DIAMOND: 385,
  EXECUTIVE_DIAMOND: 390,
  FOUNDERS_EXECUTIVE_DIAMOND: 395,
  DOUBLE_DIAMOND: 400,
  FOUNDERS_DOUBLE_DIAMOND: 405,
  TRIPLE_DIAMOND: 410,
  FOUNDERS_TRIPLE_DIAMOND: 415,
  CROWN: 420,
  FOUNDERS_CROWN: 425,
  CROWN_AMBASSADOR: 430,
  FOUNDERS_CROWN_AMBASSADOR: 435,
  FCA_40: 440,
  FCA_45: 445,
  FCA_50: 450,
  FCA_55: 455,
  FCA_60: 460,
  FCA_65: 465,
  FCA_70: 470,
  PERCENT_3: 203,
  PERCENT_6: 206,
  PERCENT_9: 209,
  PERCENT_12: 212,
  PERCENT_15: 215,
  PERCENT_18: 218,
  PERCENT_21: 221,
  PERCENT_23: 223,
  GAR_EXECUTIVE_DIAMOND: 393,
  GAR_FOUNDERS_EXECUTIVE_DIAMOND: 398,
  GAR_DOUBLE_DIAMOND: 403,
  GAR_FOUNDERS_DOUBLE_DIAMOND: 408,
  GAR_TRIPLE_DIAMOND: 413,
  GAR_FOUNDER_TRIPLE_DIAMOND: 418,
  GAR_CROWN: 423,
  GAR_FOUNDERS_CROWN: 428,
  GAR_CROWN_AMBASSADOR: 433,
  GAR_FOUNDERS_CROWN_AMBASSADOR: 438,
});

/**
 * Not a true enum; a cheat for being able to match the API output for dashed
 * case and used heavily in component color combinations.
 *
 * Only use this if you really understand what you want out of it, otherwise use
 * ABOAWARD enum
 */
export const ABOAWARD_TOSTR: Readonly<{ [key: number]: string }> = Object.freeze({
  0: "not-tracking",
  2020: "platinum-or-above", // special sauce 🚧
  2022: "associate",
  2026: "not-available",
  203: "percent-3",
  206: "percent-6",
  209: "percent-9",
  212: "percent-12",
  215: "percent-15",
  218: "percent-18",
  221: "percent-21",
  223: "percent-23",
  305: "silver-sponsor",
  310: "silver-producer",
  320: "gold-producer",
  330: "platinum",
  340: "ruby",
  342: "founders-platinum",
  345: "founders-ruby",
  350: "pearl",
  365: "sapphire",
  367: "founders-sapphire",
  370: "emerald",
  375: "founders-emerald",
  380: "diamond",
  385: "founders-diamond",
  390: "executive-diamond",
  395: "founders-executive-diamond",
  400: "double-diamond",
  405: "founders-double-diamond",
  410: "triple-diamond",
  415: "founders-triple-diamond",
  420: "crown",
  425: "founders-crown",
  430: "crown-ambassador",
  435: "founders-crown-ambassador",
  440: "fca-40",
  445: "fca-45",
  450: "fca-50",
  455: "fca-55",
  460: "fca-60",
  465: "fca-65",
  470: "fca-70",
  393: "gar-executive-diamond",
  398: "gar-founders-executive-diamond",
  403: "gar-double-diamond",
  408: "gar-founders-double-diamond",
  413: "gar-triple-diamond",
  418: "gar-founder-triple-diamond",
  423: "gar-crown",
  428: "gar-founders-crown",
  433: "gar-crown-ambassador",
  438: "gar-founders-crown-ambassador",
});
