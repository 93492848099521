import "./IconArrowDropdown.scss";

type Props = {
  backgroundClass: "blue" | "white" | "black";
  thickness?: "thick" | "";
  size?: "lg" | "";
};

export const IconArrowDropdown = ({ backgroundClass = "black", thickness = "", size = "" }: Props) => {
  return (
    <span
      className={`icon-arrow-dropdown icon-arrow-dropdown--${backgroundClass} icon-arrow-dropdown--${thickness} ${
        size === "lg" ? "icon-arrow-dropdown--lg" : ""
      }`}
    ></span>
  );
};
