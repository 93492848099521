import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import {
  FOSTER_SPONSOR,
  INTERNATIONAL_SPONSOR,
  PLATINUM_AND_SPONSOR,
  PLATINUM_FOSTER_SPONSOR,
  PLATINUM_SPONSOR,
  SPONSOR,
} from "../../../../constants/sponsor-constants";
import "./LeafSponsorTypeLabel.scss";

type Props = {
  sponsorType:
    | typeof SPONSOR
    | typeof PLATINUM_SPONSOR
    | typeof PLATINUM_AND_SPONSOR
    | typeof INTERNATIONAL_SPONSOR
    | typeof FOSTER_SPONSOR
    | typeof PLATINUM_FOSTER_SPONSOR;
};

const LeafSponsorTypeLabel = (props: Props) => {
  const { sponsorType } = props;
  const { t } = useTranslation(["profile"]);

  return (
    <span className={`leaf-sponsor-type-label leaf-sponsor-type-label--${sponsorType}`}>
      {`${t(`aboProfile.sponsorshipTab.${sponsorType}`, defaultContent["aboProfile"]["sponsorshipTab"][sponsorType])}`}
    </span>
  );
};

export default LeafSponsorTypeLabel;
