/**
 * A market and its codes
 *
 * @export
 * @class Market
 */
export default class Market {
  name: string;
  region: string;
  aff: string;
  ctry: string;
  iso: string;
  /**
   * Creates an instance of Markets.
   * @param {string} name - Market name.
   * @param {string} region - Region code.
   * @param {string} aff - Amway affiliate code.
   * @param {string} ctry - Amway country code.
   * @param {string} iso - ISO Alpha 2 country code.
   * @memberof Market
   */
  constructor(name: string, region: string, aff: string, ctry: string, iso: string) {
    this.name = name;
    this.region = region;
    this.aff = aff;
    this.ctry = ctry;
    this.iso = iso;
  }
}
