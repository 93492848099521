import { Route } from "react-router-dom";
import { ROUTE_PATHS } from "../common/enums/routing-enums";
import ActionReports from "../components/ActionReports/ActionReports";
import BirthdaysActionReport from "../components/ActionReports/BirthdaysActionReport";
import CbrActionReport from "../components/ActionReports/CbrActionReport";
import CvrActionReport from "../components/ActionReports/CvrActionReport";
import IboOnBoardingActionReport from "../components/ActionReports/IboOnBoardingActionReport";
import NBBVActionReport from "../components/ActionReports/NBBVActionReport";
import RenewalsActionReport from "../components/ActionReports/RenewalsActionReport";
import { withActionReportSelectorRouting } from "../components/ActionReports/utils/ActionReportSelectorRouting";
import WhosCloseActionReport from "../components/ActionReports/WhosCloseActionReport";
import WhosNewActionReport from "../components/ActionReports/WhosNewActionReport";

export const ACTION_REPORTS_COMPONENT_MAP = {
  [ROUTE_PATHS.ACTION_REPORTS]: <ActionReports />,
  [ROUTE_PATHS.RENEWALS]: withActionReportSelectorRouting(<RenewalsActionReport />),
  [ROUTE_PATHS.IBO_ONBOARDING]: withActionReportSelectorRouting(<IboOnBoardingActionReport />),
  [ROUTE_PATHS.CBR]: withActionReportSelectorRouting(<CbrActionReport />),
  [ROUTE_PATHS.CVR]: withActionReportSelectorRouting(<CvrActionReport />),
  [ROUTE_PATHS.BIRTHDAYS_ACTION_REPORT]: withActionReportSelectorRouting(<BirthdaysActionReport />),
  [ROUTE_PATHS.WHOS_NEW_ACTION_REPORT]: withActionReportSelectorRouting(<WhosNewActionReport />),
  [ROUTE_PATHS.WHOS_CLOSE_ACTION_REPORT]: withActionReportSelectorRouting(<WhosCloseActionReport />),
  [ROUTE_PATHS.NON_BONUSABLE_BV_ACTION_REPORT]: withActionReportSelectorRouting(<NBBVActionReport />),
};

export const ActionReportsRoutes = () => {
  return (
    <>
      {Object.entries(ACTION_REPORTS_COMPONENT_MAP).map(([path, element]) => (
        <Route key={path} path={path} element={element} />
      ))}
    </>
  );
};
