import LeafProfileLoader from "../../../../../common/leaf/LeafProfile/LeafProfileLoader/LeafProfileLoader";
import { useGetABOProfileQuery } from "../../../../../services/Profile/profileAPI";
import { useAppSelector } from "../../../../../store";
import LeafApplicantItem from "../../../../../common/leaf/LeafProfile/LeafApplicant/LeafApplicantItem/LeafApplicantItem";
import "./ABOProfilePrimaryApplicantCard.scss";
import { cardActionClickTrack } from "../../../../../common/tealium/ABOProfile/Profile/ApplicantCardTrack";

const ABOProfilePrimaryApplicantCard = () => {
  // Get Login ABO
  const user = useAppSelector((state) => state.boot?.user);
  const affAbo = useAppSelector((state) => state.profile.affAbo);
  const { selectedPeriod } = useAppSelector(({ period }) => period);
  const boot = useAppSelector(({ boot }) => boot);
  const profileInfo = boot?.configuration?.profile?.aboInfo || {};

  const { isFetching, data } = useGetABOProfileQuery({
    period: selectedPeriod,
    affAbo,
    reqAffAbo: `${user.aff}-${user.abo}`,
  });

  const applicantTrackOnclick = (event?: string) => {
    cardActionClickTrack(event, true);
  };

  if (isFetching === false && data?.aboInfo && data.partyInfo) {
    const { aboInfo, partyInfo } = data;

    const partyAvaliable = partyInfo && partyInfo.length > 0;
    const primaryParty = partyInfo.find(({ isPrimary }) => isPrimary === true) || partyInfo[0];

    const party = {
      ...primaryParty,
      partyId: "",
    };

    if (partyAvaliable === true) {
      return (
        <div className="abo-applicant-card">
          <LeafApplicantItem
            aboInfo={aboInfo}
            partyInfo={party}
            isExpandable={false}
            applicantTrackOnclick={applicantTrackOnclick}
            profileInfo={profileInfo}
          />
        </div>
      );
    }

    return <></>;
  }

  return <LeafProfileLoader row={1} applicant={true} isFetching={isFetching} />;
};

export default ABOProfilePrimaryApplicantCard;
