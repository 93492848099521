export const BUSINESSNATURE: Readonly<{ [key: string]: number }> = Object.freeze({
  ABO: 1,
  MEMBER_PLUS: 2,
  MEMBER: 3,
  CLIENT: 4,
  MARKETING_ADVISOR: 5,
  AMWAY_OF: 6,
  HONORARY: 7,
  EIGHT: 8,
  NINE: 9,
  TEN: 10,
  ELEVEN: 11,
  SALES_REP: 12,
});
