import { WatchlistItem } from "../interfaces/models/watchlist";
import { RawWatchlist } from "../interfaces/api/watchlist";

/**
 * A simple model to work with saved data views and their KPIs
 */
export default class Watchlist {
  private __internal__: WatchlistItem[];
  /**
   * Creates an instance of Watchlist.
   * @param {apiWatchlistItem[]} data
   */
  constructor(data: RawWatchlist[] = []) {
    try {
      /**
       * The service doesn't really offer the proper keying, so we just mirror
       * it out, though I'm not a huge fan of this
       * @type {watchlistItem[]}
       */
      this.__internal__ = data.map((i) => ({
        Aff: i.Aff,
        ABONo: i.ABONo,
        Name: i.Name,
      }));
    } catch (error) {
      this.__internal__ = [];
    }
  }

  /**
   * Get a list of the available abo's in the watchlist
   */
  getList(): WatchlistItem[] {
    return this.__internal__;
  }

  /**
   * Add a watchlist item to the modal; useful when you don't want to
   * re-ask the API in the middle of the session but keep the data sane
   * @param {watchlistItem} watchlistItem
   */
  addWatchlistItem(watchlistItem: WatchlistItem): void {
    this.__internal__.push(watchlistItem);
  }

  /**
   * Remove a watchlist item from the modal; useful when you don't want to
   * re-ask the API in the middle of the session but keep the data sane
   * @param {string} affAbo reference key
   */
  deleteWatchlistItem(abo: string): void {
    const id = this.__internal__.findIndex((i) => i.ABONo === abo);
    if (id !== -1) {
      this.__internal__.splice(id, 1);
    }
  }

  /**
   * Get a list of abos via aff market
   * @param {string} aff
   */
  getListByAff(aff: string): WatchlistItem[] {
    return this.__internal__.filter((i) => i.Aff === aff);
  }

  /**
   * Check is the watchlist has a particular abo
   * @param {string} affAbo
   * @returns {boolean}
   */
  hasAbo(abo: string): boolean {
    return this.__internal__.filter((i) => i.ABONo === abo).length > 0;
  }

  /**
   * Useful for generating object references for use to create savedDataViewKpiListItem[]
   * @static
   * @param {string} affAbo
   * @param {string} name
   * @returns {watchlistItem}
   */
  static generateWatchlistItem(affAbo: string, name: string): WatchlistItem {
    const [aff, abo] = affAbo.split("-");
    return {
      ABONo: abo,
      Aff: aff,
      Name: name,
    };
  }
}
