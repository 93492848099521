import Market from "./market";

/**
 * Collection of Markets
 *
 * @export
 * @class Markets
 */
export default class Markets {
  list: Market[];
  /**
   * Creates an instance of Markets.
   * @memberof Markets
   */
  constructor() {
    this.list = [
      new Market("United Kingdom", "EIA", "040", "040", "GB"),
      new Market("Venezuela", "AMER", "170", "600", "VE"),
      new Market("Uruguay", "AMER", "170", "440", "UY"),
      new Market("United States", "AMER", "010", "010", "US"),
      new Market("Panama", "AMER", "170", "230", "PA"),
      new Market("Mexico", "AMER", "170", "170", "MX"),
      new Market("Honduras", "AMER", "170", "520", "HN"),
      new Market("Guatemala", "AMER", "170", "260", "GT"),
      new Market("El Salvador", "AMER", "170", "510", "SV"),
      new Market("Dominican Republic", "AMER", "010", "580", "DO"),
      new Market("Costa Rica", "AMER", "170", "290", "CR"),
      new Market("Colombia", "AMER", "170", "190", "CO"),
      new Market("Chile", "AMER", "170", "400", "CL"),
      new Market("Canada", "AMER", "010", "020", "CA"),
      new Market("Brazil", "AMER", "170", "310", "BR"),
      new Market("Argentina", "AMER", "170", "380", "AR"),
      new Market("Haiti", "AMER", "010", "700", "HT"),
      new Market("Hong Kong", "GCR", "050", "050", "HK"),
      new Market("Taiwan", "GCR", "130", "130", "TW"),
      new Market("China", "GCR", "360", "360", "CN"),
      new Market("Vietnam", "APAC", "500", "500", "VN"),
      new Market("Thailand", "APAC", "200", "200", "TH"),
      new Market("Singapore", "APAC", "100", "530", "SG"),
      new Market("Philippines", "APAC", "350", "350", "PH"),
      new Market("New Zealand", "APAC", "030", "240", "NZ"),
      new Market("Malaysia", "APAC", "100", "100", "MY"),
      new Market("Korea, Republic of", "APAC", "180", "180", "KR"),
      new Market("Japan", "APAC", "070", "070", "JP"),
      new Market("Indonesia", "APAC", "220", "220", "ID"),
      new Market("Brunei Darussalam", "APAC", "100", "780", "BN"),
      new Market("Australia", "APAC", "030", "030", "AU"),
      new Market("United Kingdom", "EIA", "040", "040", "UK"),
      new Market("Ukraine", "EIA", "150", "660", "UA"),
      new Market("Turkey", "EIA", "150", "450", "TR"),
      new Market("Switzerland", "EIA", "110", "110", "CH"),
      new Market("Sweden", "EIA", "470", "370", "SE"),
      new Market("Spain", "EIA", "250", "250", "ES"),
      new Market("South Africa", "EIA", "570", "570", "ZA"),
      new Market("Slovenia", "EIA", "150", "140", "SI"),
      new Market("Slovakia", "EIA", "150", "490", "SK"),
      new Market("Russian Federation", "EIA", "420", "420", "RU"),
      new Market("Romania", "EIA", "150", "590", "RO"),
      new Market("Portugal", "EIA", "250", "270", "PT"),
      new Market("Poland", "EIA", "150", "150", "PL"),
      new Market("Norway", "EIA", "470", "280", "NO"),
      new Market("Netherlands", "EIA", "090", "090", "NL"),
      new Market("Mongolia", "EIA", "420", "980", "MN"),
      new Market("Lithuania", "EIA", "150", "810", "LT"),
      new Market("Latvia", "EIA", "150", "820", "LV"),
      new Market("Kyrgyzstan", "EIA", "420", "870", "KG"),
      new Market("Kazakhstan", "EIA", "420", "830", "KZ"),
      new Market("Italy", "EIA", "160", "160", "IT"),
      new Market("Ireland", "EIA", "040", "300", "IE"),
      new Market("India", "EIA", "430", "430", "IN"),
      new Market("Hungary", "EIA", "150", "340", "HU"),
      new Market("Greece", "EIA", "480", "480", "GR"),
      new Market("Germany", "EIA", "060", "060", "DE"),
      new Market("France", "EIA", "060", "080", "FR"),
      new Market("Finland", "EIA", "470", "460", "FI"),
      new Market("Estonia", "EIA", "150", "800", "EE"),
      new Market("Denmark", "EIA", "470", "470", "DK"),
      new Market("Czech Republic", "EIA", "150", "390", "CZ"),
      new Market("Croatia", "EIA", "150", "650", "HR"),
      new Market("Bulgaria", "EIA", "150", "620", "BG"),
      new Market("Belgium", "EIA", "090", "120", "BE"),
      new Market("Austria", "EIA", "210", "210", "AT"),
      new Market("Namibia", "EIA", "570", "740", "NA"),
      new Market("Botswana", "EIA", "570", "750", "BW"),
    ];
  }

  /**
   * Returns all markets in the specified region.
   * @param {string} region - Region code.
   * @returns {Market[]}
   * @memberof Markets
   */
  getByRegion(region: string): Array<Market> {
    return this.list.filter((m) => m.region === region);
  }

  /**
   * Returns all markets in the specified affiliate.
   * @param {string} aff - Amway affiliate code.
   * @returns {Market[]}
   * @memberof Markets
   */
  getByAff(aff: string): Array<Market> {
    return this.list.filter((m) => m.aff === aff);
  }

  /**
   * Returns the market with the specified Amway country code.
   * @param {string} ctry - Amway country code.
   * @returns {Market}
   * @memberof Markets
   */
  getByCtry(ctry: string): Market {
    return this.list.filter((m) => m.ctry === ctry)[0];
  }

  /**
   * Returns the market with the specified ISO country code.
   * @param {string} iso - ISO Alpha 2 country code.
   * @returns {Market}
   * @memberof Markets
   */
  getByIso(iso: string): Market {
    return this.list.filter((m) => m.iso === iso)[0];
  }
}
