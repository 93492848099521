import "./LeafRadioMenu.scss";

type Option = {
  id: string;
  text: string;
};

export type Props = {
  options: Option[];
  onSelected: (id: string) => void;
  selectedId: string;
};

const LeafRadioMenu = (props: Props) => {
  const { options, onSelected, selectedId } = props;

  return (
    <div className="leaf-radio-menu">
      {options.map((option) => {
        return (
          <div key={option.id}>
            <label htmlFor={option.id}>
              <div
                role="radio"
                aria-checked={selectedId === option.id}
                tabIndex={0}
                className={`leaf-radio-menu__list-item ${selectedId === option.id ? "selected" : ""}`}
              >
                <input
                  type="radio"
                  id={option.id}
                  checked={selectedId === option.id}
                  onChange={() => onSelected(option.id)}
                />
                <div className="leaf-radio-menu__list-item__text">{option.text}</div>
              </div>
            </label>
          </div>
        );
      })}
    </div>
  );
};

export default LeafRadioMenu;
