import { useTranslation } from "react-i18next";
import { ErrorTile } from "../../../../common/components/ErrorTile/ErrorTile";
import { useAppSelector } from "../../../../store";
import { AWARD_CODES } from "../../../../common/enums/award-codes";
import { isProgramActive, isProgramActiveBulkCheck } from "../../../../common/util/checkProgramActiveStatus";
import { EligibleIncentive } from "../../../../services/ShellConfig/getShellConfigAPI.types";
import defaultContent from "../../../../content/annualPerformanceDashboard";
import LeafTile from "../../../../common/leaf/LeafTile/LeafTile";
import { FetchAnnualIncentiveTracking } from "./AnnualIncentiveTrackingDataLayer";
import { PggTrackingTile } from "./PggTrackingTile/PggTrackingTile";
import { FggTrackingTile } from "./FggTrackingTile/FggTrackingTile";
import "./AnnualIncentiveTracking.scss";
import { FaaTrackingTile } from "./FaaTrackingTile/FaaTrackingTile";

export const AnnualIncentiveTrackingTile = () => {
  const { t } = useTranslation(["annualPerformanceDashboard"]);
  const { selectedPeriod } = useAppSelector((store) => store.period);
  const { shellConfig } = useAppSelector((state) => state.boot);
  const { isLoading, annualIncentivesTrackingData, errorStatus, refetch } = FetchAnnualIncentiveTracking({
    period: selectedPeriod,
  });
  const { PggTile, FggTile, FaaTile } = annualIncentivesTrackingData;

  const hasActiveIncentives = isProgramActiveBulkCheck(shellConfig.eligibleIncentives, [
    AWARD_CODES.ANNUAL_PGG,
    AWARD_CODES.PGG_GCR,
    AWARD_CODES.FGG,
    AWARD_CODES.FAA,
  ]);
  const hasEnabledTiles = [PggTile.DisableTile, FggTile.DisableTile, FaaTile.DisableTile].some(
    (tileDisabled) => tileDisabled === false,
  );

  if (errorStatus) {
    return (
      <ErrorTile
        clickFunction={refetch}
        errorStatus={errorStatus}
        isLoading={isLoading}
        tealiumTrackId="Annual_Incentive_Tracking_Tile"
      />
    );
  }

  const checkEligibility = (awardcode: AWARD_CODES) => {
    return (
      isProgramActive(shellConfig.eligibleIncentives, awardcode) ||
      isComingSoon(shellConfig.eligibleIncentives, awardcode)
    );
  };

  const isComingSoon = (eligibleIncentives: EligibleIncentive[] = [], awardCode: number) => {
    return eligibleIncentives.some(({ awardNumber, comingSoon }) => awardNumber === awardCode && comingSoon);
  };

  const checkSingleTile = () => {
    return (
      (checkEligibility(AWARD_CODES.ANNUAL_PGG) || checkEligibility(AWARD_CODES.PGG_GCR)) &&
      checkEligibility(AWARD_CODES.FGG)
    );
  };

  return hasActiveIncentives && hasEnabledTiles ? (
    <LeafTile isLoading={false} hideBorder={true}>
      <div className="TpiTile">
        <div className={"TpiTile__header"}>
          <h2 className="TpiTile__title">
            {t("annualIncentiveTrackingTitle", defaultContent["annualIncentiveTrackingTitle"] as string)}
          </h2>
        </div>

        <section
          className={`annualIncentiveTrackingTile__container ${
            !checkSingleTile() ? "annualIncentiveTrackingTile__singleTile" : ""
          }`}
        >
          <div className="annualIncentiveTrackingTile__groupGrowthTiles">
            {(checkEligibility(AWARD_CODES.ANNUAL_PGG) || checkEligibility(AWARD_CODES.PGG_GCR)) && (
              <div className="annualIncentiveTrackingTile__tile">
                <PggTrackingTile tileData={PggTile} isLoading={isLoading} />
              </div>
            )}
            {checkEligibility(AWARD_CODES.FGG) && (
              <div className="annualIncentiveTrackingTile__tile">
                <FggTrackingTile tileData={FggTile} isLoading={isLoading} />
              </div>
            )}
          </div>
          {!FaaTile.DisableTile && (
            <div className="annualIncentiveTrackingTile__tile--fullwidth">
              <FaaTrackingTile tileData={FaaTile} isLoading={isLoading} />
            </div>
          )}
        </section>
      </div>
    </LeafTile>
  ) : (
    <></>
  );
};
