import { ISOAFF } from "../../components/Lit/acc-es-data/src/enums/affIsoCodes";
import { AWARD_CODES, TTCI_AWARD_CODES } from "../enums/award-codes";
import { MARKETS, MARKET_CODES } from "../enums/market-enums";

export const ANA_MARKETS: string[] = [MARKETS.UNITED_STATES, MARKETS.CANADA, MARKETS.DOMINICAN_REPUBLIC];

export const ANNUAL_INCENTIVES_AWARD_CODES: number[] = [
  AWARD_CODES.ANNUAL_PGG,
  AWARD_CODES.PGG_GCR,
  AWARD_CODES.FGG,
  TTCI_AWARD_CODES.PLATINUM,
  TTCI_AWARD_CODES.FOUNDERS_PLATINUM,
  TTCI_AWARD_CODES.SAPPHIRE,
  TTCI_AWARD_CODES.FOUNDERS_SAPPHIRE,
  TTCI_AWARD_CODES.IN_MARKET_EMERALD,
  TTCI_AWARD_CODES.IN_MARKET_FOUNDERS_EMERALD,
  TTCI_AWARD_CODES.IN_MARKET_DIAMOND,
  TTCI_AWARD_CODES.IN_MARKET_FOUNDERS_DIAMOND,
];

export const ANA_MARKET_CODES: string[] = [
  MARKET_CODES.UNITED_STATES,
  MARKET_CODES.CANADA,
  MARKET_CODES.DOMINICAN_REPUBLIC,
];

export const LATAM_MARKET_CODES: string[] = [
  ISOAFF.BR,
  ISOAFF.CL,
  ISOAFF.CO,
  ISOAFF.CR,
  ISOAFF.GT,
  ISOAFF.HN,
  ISOAFF.MX,
  ISOAFF.PA,
  ISOAFF.SV,
  ISOAFF.UY,
  ISOAFF.VE,
];
