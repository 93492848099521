import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./LeafToastMessage.scss";
import { IconCloseThick } from "../../icons/IconCloseThick/IconCloseThick";

export type Props = {
  showToast: boolean;
  htmlContent?: React.ReactNode;
  message?: string;
  position: "top-right" | "top-center" | "top-left" | "bottom-right" | "bottom-center" | "bottom-left";
  autoClose: number; // in milli seconds
  hideProgressBar: boolean;
  closeOnClick: boolean;
  pauseOnHover: boolean;
  draggable: boolean;
  progress?: string;
  theme?: "success" | "info" | "error";
};

const LeafToastMessage = (props: Props) => {
  const {
    showToast,
    htmlContent,
    message,
    position,
    autoClose,
    hideProgressBar,
    closeOnClick,
    pauseOnHover,
    draggable,
    progress = undefined,
    theme = "success",
  } = props;

  const getNotify = () => {
    switch (theme) {
      case "info":
        return toast.info;
      case "error":
        return toast.error;
      default:
        return toast.success;
    }
  };

  const showToastMessage = () => {
    const notify = getNotify();
    const toastId = notify(
      <>
        {htmlContent}
        {message}
      </>,
      {
        position: position,
        autoClose: autoClose,
        hideProgressBar: hideProgressBar,
        closeOnClick: closeOnClick,
        pauseOnHover: pauseOnHover,
        draggable: draggable,
        progress: progress,
        closeButton: (
          <button className="custom-close-button" onClick={() => toast.dismiss(toastId)}>
            <IconCloseThick height="0.875rem" width="0.625rem" theme={theme}></IconCloseThick>
          </button>
        ),
      },
    );
  };

  return (
    <>
      {showToast === true && showToastMessage()}
      <ToastContainer />
    </>
  );
};

export default LeafToastMessage;
