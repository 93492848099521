import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import defaultContent from "../../../content/provideUsFeedback";
import { fetchConfig } from "../../../common/util/config";
import "./ProvideUsFeedback.scss";

export default function ProvideUsFeedback() {
  const { t } = useTranslation(["provideUsFeedback"]);
  useEffect(() => {
    const iFrame = createIframe();
    fetchConfig().then((config) => {
      iFrame.setAttribute("src", config.corePlusSurvey);
      iFrame.setAttribute("title", t("pageTitle", defaultContent["pageTitle"]).toString());
    });
  }, []);
  return (
    <div className="feedback">
      <div className="feedback__header">
        <h2 className="feedback__header__title">{t("pageTitle", defaultContent["pageTitle"])}</h2>
      </div>
      <div id="iframeWrapper"></div>
    </div>
  );
}
export function createIframe() {
  const cpSurvey = document.createElement("iframe");
  cpSurvey.setAttribute("scrolling", "no");
  cpSurvey.setAttribute("className", "survey");
  cpSurvey.setAttribute("id", "frame");
  document.body.appendChild(cpSurvey);
  document.getElementById("iframeWrapper")?.appendChild(cpSurvey);
  return cpSurvey;
}
