import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IconCheckCircle } from "../../../../../common/icons/IconCheckCircle/IconCheckCircle";
import LeafLinkButton from "../../../../../common/leaf/LeafLinkButton/LeafLinkButton";
import LeafToastMessage from "../../../../../common/leaf/LeafToastMessage/LeafToastMessage";
import {
  TAG_EXPORT_LIST,
  useGetExportNotificationV2Query,
  util as ExportAPIUtil,
} from "../../../../../services/ExportV2/exportV2API";
import defaultContent from "../../../../../content/serverSideExport";
import "./ServerSideExportCompletedNotify.scss";
import { STATUS_DONE, STATUS_ERROR } from "../../../../../common/constants/export-constants";
import { IconWarningCircle } from "../../../../../common/icons/IconWarningCircle/IconWarningCircle";
import { dispatchExportCountEvent } from "../../ServerSideExportUtil/ServerSideExportEvents";

export type SSNotifyContent = {
  inProgressMessageTitle: string;
  inProgressMessageSubTitle: string;
  successMessageTitle: string;
  successMessageSubTitle: string;
  failedMessageTitle: string;
  failedMessageSubTitle: string;
  downloadButtonText: string;
};

type Props = {
  moduleId: string;
  notifyDownloadLinkOnClick: () => void;
};

const ServerSideExportCompletedNotify = ({ moduleId, notifyDownloadLinkOnClick }: Props) => {
  const { t } = useTranslation(["serverSideExport"]);

  const dispatch = useDispatch();

  const defaultNotificationContent = defaultContent.notifications;

  const translatedContent: SSNotifyContent = t("notifications", {
    returnObjects: true,
    defaultValue: defaultNotificationContent,
  });

  const content: SSNotifyContent = {
    ...defaultNotificationContent,
    ...translatedContent,
  };

  const { data } = useGetExportNotificationV2Query(
    { moduleId },
    {
      pollingInterval: 30000,
    },
  );

  const getContent = (status: string) => {
    switch (status) {
      case STATUS_ERROR: {
        return {
          title: content.failedMessageTitle,
          subTitle: content.failedMessageSubTitle,
        };
      }
      case STATUS_DONE:
      default:
        return {
          title: content.successMessageTitle,
          subTitle: content.successMessageSubTitle,
        };
    }
  };

  const getNotifyTheme = (status: string) => {
    switch (status) {
      case STATUS_ERROR:
        return "error";
      case STATUS_DONE:
      default:
        return "success";
    }
  };

  return useMemo(() => {
    const { Exports = [], VisibleExportRequestCount = 0, ExportCount = 0 } = data || {};

    if (Exports.length > 0) {
      // invalidate Export List API cache to make it re-fetch the API
      dispatch(ExportAPIUtil.invalidateTags([{ type: TAG_EXPORT_LIST, id: moduleId }]));
    }

    // update lit component export button state
    dispatchExportCountEvent(ExportCount, VisibleExportRequestCount);

    return (
      <div className="ss-export-notify-container">
        {Exports.map(({ ExportStatus, Done, ExportID }) => {
          const { title, subTitle } = getContent(ExportStatus);
          const theme = getNotifyTheme(ExportStatus);

          const isError = ExportStatus === STATUS_ERROR;

          return (
            <LeafToastMessage
              key={ExportID}
              htmlContent={
                <div className={`ss-export-toast`}>
                  <div className={`ss-export-toast__icon`}>
                    {isError && <IconWarningCircle />}
                    {Done && <IconCheckCircle size={`sm`} color={`green`} />}
                  </div>
                  <div className={`ss-export-toast__content`}>
                    <div className={"ss-export-toast__text"}>
                      <div className={`ss-export-toast__title`}>{title}</div>
                      <div className={`ss-export-toast__subtitle`}>{subTitle}</div>
                    </div>
                    {Done && (
                      <div className={`ss-export-toast__download-link`}>
                        <LeafLinkButton
                          as="link"
                          onClick={notifyDownloadLinkOnClick}
                          theme={"green"}
                          label={content.downloadButtonText}
                          fontWeight={"bold"}
                        >
                          {content.downloadButtonText}
                        </LeafLinkButton>
                      </div>
                    )}
                  </div>
                </div>
              }
              showToast={true}
              position={"top-center"}
              autoClose={10000}
              hideProgressBar={true}
              closeOnClick={false}
              pauseOnHover={false}
              draggable={false}
              theme={theme}
            />
          );
        })}
      </div>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
};

export default ServerSideExportCompletedNotify;
