import { useEffect } from "react";
import "../scss/layout.scss";

/**
 * Prevents page scrolling when modal is open
 * @param {boolean} shouldLock
 */
export const useLockBodyScrolling = (shouldLock: boolean, modalRef?: React.RefObject<HTMLElement>) => {
  useEffect(() => {
    if (shouldLock) {
      const horizontalScrollPos = window.scrollY;
      if (horizontalScrollPos > 0) {
        document.body.style.top = `-${horizontalScrollPos}px`;
      }
      document.body.className = "LockScrolling";

      const preventTouchMoves = (e: Event) => {
        if (modalRef?.current && modalRef.current.contains(e.target as Node)) {
          // if touch is inside modal return to allow movement
          return;
        }
        e.preventDefault();
      };

      // on mobile prevent touches from scrolling the background
      document.body.addEventListener("touchmove", preventTouchMoves, { passive: false });

      return () => {
        document.body.style.top = "";
        document.body.className = "";

        document.body.removeEventListener("touchmove", preventTouchMoves);

        window.scrollTo(0, horizontalScrollPos);
      };
    }
  }, [shouldLock, modalRef]);
};
