import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LeafToastMessage from "../../../../../common/leaf/LeafToastMessage/LeafToastMessage";
import defaultContent from "../../../../../content/serverSideExport";
// Share same CSS style
import "../ServerSideExportCompletedNotify/ServerSideExportCompletedNotify.scss";
import { IconInfoCircle } from "../../../../../common/icons/IconInfoCircle/IconInfoCircle";
import { SSNotifyContent } from "../ServerSideExportCompletedNotify/ServerSideExportCompletedNotify";
import { NotifyInProgressEvent } from "../../ServerSideExportUtil/ServerSideExportEvents";

const ServerSideExportInProgressNotify = () => {
  const { t } = useTranslation(["serverSideExport"]);
  const [timestamp, setTimestamp] = useState(0);

  useEffect(() => {
    const showNotification = () => {
      setTimestamp(new Date().getTime());
    };

    window.addEventListener(NotifyInProgressEvent, showNotification);

    return () => {
      window.removeEventListener(NotifyInProgressEvent, showNotification);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const defaultNotificationContent = defaultContent.notifications;

  const translatedContent: SSNotifyContent = t("notifications", {
    returnObjects: true,
    defaultValue: defaultNotificationContent,
  });

  const content: SSNotifyContent = {
    ...defaultNotificationContent,
    ...translatedContent,
  };

  return useMemo(
    () => (
      <div className="ss-export-notify-container">
        <LeafToastMessage
          htmlContent={
            <div className={`ss-export-toast`}>
              <div className={`ss-export-toast__icon`}>
                <IconInfoCircle />
              </div>
              <div className={`ss-export-toast__content`}>
                <div className={"ss-export-toast__text"}>
                  <div className={`ss-export-toast__title`}>{content.inProgressMessageTitle}</div>
                  <div className={`ss-export-toast__subtitle`}>{content.inProgressMessageSubTitle}</div>
                </div>
              </div>
            </div>
          }
          showToast={timestamp !== 0}
          position={"top-center"}
          autoClose={5000}
          hideProgressBar={true}
          closeOnClick={false}
          pauseOnHover={false}
          draggable={false}
          theme={"info"}
        />
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timestamp],
  );
};

export default ServerSideExportInProgressNotify;
