export const STATUS_NOT_EXPORTED = "NOT_EXPORTED";
export const STATUS_PENDING = "PENDING";
export const STATUS_IN_PROGRESS = "IN_PROGRESS";
export const STATUS_DONE = "DONE";
export const STATUS_ERROR = "ERROR";
export const FULL_MAP = "FULL_MAP";
export const CURRENT_VIEW = "CURRENT_VIEW";
export const FULL_MAP_SELECTED_KPIS = "FULL_MAP_SELECTED_KPIS";

export const FORMAT_CSV = "csv";
export const FORMAT_XLSX = "xlsx";
export const FORMAT_PDF = "pdf";

export const COLOR_NEUTRAL = "#D9D9D9";
export const COLOR_POSITIVE = "#107F47";
export const COLOR_ERROR = "#D91734";

export const MODULE_CUSTOMER = "customer";
export const MODULE_ORDER = "order";

export const MODULE_LOS = "los";
export const MODULE_EDLOS = "los";
export const MODULE_BIRTHDAYS_AR = "birthdaysAr";
export const MODULE_GOALS_AR = "goalsAr";
export const MODULE_PROVISIONAL_AR = "provisionalAr";
export const MODULE_CBR_AR = "cbrAr";
export const MODULE_CVR_AR = "cvrAr";
export const MODULE_WATCHLIST = "watchlist";
export const MODULE_WHOSNEW = "whosNew";
export const MODULE_WHOS_CLOSE = "whosClose";
export const MODULE_NON_BONUSABLE_BV_AR = "nonBonusableBvAr";
export const MODULE_RENEWALS = "renewalsAr";
