import LeafRectangleLoader from "../../../../../../common/leaf/LeafLoadingSkeleton/LeafRectangleLoader/LeafRectangleLoader";
import "./MenuList.scss";

type Props = {
  numberOfLoaders: number;
  isFetching: boolean;
};

const MenuList = ({ numberOfLoaders, isFetching }: Props) => {
  if (isFetching === false) {
    return <></>;
  }

  return (
    <>
      {Array.from({ length: numberOfLoaders }, (_, index) => (
        <div key={index} className="server-side-export-menu-loader__menu-item">
          <LeafRectangleLoader isLoading={isFetching} width="auto" height={"2rem"} />
        </div>
      ))}
    </>
  );
};

export default MenuList;
