import { ALWAYS_DISPLAY } from "../../constants/routing-constants";
import { Config } from "../../interfaces/config";
import { NavDropdown, NavData, NavDropdownItem } from "../../interfaces/routing";
import { BootUser } from "../../../reducers/boot";
import { createAcademyMenu } from "./createAcademyMenu";
import { createEarlyIncentivesDropdown } from "./earlyIncentivesDropdown";
import { createLeaderGrowthDropdown } from "./leaderGrowthDropdown";
import { createMyGroupDropdown } from "./myGroupDropdown";
import { createPerformanceDropdown } from "./performanceDropdown";
import { createResourcesDropdown } from "./resourcesDropdown";

/**
 *  Takes the navConfig data from services and returns a menuConfig based on if the program is active
 *  or if the market config disabled the page.
 */
export const createMenuConfig = (
  navConfigs: NavData[],
  learnMoreLink: string,
  { user, configuration }: { user: BootUser; configuration: Config },
) => {
  const finalMenuConfig: NavDropdown[] = [];
  const performanceDropdown = createPerformanceDropdown(navConfigs);
  const earlyIncentivesDropdown = createEarlyIncentivesDropdown(navConfigs);
  const myGroupDropdown = createMyGroupDropdown(navConfigs);
  const leaderGrowthDropdown = createLeaderGrowthDropdown(navConfigs, { user, configuration });
  const resourcesDropdown = createResourcesDropdown(navConfigs, learnMoreLink, { user, configuration });
  const academyMenu = createAcademyMenu(configuration, user);

  if (performanceDropdown.items.length !== 0) {
    finalMenuConfig.push(performanceDropdown);
  }
  if (earlyIncentivesDropdown.items.length !== 0) {
    finalMenuConfig.push(earlyIncentivesDropdown);
  }
  if (myGroupDropdown.items.length !== 0) {
    finalMenuConfig.push(myGroupDropdown);
  }
  if (leaderGrowthDropdown.items.length !== 0) {
    finalMenuConfig.push(leaderGrowthDropdown);
  }
  if (academyMenu.skip === false) {
    finalMenuConfig.push(academyMenu);
  }
  if (resourcesDropdown.items.length !== 0) {
    finalMenuConfig.push(resourcesDropdown);
  }

  return finalMenuConfig;
};

/**
 * Removes NavDropdownItem from dropdowns based on if activeAward is existing and the ROUTE_LABEL is
 * not included in the ALWAYS_DISPLAY constant
 */
export const filterDropdown = (navConfig: NavData[], defaultDropdown: NavDropdown): NavDropdown => {
  const filteredDropdown: NavDropdown = {
    key: defaultDropdown.key,
    label: defaultDropdown.label,
    items: [],
  };

  navConfig.forEach((NavDropdownItem) => {
    defaultDropdown.items.forEach((dropdownItem) => {
      if (
        NavDropdownItem.awardNumber === dropdownItem.code &&
        (NavDropdownItem.programActive || NavDropdownItem.comingSoon)
      ) {
        // HideMenu: Hide the menu but user can still access the page via direct link/bookmark
        filteredDropdown.items.push({ ...dropdownItem, hideMenu: NavDropdownItem.hideMenu });
      }

      if (ALWAYS_DISPLAY.includes(dropdownItem.label) && !filteredDropdown.items.includes(dropdownItem)) {
        filteredDropdown.items.push(dropdownItem);
      }
    });
  });
  filteredDropdown.items.sort(
    (a: { navPosition: number }, b: { navPosition: number }) => a.navPosition - b.navPosition,
  );
  return filteredDropdown;
};

export const returnRoutesByKey = (key: string, menuConfig: NavDropdown[]): NavDropdownItem[] => {
  const items = menuConfig.find((item: NavDropdown) => item.key === key)?.items;
  if (!items || items.length <= 0) {
    return [];
  }
  return items;
};

/**
 * Returns the parent navigation group that `path` belongs to
 * @param {string} path
 * @returns NavDropdown
 */
export const getNavGroupFromPath = (path: string, menuConfig: NavDropdown[]): NavDropdown => {
  for (const navGroup of menuConfig) {
    if (navGroup.items.find((route) => route.path === path || path === "/")) {
      return navGroup;
    }
  }
  return {} as NavDropdown;
};
