import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "../..";
import { QcgMultiplierBaseResponse, QcgOverviewResponse } from "./qcGrowth.types";

type QcgBaseRequestParams = {
  abo: string;
  aff: string;
  locale: string;
  period: string;
};

type QcgExtendedRequestParams = QcgBaseRequestParams & {
  isoCountryCode: string;
  isoCurrencyCode: string;
};

export const qcgAPI = createApi({
  reducerPath: "qcgAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getQcgOverview: builder.query<QcgOverviewResponse, QcgExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/qcg/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
    getQcgMultiplierBase: builder.query<QcgMultiplierBaseResponse, QcgExtendedRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/qcg/multiplierbase?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetQcgOverviewQuery, useGetQcgMultiplierBaseQuery } = qcgAPI;
