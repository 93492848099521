import Abo, { AboConstructorFields } from "./abo";

export interface AwardsFields {
  previousPYPin: { code: number };
  currentPin: { code: number };
  trackingPin: { code: number; awardTrend: number };
  highestPin: { code: number };
}

export interface EdlosServiceResult {
  affNo: string;
  aboNo: string;
  level: number;
  legNumber: number;
  index: number;
  name: string;
  isoLanguageCode: string;
  localName: string;
  privacyFlag: boolean;
  totalNewABOCount: number;
  groupPV: number;
  newPin: boolean;
  awards: AwardsFields;
  enableProfile: boolean;
  enablePerformanceSheet: boolean;
  enableDownlineLOS: boolean;
}

/**
 * Model for emerald/diamond los
 *
 * @export
 * @class EmeraldDiamondLos
 */
export default class EmeraldDiamondLosResult extends Abo {
  level: number;
  legNumber: number;
  index: number;
  totalNewAboCount: number;
  groupPV: number;
  newPin: boolean;
  awards: AwardsFields;
  enableProfile: boolean;
  enablePerformanceSheet: boolean;
  enableDownlineLOS: boolean;
  constructor(result: EdlosServiceResult) {
    const aboParams: AboConstructorFields = {
      affiliate: result.affNo,
      aboNumber: parseInt(result.aboNo),
      profile: {
        name: result.name,
        localName: result.localName,
        isoLanguageCode: result.isoLanguageCode,
        privacyFlag: result.privacyFlag,
      },
    };
    super(aboParams);
    this.level = result.level;
    this.legNumber = result.legNumber;
    this.index = result.index;
    this.totalNewAboCount = result.totalNewABOCount;
    this.groupPV = result.groupPV;
    this.newPin = result.newPin;
    this.awards = result.awards;
    this.enableProfile = result.enableProfile;
    this.enablePerformanceSheet = result.enablePerformanceSheet;
    this.enableDownlineLOS = result.enableDownlineLOS;
  }
}
