import hatFaaHelper, { HatFaaHelperPayload } from "./hatFaaHelper";

export interface FAASuperLegPayload extends HatFaaHelperPayload {
  ABONo: string;
  AnnualSponsorAff: string;
  AnnualSponsorABONo: string;
  Country: string;
  Points: number;
}

/**
 * A model of a FAA SuperLeg
 *
 * @export
 * @class FAASuperLeg
 */
export default class FAASuperLeg extends hatFaaHelper {
  __payload: FAASuperLegPayload;
  /**
   * Creates an instance of payload.
   * @param {object} payload
   * @memberof FAASuperLeg
   */
  constructor(payload: FAASuperLegPayload) {
    super(payload);
    this.__payload = payload;
  }

  /**
   * Get abo number
   * @returns {String}
   * @memberof FAASuperLeg
   */
  get abo(): string {
    return this.__payload.ABONo;
  }

  /**
   * Gets sponsor aff number
   * @returns {String}
   * @memberof FAASuperLeg
   */
  get sponsorAff(): string {
    return this.__payload.AnnualSponsorAff;
  }

  /**
   * Gets sponsor abo number
   * @returns {String}
   * @memberof FAASuperLeg
   */
  get sponsorAbo(): string {
    return this.__payload.AnnualSponsorABONo;
  }

  /**
   * Get country code
   * @returns {String}
   * @memberof FAASuperLeg
   */
  get country(): string {
    return this.__payload.Country;
  }

  /**
   * Get abo leg points
   * @returns {number}
   * @memberof FAASuperLeg
   */
  get points(): number {
    return this.__payload.Points;
  }
}
