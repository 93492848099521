import { useTranslation } from "react-i18next";
import defaultContent from "../../../../../content/profile";
import LeafDateTime from "../../../LeafDateTime/LeafDateTime";
import "./LeafDateJoinedInfo.scss";

export const DATE_JOINED_KEY = "dateJoined";
export const BUSINESS_ENTRY_KEY = "businessEntry";

type Props = {
  date: string;
  privacyFlag?: boolean;
  show?: boolean;
  title?: typeof DATE_JOINED_KEY | typeof BUSINESS_ENTRY_KEY;
};

const LeafDateJoinedInfo = (props: Props) => {
  const { date, privacyFlag, show, title = DATE_JOINED_KEY } = props;
  const { t } = useTranslation(["profile"]);

  const renderInfo = date ? (
    <LeafDateTime date={date} options={{ month: "long", day: "numeric", year: "numeric" }} ignoreTimeZone={true} />
  ) : (
    `${t("aboProfile.datesTab.noJoinedDate", defaultContent["aboProfile"]["datesTab"]["noJoinedDate"])}`
  );

  const privacyView = privacyFlag === true ? "-" : renderInfo;

  if (show === true) {
    return (
      <div className="leaf-date-joined">
        <div className="leaf-date-joined__container">
          <div className="leaf-date-joined__content-title">{`${t(
            `aboProfile.datesTab.${title}`,
            defaultContent["aboProfile"]["datesTab"][title],
          )}`}</div>
          <div className="leaf-date-joined__content-data">{privacyView}</div>
        </div>
      </div>
    );
  }

  return <></>;
};

export default LeafDateJoinedInfo;
