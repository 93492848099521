import TEALIUM from "../../../enums/tealium-enums";
import { STATUS_ERROR } from "../../../constants/export-constants";
import { track } from "../../../util/tealium";

export const openExportClickTrack = (tealiumCategory: string) => {
  track(TEALIUM.U_TAG_METHOD.LINK, TEALIUM.EVENT_NAME.CLICK_ACTION, "", tealiumCategory, "", "open_export_modal");
};

export const customerExportTrack = (element: string) => {
  track(
    TEALIUM.U_TAG_METHOD.LINK,
    TEALIUM.EVENT_NAME.CLICK_ACTION,
    "",
    TEALIUM.CATEGORY.GLOBAL_CML_EXPORT,
    "",
    element,
  );
};

export const trackOpenTermsAndConditions = () => {
  customerExportTrack("open_terms_and_conditions");
};

export const trackAcceptTnC = () => {
  customerExportTrack("accept_terms_and_conditions");
};

export const trackDeclineTnC = () => {
  customerExportTrack("decline_terms_and_conditions");
};

export const trackCustomerListExport = () => {
  customerExportTrack("export_customer_list");
};

export const trackOrderHistoryExport = () => {
  customerExportTrack("export_order_history");
};

export const trackExportAll = () => {
  customerExportTrack("export_all");
};

export const trackCustomerListCSVDownload = () => {
  customerExportTrack("download_customer_list_csv");
};

export const trackCustomerListXLSXDownload = () => {
  customerExportTrack("download_customer_list_xlsx");
};

export const trackOrderHistoryCSVDownload = () => {
  customerExportTrack("download_order_history_csv");
};

export const trackOrderHistoryXLSXDownload = () => {
  customerExportTrack("download_order_history_xlsx");
};

export const trackDeleteCustomerListExport = () => {
  customerExportTrack("delete_customer_list_export");
};

export const trackDeleteOrderHistoryExport = () => {
  customerExportTrack("delete_order_history_export");
};

export const trackExportNotification = () => {
  customerExportTrack("download_from_export_notification");
};

// Tracking from download slider ( DM : Download Modal)
export const trackCustomerListExportDM = (
  visibleCustomerExportCount: number,
  noDownline = false,
  exportStatus?: string,
) => {
  if (visibleCustomerExportCount > 0) {
    // re-try error export
    if (exportStatus === STATUS_ERROR && noDownline === false) {
      return customerExportTrack("retry_download_customer_list");
    }
    // re-request export
    if (exportStatus !== STATUS_ERROR) {
      return customerExportTrack("re-export_customer_list");
    }
  }
  return customerExportTrack("download_modal_export_customer_list");
};

export const trackOrderHistoryExportDM = (
  visibleCustomerExportCount: number,
  noDownline = false,
  exportStatus?: string,
) => {
  if (visibleCustomerExportCount > 0) {
    // re-try error export
    if (exportStatus === STATUS_ERROR && noDownline === false) {
      return customerExportTrack("retry_download_order_history");
    }
    // re-request export
    if (exportStatus !== STATUS_ERROR) {
      return customerExportTrack("re-export_order_history");
    }
  }
  return customerExportTrack("download_modal_export_order_history");
};
